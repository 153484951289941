import React from 'react';
import {Route, Switch} from 'react-router-dom'
import ChangePassword from './ChangePassword';

export default ({match}: any) => {
    return (
        <Switch>
            <Route path={`${match.url}/changepassword`} component={ChangePassword}/>
        </Switch>
    )
};

