import React, { useState } from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash'
import {connect} from '../../store'
import { TitleBar } from '../../components'
import {Paper} from '@material-ui/core';
import { Button, Form, Input } from 'oneframe-react'
import {ChangePasswordStateType, ChangePasswordStateDefaultValue} from "./type";
import {changePassword} from "../../_actions/profile";

type IState = ChangePasswordStateType

const ChangePassword = (props: any) => {

    const [state, setState] = useState<IState>(ChangePasswordStateDefaultValue) as any;

    const handleSubmit = async () => {
        let data: any = {
            ...state
        };

        let result: any = await changePassword(data);

        if (result.statusCode === 200) {
            setState(ChangePasswordStateDefaultValue);
        }
    };

    const handleChange = (e: any) => {
        setState((prevState: IState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <div>
            <TitleBar
                title={'Şifre Değiştir'}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Profil',
                        link: '/profile'
                    },
                    {
                        text: 'Şifre Değiştir',
                        link: '/profile/changePassword'
                    }
                ]}
            />
            <div className="wrapper">
                <Paper className="p-4" elevation={0}>
                    <Form onSubmit={() => handleSubmit()}>
                        <div className="row mb-4">
                            <div className="col-6 offset-3">
                                <div className="form-field">
                                <Input
                                    id="currentPassword"
                                    name="currentPassword"
                                    type="password"
                                    caption="Mevcut Şifre"
                                    className="custom-login-field"
                                    value={state!.currentPassword || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                    ]}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-6 offset-3">
                                <div className="form-field">
                                <Input
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    caption="Yeni Şifre"
                                    className="custom-login-field"
                                    value={state!.newPassword || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'minLength',
                                            value: 6,
                                            message: 'Minimum 6 karakter girilmelidir',
                                        },
                                    ]}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-6 offset-3">
                                <div className="form-field">
                                <Input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    caption="Yeni Şifre Tekrar"
                                    className="custom-login-field"
                                    value={state!.confirmPassword || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'custom',
                                            validate: (inputValue: any) => {
                                                return inputValue === state.newPassword;
                                            },
                                            message: 'Yeni şifrenin ilk ve ikinci girişi birbiriyle aynı değildir. Lütfen tekrar giriniz.',
                                        },
                                    ]}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-2">
                                <div className="form-field">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        text={'Kaydet'}
                                        className="custom-blue-button"
                                        iconRight={() => <i className="fad fa-save ml-2"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Paper>
            </div>
        </div>
    )
};

const ChangePasswordStyled = styled(ChangePassword)``;
const ChangePasswordComponent = React.memo(ChangePasswordStyled, (p: any, n: any) => isEqual(p, n));
export default connect((state: any) => (state))(ChangePasswordComponent);