import {buildRequestHeader, getAuthApiBaseUrl} from "../_helpers";
import {krax} from "../store";
import {ChangePasswordStateType} from "../pages/Profile/type";
import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

export const changePassword = async (data: ChangePasswordStateType) => {

    const urlSuffix = '/users/ChangePassword';
    const url: string = String(new URL(urlBase() + urlSuffix));

    const bodyData: any = {
        ...data
    };

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: bodyData
    };

    return await krax({
        name: 'changePassword',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'info',
                message: 'Şifreniz başarıyla değiştirildi',
                position: 'topRight',
                icon: 'fad fa-check',
            });
        },
        onError: (_: any, state: any, error: any) => {

            if (error && state && state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                })
            }
        }
    });
}