import {IKraxResult} from "../../_actions/kraxTypes";
import {ILocationResponseProps, ILookUpListProps} from "./../common/types";
import { ExportNames } from './../../_constants/exportNames';

export interface IStreetAnimalsProps {
    items: IStreetAnimalItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IStreetAnimalItemProps {
    age: string,
    breed: string,
    character: string,
    cityId: number,
    cityName: string,
    color: string,
    countyId: number,
    gender: string,
    id: string,
    latitude: number,
    longitude: number,
    name: string,
    neighbourhoodId: number,
    neighbourhoodName: string,
    physicalAttribute: string,
    physicalAttributes: string[],
    weight: string,
    microchipNumber: string,
    isApproved: boolean,
    isApprovedText: string,
    isAlive: boolean,
    isAliveText: string,
    isDeleted: boolean,
    isDeletedText: string,
    isPassive: boolean,
    isPassiveText: string
}


export interface IStreetAnimalFilterProps {
    microchipNumber: string,
    name: string,
    breed: string,
    gender: string,
    age: string,
    color: string,
    cityId?: number,
    countyId?: number,
    neighbourhoodId?: number,
    isAlive?: boolean,
    isDeleted?: boolean,
    isPassive?: boolean,
    triggerFilter: boolean
}

export const IStreetAnimalFilterDefaultValue : IStreetAnimalFilterProps={
    microchipNumber: '',
    name: '',
    breed: '',
    gender: '',
    age: '',
    color: '',
    triggerFilter: false
}

export interface IStreetAnimalListPageProps {
    streetAnimals: IKraxResult<IStreetAnimalsProps>,
    streetAnimalsAllRecord: IKraxResult<IStreetAnimalsProps>,
    lookups: IKraxResult<ILookUpListProps>,
    cities: IKraxResult<ILocationResponseProps>,
    counties: IKraxResult<ILocationResponseProps>,
    neighbourhoods: IKraxResult<ILocationResponseProps>
}

export interface IStreetAnimalListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    streetAnimalList: IStreetAnimalItemProps[],
    tabHelperText?: string,
    exportTitle: string,
    exportFileName: string,
    approvalStatus: boolean,
    modalShow: boolean,
    selectedItem: any,
    operationStatus: boolean,
    operationType: string,
    modalWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined,
    tabIndex: number,
    ownership: boolean,
    ownershipSlug: string,
    cities: any,
    exportType?: string
}

export const IStreetAnimalListPageDefaultValue: IStreetAnimalListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    streetAnimalList: [],
    exportFileName: ExportNames.STREET_ANIMALS.APPROVED.FILE_NAME,
    exportTitle: ExportNames.STREET_ANIMALS.APPROVED.TITLE,
    approvalStatus: true,
    modalShow: false,
    operationStatus: false,
    selectedItem: null,
    operationType: '',
    modalWidth: false,
    tabIndex: 0,
    ownership: false,
    ownershipSlug: '',
    cities: []    
};

export const IStreetAnimalListHasOwnerPageDefaultValue: IStreetAnimalListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    streetAnimalList: [],
    exportFileName: ExportNames.STREET_ANIMALS.HAS_OWNER.FILE_NAME,
    exportTitle: ExportNames.STREET_ANIMALS.HAS_OWNER.TITLE,
    approvalStatus: true,
    modalShow: false,
    operationStatus: false,
    selectedItem: null,
    operationType: '',
    modalWidth: false,
    tabIndex: 0,
    ownership: true,
    ownershipSlug: 'hasOwner',
    cities: []    
};

export const IStreetAnimalListNoOwnerPageDefaultValue: IStreetAnimalListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    streetAnimalList: [],
    exportFileName: ExportNames.STREET_ANIMALS.NO_OWNER.FILE_NAME,
    exportTitle: ExportNames.STREET_ANIMALS.NO_OWNER.TITLE,
    approvalStatus: true,
    modalShow: false,
    operationStatus: false,
    selectedItem: null,
    operationType: '',
    modalWidth: false,
    tabIndex: 0,
    ownership: true,
    ownershipSlug: 'noOwner',
    cities: []    
};