import React, {memo, useEffect, useState} from 'react';
import {TitleBar, Tooltip} from '../../components'
import {getFaq, removeFaq} from "../../_actions/faq";
import {connect} from "../../store";
import { DataGrid as Grid , Modal, Button} from 'oneframe-react'
import {IFaqListPageDefaultValue, IFaqListPageProps, IFaqListPageState, IFaqFormPageDefaultValue} from "./types";
import {useHistory, useLocation} from 'react-router-dom'
import {IconButton} from '@material-ui/core';
import moment from 'moment'
import 'moment/locale/tr'
import Description from "../../components/Description";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CategoryGroup } from './../../_constants/categoryGroup';

interface IProps extends IFaqListPageProps {
}

interface IFaqState extends IFaqListPageState {
}

const Faq = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    // detay sayfadan geri dönerken, kaldığı sayfadan devam edebilmesi için yapıldı.
    const [state, setState] = useState<IFaqState>(location.state == null ? IFaqListPageDefaultValue : location.state.listState);
    const {faq: {payload, loading}} = props;
    const {page, pageSize, deleteControl, modalShow} = state;
    const operationTypes: any = {
        edit: 'edit',
        delete: 'delete'
    };    

    useEffect(() => {
        getFaq(pageSize, page, CategoryGroup.Faq)
    }, [page, pageSize, deleteControl]);

    useEffect(() => {
        if (payload && payload.items) {

            // Daha önceden yüklenmiş olan payload'daki page ve pageSize'ı iptal etmek için eklendi. Çünkü sonsuz döngüye giriyordu.
            if (page == payload.pageIndex && pageSize == payload.pageSize) {
                setState((prevState: IFaqState) => ({
                    ...prevState,
                    faqList: payload.items,
                    page: payload.pageIndex,
                    totalCount: payload.totalCount,
                    totalPages: payload.totalPages,
                    pageSize: payload.pageSize,
                    deleteControl: false,
                }))
            }
        }

    }, [payload]);

    const handleClick = (id: string, operation: string) => {

        if (operation === operationTypes.edit) {
            history.push(`/faq/edit/${id}`, {listState: state, formDefaultState: IFaqFormPageDefaultValue}) // mevcut state'i daha sonra geri alabilmek için gönderiyoruz.
        } else if (operation === operationTypes.delete) {
            removeFaq(id);
            setState((prevState: IFaqState) => ({
                ...prevState,
                deleteControl: true,
                modalShow: false,
                deleteItemId: ''
            }))
        }
    };

    return (
        <div>
            <TitleBar
                title={'Sık Sorulan Sorular'}
                loading={loading}
                button={[
                    {
                        text: 'Soru Ekle',
                        onClick: () => {
                            history.push('/faq/add');
                        },
                        iconLeft: <i className="fad fa-plus mr-2"/>,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Sık Sorulan Sorular',
                        link: '/faq'
                    }
                ]}
            />


            <div className="wrapper">
                {!loading ?
                    (
                        state.faqList.length ?
                            <Grid
                                data={state.faqList}
                                page={state.page}
                                rowsPerPage={state.pageSize}
                                count={state.totalCount}
                                totalPages={state.totalPages}
                                serverSide={true}
                                loadingState={loading}
                                onChangeRowsPerPage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        pageSize: a,
                                        page: 0
                                    }))
                                }}
                                onChangePage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        page: a
                                    }))
                                }}
                                header={{
                                    print: false,
                                    download: false,
                                    search: false,
                                    filter: false,
                                    viewColumns: false,
                                }}
                                helperInfoText={'soru var'}
                                sort={false}
                                // onColumnSortChange={(column:any, direction:any) => {                                
                                //     console.log(column, direction);
                                // }}
                                labels={{
                                    noData: 'Hiç Data Yok',
                                    headerTooltip: 'Sırala',
                                    paginationNext: 'Sonraki Sayfa',
                                    paginationPrev: 'Önceki Sayfa',
                                    paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                    seperator: '/'
                                }}
                                hiddenColumns={['id', 'categoryId']}
                                column={[
                                    {
                                        label: 'Soru/Cevap',
                                        displayField: 'question',
                                        customRender: (a, b) => {
                                            return (
                                                <>
                                                    <div style={{width: 500}}>
                                                        <h6>{b.question}</h6>
                                                        <p>
                                                        <ReactQuill                                                            
                                                            value={b.answer}
                                                            readOnly={true}
                                                            theme={"bubble"}
                                                            />
                                                        </p>
                                                    </div>
                                                </>
                                            );
                                        }
                                    },
                                    {
                                        label: 'Kategori',
                                        displayField: 'categoryName'
                                    },
                                    {
                                        label: 'Eklenme Tarihi',
                                        displayField: 'insertedDate',
                                        customRender: (a) => {
                                            return a && moment(a).format('LL')
                                        }
                                    },
                                    {
                                        label: 'Ekleyen Kişi',
                                        displayField: 'insertedUser'
                                    },
                                    {
                                        label: 'Güncellenme Tarihi',
                                        displayField: 'updatedDate',
                                        customRender: (a) => {
                                            return a && moment(a).format('LL')
                                        }
                                    },
                                    {
                                        label: 'Güncelleyen Kişi',
                                        displayField: 'updatedUser'
                                    },
                                    {
                                        label: 'İşlemler',
                                        displayField: '',
                                        customRender: (value: any, rowData: any) => {
                                            return (
                                                <>
                                                    <div className="table-icon">
                                                        <Tooltip title="Düzenle" placement="bottom">
                                                            <IconButton className="detail-icon bg-blue" onClick={() => handleClick(rowData.id, operationTypes.edit)}>
                                                                <i className="fas fa-edit fa-1x"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Sil" placement="bottom">
                                                            <IconButton
                                                                className="remove-icon bg-red ml-2"
                                                                onClick={() => {
                                                                    setState(prevState => ({
                                                                        ...prevState,
                                                                        modalShow: true,
                                                                        deleteItemId: rowData.id
                                                                    }))
                                                                }}
                                                            >
                                                                <i className="fas fa-trash fa-1x"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </>
                                            )
                                        }
                                    }
                                ]}
                            />
                            : <Description text="Herhangi bir soru/cevap yok"/>
                    ) : null
                }

                <Modal
                    title={
                        <div className="p-3">
                            <span><i className="fad fa-question-circle mr-2"/>Soru Silme İşlemi</span>
                        </div>
                    }
                    show={modalShow && state.deleteItemId.length > 0}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={'xs'}
                    footer={
                        <div className="p-3">
                            <Button
                                text="Sil"
                                className="custom-red-button h-75 mr-2"
                                iconLeft={() => <i className="fad fa-trash mr-2"/>}
                                onClick={() => handleClick(state.deleteItemId, operationTypes.delete)}
                            />
                            <Button
                                text="İptal"
                                className="custom-blue-button h-75"
                                iconLeft={() => <i className="fad fa-times mr-2"/>}
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        modalShow: false,
                                        deleteItemId: ''
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span>Soruyu silmek istediğine emin misin?</span>
                </Modal>
            </div>
        </div>
    )
});

const ConnectComponent = connect((store: any) => ({faq: store.faq}))(Faq) as any;
export default ConnectComponent
