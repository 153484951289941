import React, { memo, useEffect, useState } from 'react';
import { TitleBar, Tooltip } from '../../components'
import { DataGrid as Grid, Modal, Button, Input, Form, Select } from 'oneframe-react'
import { getStreetAnimals, updateApprovalStatus, removeStreetAnimal, updateOwnershipStatus, activateStreetAnimal } from "../../_actions/streetAnimals";
import { getCities, getCountiesOfCity, getLookups, getNeighbourhoodsOfCounty } from "../../_actions/common";
import { connect } from "../../store";
import { IStreetAnimalListPageDefaultValue, IStreetAnimalListHasOwnerPageDefaultValue, IStreetAnimalListNoOwnerPageDefaultValue, IStreetAnimalListPageProps, IStreetAnimalListPageState, IStreetAnimalFilterDefaultValue, IStreetAnimalFilterProps, IStreetAnimalItemProps } from "./types";
import { ILookUpListProps, ILookUpListDefaultValue, ILocationListProps, ILocationListDefaultValue } from "./../common/types"
import { useHistory, useLocation } from 'react-router-dom'
import { IconButton, Avatar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Description from "../../components/Description";
import { capitalize, filter, join } from 'lodash';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { LookupConstants } from './../../_constants/lookup'
import DownloadExcel from './../../components/ExcelExport/exceldownload';
import generatePDF from '../../components/PdfExport/pdfexport';
import { ExportTypes } from './../../_constants/exportType';
import { ExportNames } from './../../_constants/exportNames';

interface IProps extends IStreetAnimalListPageProps {
}

interface IStreetAnimalState extends IStreetAnimalListPageState {
}

interface IStreetAnimalFilterState extends IStreetAnimalFilterProps {
}

interface ILookUpListState extends ILookUpListProps {

}

interface ILocationListState extends ILocationListProps {

}

const StreetAnimals = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const { pathname } = location;

    const tabSlugs: any = {
        approvedPathInfo: 'approved',
        unApprovedPathInfo: 'unApproved',
        ownedPathInfo: 'hasOwner',
        unOwnedPathInfo: 'noOwner',
    };

    const opTypeSlug = pathname.split('/')[2];
    const ownershipStatus: boolean = opTypeSlug === tabSlugs.ownedPathInfo || opTypeSlug === tabSlugs.unOwnedPathInfo;

    const getDefaultValues = () => {
        switch(opTypeSlug){
            case tabSlugs.approvedPathInfo:
                return IStreetAnimalListPageDefaultValue;
            case tabSlugs.ownedPathInfo:
                return IStreetAnimalListHasOwnerPageDefaultValue;
            case tabSlugs.unOwnedPathInfo:
                return IStreetAnimalListNoOwnerPageDefaultValue;
            default:
                return IStreetAnimalListPageDefaultValue;
        }
    }

    const [state, setState] = useState<IStreetAnimalState>(() => {
        var initializeValue = getDefaultValues();
        return initializeValue;
    });
    const [lookupState, setLookupState] = useState<ILookUpListState>(ILookUpListDefaultValue);
    const [filterState, setFilterState] = useState<IStreetAnimalFilterState>(IStreetAnimalFilterDefaultValue);
    const [locationState, setLocationState] = useState<ILocationListState>(ILocationListDefaultValue);
    const [exportDataState, setExportDataState] = useState<IStreetAnimalItemProps[] | null>(null);
    const { streetAnimals, streetAnimalsAllRecord, lookups, cities, counties, neighbourhoods } = props;
    const { triggerFilter } = filterState;

    const operationTypes: any = {
        approve: 'approve',
        delete: 'delete',
        owned: 'owned',
        unOwned: 'unowned',
        activate: 'activate'
    };
    const mainPathInfo: string = '/streetanimals';

    const tabIndexInfo = {
        "approved": 0,
        "hasOwner": 0,
        "noOwner": 1
    };

    const getTabContentItem = (opTypeSlug: string, ownershipStatus: boolean, request: string) => {
        return tabContent[ownershipStatus ? 'forOwnership' : 'forApprove'][tabIndexInfo[opTypeSlug]][request];
    };
    const { page, pageSize, approvalStatus, modalShow, operationStatus, operationType, modalWidth, ownership, ownershipSlug = ownershipStatus ? opTypeSlug : '' } = state;
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            tabIndex: tabIndexInfo[opTypeSlug],
            ownership: ownershipStatus,
            ownershipSlug: ownershipStatus ? opTypeSlug : '',
            tabHelperText: getTabContentItem(opTypeSlug, ownershipStatus, 'helperText'),
            exportTitle: getTabContentItem(opTypeSlug, ownershipStatus, 'exportTitle'),
            exportFileName: getTabContentItem(opTypeSlug, ownershipStatus, 'exportFileName'),
            pageSize: 10,
            approvalStatus: !pathname.includes(tabSlugs.unApprovedPathInfo),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps

        resetFilterHandle();

        const lookupList = [LookupConstants.AGE, LookupConstants.GENDER, LookupConstants.BREED, LookupConstants.COLOR];
        getLookups(join(lookupList, LookupConstants.LOOKUPSEPARATOR));
        getCities();

    }, [pathname]);

    useEffect(() => {
        if (lookups.payload && Object.keys(lookups.payload).length > 0) {
            setLookupState(lookups.payload);
        }
    }, [lookups.payload, pathname]);

    useEffect(() => {
        if (cities.payload && Object.keys(cities.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                cities: cities.payload
            }));
        }
    }, [cities.payload, pathname]);

    //#region FillCounties
    useEffect(() => {
        if (filterState.cityId != null) {
            getCountiesOfCity(filterState.cityId);
        }
    }, [filterState.cityId]);

    useEffect(() => {
        if (counties.payload && Object.keys(counties.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                counties: counties.payload
            }));
        }
    }, [counties.payload, pathname]);
    //#endregion

    //#region Fillneighbourhoods
    useEffect(() => {
        if (filterState.countyId != null) {
            getNeighbourhoodsOfCounty(filterState.countyId);
        }
    }, [filterState.countyId]);

    useEffect(() => {
        if (neighbourhoods.payload && Object.keys(neighbourhoods.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                neighbourhoods: neighbourhoods.payload
            }));
        }
    }, [neighbourhoods.payload, pathname]);
    //#endregion


    useEffect(() => {
        getStreetAnimals(pageSize, page, approvalStatus, capitalize(ownershipSlug), filterState, 'streetAnimals');
    }, [page, pageSize, approvalStatus, operationStatus, ownershipSlug, triggerFilter]);

    useEffect(() => {
        if (streetAnimals.payload && streetAnimals.payload.items) {
            setState((prevState: IStreetAnimalState) => ({
                ...prevState,
                streetAnimalList: streetAnimals.payload.items,
                page: streetAnimals.payload.pageIndex,
                totalCount: streetAnimals.payload.totalCount,
                totalPages: streetAnimals.payload.totalPages,
                operationStatus: false,
                approvalStatus: !pathname.includes(tabSlugs.unApprovalStatus),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streetAnimals.payload]);

    useEffect(() => {
        if (streetAnimalsAllRecord.payload && streetAnimalsAllRecord.payload.items) {
            var customizedData = customizeData(streetAnimalsAllRecord.payload.items);

            if (state.exportType == ExportTypes.EXCEL) {
                setExportDataState(customizedData);
            } else if (state.exportType == ExportTypes.PDF) {
                generatePDF(getExportColumnsByGridType(), customizedData, state.exportTitle, state.exportFileName)
            }

            setState((prevState: IStreetAnimalState) => ({
                ...prevState,
                exportType: ''
            }));
        }
    }, [streetAnimalsAllRecord.payload]);

    const getAllDataOnHandleClick = (exportType: string) => {
        setState((prevState: IStreetAnimalState) => ({
            ...prevState,
            exportType: exportType
        }));

        // state sıfırlanmazsa ikinci kez indirilemiyor.
        setExportDataState(null);
        // tüm kayıtları çekebilmek için totalCount ve page 0 kullanılıyor.
        getStreetAnimals(32000, 0, approvalStatus, capitalize(ownershipSlug), filterState, 'streetAnimalsAllRecord');
        // useEffect strretAnimalsAllRecord.payload kısmına düşer.
    }

    const customizeData = (allData: any) => {
        allData.forEach((item) => {
            item.isAliveText = item.isAlive ? "Evet" : "Hayır";
            item.isDeletedText = item.isDeleted ? "Evet" : "Hayır";
            item.isPassiveText = item.isPassive ? "Pasif" : "Aktif";
            item.hasOwnerText = item.hasOwner ? "Sahipli" : "Sahiplendirilebilir";
        });

        return allData;
    }

    const resetByOperation = () => {
        setState((prevState: IStreetAnimalListPageState) => ({
            ...prevState,
            operationStatus: true,
            modalShow: false,
            selectedItem: null,
            operationType: ''
        }));
        return true;
    };

    const handleClick = (rowData: any, operation: string) => {
        if (rowData && (operation === operationTypes.approve || operation === operationTypes.unApprove)) {
            updateApprovalStatus(rowData);
            resetByOperation();

        } else if (rowData && (operation === operationTypes.owned || operation === operationTypes.unOwned)) {
            updateOwnershipStatus(rowData);
            resetByOperation();

        } else if (rowData && operation === operationTypes.delete) {
            removeStreetAnimal(rowData.id);
            resetByOperation();
        } else if (rowData && operation === operationTypes.activate) {
            activateStreetAnimal(rowData.id);
            resetByOperation();
        }
        return true;
    };

    const filterHandleChange = (e: any) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const filterHandleSubmit = async () => {
        // arama sonrasında page 0 a çekilmeli bu filtreyi de tetikler. page 0 ise, else kısmı tetikler.
        if (state.page != 0) {
            setState((prevState) => ({
                ...prevState,
                page: 0
            }));
        }
        else {
            //triggerFilter değiştiğinde use effect getStreetAnimals a düşer.
            setFilterState((prevState) => ({
                ...prevState,
                triggerFilter: !triggerFilter
            }));
        }
    }

    const resetFilterHandle = async () => {
        setFilterState(IStreetAnimalFilterDefaultValue);
    }

    const tabContent = {
        forApprove: [
            {
                text: 'Semt Sakinleri',
                helperText: ' semt sakini var',
                exportFileName: ExportNames.STREET_ANIMALS.APPROVED.FILE_NAME,
                exportTitle: ExportNames.STREET_ANIMALS.APPROVED.TITLE,
                onClick: () => {
                    history.push('/streetanimals/approved')
                },
                iconLeft: <i className="fad fa-paw" />,
            }
        ],
        forOwnership: [
            {
                text: 'Sahipli Semt Sakinleri',
                helperText: 'sahipli semt sakini var',
                exportFileName: ExportNames.STREET_ANIMALS.HAS_OWNER.FILE_NAME,
                exportTitle: ExportNames.STREET_ANIMALS.HAS_OWNER.TITLE,
                onClick: () => {
                    history.push('/streetanimals/hasOwner')
                },
                iconLeft: <i className="fad fa-paw" />,
            },
            {
                text: 'Sahiplendirilebilir Semt Sakinleri',
                helperText: 'semt sakini sahiplenmeyi bekliyor',
                exportFileName: ExportNames.STREET_ANIMALS.NO_OWNER.FILE_NAME,
                exportTitle: ExportNames.STREET_ANIMALS.NO_OWNER.TITLE,
                onClick: () => {
                    history.push('/streetanimals/noOwner')
                },
                iconLeft: <i className="fad fa-hands-heart" />,
            }
        ]
    };

    const getColumnsByGridType = (showStatus: boolean) => {
        const defaultColumns: any = [
            {
                label: '',
                displayField: '',
                customRender: () => {
                    return (
                        <>
                            <div className="rounded-image">
                                <Avatar src="/streetAnimal.png" />
                            </div>
                        </>
                    );
                }
            },
            {
                label: 'Çip No',
                displayField: 'microchipNumber',
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                label: 'Ad',
                displayField: 'name',
            },
            {
                label: 'Cins',
                displayField: 'breed',
            },
            {
                label: 'Cinsiyet',
                displayField: 'gender',
            },
            {
                label: 'Yaş',
                displayField: 'age',
            },
            {
                label: 'Ağırlık',
                displayField: 'weight',
            },
            {
                label: 'Karakter',
                displayField: 'character',
            },
            {
                label: 'Renk',
                displayField: 'color',
            },
            {
                label: 'İl',
                displayField: 'cityName',
                customRender: (val) => {
                    return (
                        <>
                            {val}
                        </>
                    );
                }
            },
            {
                label: 'İlçe',
                displayField: 'countyName',
                customRender: (val) => {
                    return (
                        <>
                            {val}
                        </>
                    );
                }
            },
            {
                label: 'Mahalle',
                displayField: 'neighbourhoodName',
                customRender: (val) => {
                    return (
                        <>
                            {val}
                        </>
                    );
                }
            },
            {
                label: 'Fiziksel Özellik',
                displayField: 'physicalAttribute',
            },
            {
                label: 'Kısır mı?',
                displayField: 'isNeuter',
                customRender: (val) => {
                    return (
                        <>
                            {val ? 'Evet' : 'Hayır'}
                        </>
                    );
                }
            },
            {
                label: 'Sahiplendirilebilir mi?',
                displayField: 'isAdoptable',
                customRender: (val) => {
                    return (
                        <>
                            {val ? 'Evet' : 'Hayır'}
                        </>
                    );
                }
            },
            {
                label: 'Özel Diyeti Var mı?',
                displayField: 'hasSpecialDiet',
                customRender: (val) => {
                    return (
                        <>
                            {val ? 'Evet' : 'Hayır'}
                        </>
                    );
                }
            }];


        if (showStatus && opTypeSlug === tabSlugs.ownedPathInfo) {
            defaultColumns.push({
                label: 'Sahiplenme Durumu',
                displayField: 'hasOwner',
                customRender: (value: any, allValue: any) => {
                    return (
                        <div className={value ? 'txt-green' : 'txt-blue'}>
                            {value ? <span>Sahipli</span> : <span>Sahiplendirilebilir</span>}
                        </div>
                    );
                }
            });
        } else if (showStatus && opTypeSlug === tabSlugs.unOwnedPathInfo) {
            defaultColumns.push(
                {
                    label: 'Sahiplenme Durumu',
                    displayField: 'hasOwner',
                    customRender: (value: any, allValue: any) => {
                        return (
                            <div className={value ? 'txt-green' : 'txt-blue'}>
                                {value ? <span>Sahipli</span> : <span>Sahiplendirilebilir</span>}
                            </div>
                        );
                    }
                }, {
                    label: 'Aktif/ Pasif',
                    displayField: 'isPassive',
                    customRender: (value: any, allValue: any) => {
                        return (
                            <div className={value ? 'txt-red' : 'txt-green'}>
                                {value ? <span>Pasif</span> : <span>Aktif</span>}
                            </div>
                        );
                    }
                }
            );
        } else {
            defaultColumns.push(
                {
                    label: 'Yaşıyor Mu?',
                    displayField: 'isAlive',
                    customRender: (value: any, allValue: any) => {
                        return (
                            <div className={value ? 'txt-green' : 'txt-red'}>
                                {value ? <span>Evet</span> : <span>Hayır</span>}
                            </div>
                        );
                    }
                },
                {
                    label: 'Silinmiş Mi?',
                    displayField: 'isDeleted',
                    customRender: (value: any, allValue: any) => {
                        return (
                            <div className={value ? 'txt-green' : 'txt-red'}>
                                {value ? <span>Evet</span> : <span>Hayır</span>}
                            </div>
                        );
                    }
                },
                {
                    label: 'Aktif/ Pasif',
                    displayField: 'isPassive',
                    customRender: (value: any, allValue: any) => {
                        return (
                            <div className={value ? 'txt-red' : 'txt-green'}>
                                {value ? <span>Pasif</span> : <span>Aktif</span>}
                            </div>
                        );
                    }
                }
            );
        }

        const buttonOkText: String = ownershipSlug === tabSlugs.unOwnedPathInfo ? "Sahiplendir" : "Onayla";
        const buttonOkType = ownershipSlug === tabSlugs.unOwnedPathInfo ? operationTypes.owned : operationTypes.approve;

        const buttonNokText: String = ownershipSlug === tabSlugs.ownedPathInfo ? "Sahiplenmeyi Sonlandır" : "Onayı Kaldır";
        const buttonNokType = ownershipSlug === tabSlugs.ownedPathInfo ? operationTypes.unOwned : operationTypes.unApprove;

        const deleteButton = (rowData: any) => {
            return (<Tooltip title="Sil" placement="bottom">
                <IconButton
                    className="remove-icon bg-red ml-2"
                    onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            modalShow: true,
                            selectedItem: rowData,
                            operationType: operationTypes.delete,
                            modalWidth: 'xs'
                        }))
                    }}
                >
                    <i className="fas fa-trash fa-1x" />
                </IconButton>
            </Tooltip>
            );
        }

        const activateButton = (rowData: any) => {
            return (<Tooltip title="Aktif Et" placement="bottom">
                <IconButton
                    className="confirm-icon bg-green ml-2"
                    onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            modalShow: true,
                            selectedItem: rowData,
                            operationType: operationTypes.activate,
                            modalWidth: 'xs'
                        }))
                    }}
                >
                    <i className="fas fa-check fa-1x" />
                </IconButton>
            </Tooltip>
            );
        }

        defaultColumns.push(
            {
                label: 'İşlemler',
                displayField: '',
                customRender: (value: any, rowData: any) => {
                    // sahiplenme durumu kolonu yok ise, aktif et ve delete olacak.             
                    if (!showStatus) {
                        return (
                            <>
                                <div className="table-icon">
                                    {!ownership && rowData.isPassive && rowData.isAlive && !rowData.isDeleted && activateButton(rowData)}
                                    {!ownership && !rowData.isDeleted && deleteButton(rowData)}
                                </div>
                            </>
                        );
                    }
                    else if (!approvalStatus || ownershipSlug === tabSlugs.unOwnedPathInfo) {
                        return (
                            <>
                                <div className="table-icon">
                                    <>
                                        <Tooltip title={buttonOkText} placement="bottom">
                                            <IconButton className="confirm-icon bg-green" onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    modalShow: true,
                                                    selectedItem: rowData,
                                                    operationType: buttonOkType,
                                                    modalWidth: 'md'
                                                }))
                                            }}>
                                                <i className="fas fa-check fa-1x" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    {!ownership && deleteButton(rowData)}
                                </div>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div className="table-icon">
                                    <>
                                        <Tooltip title={buttonNokText} placement="bottom">
                                            <IconButton className="reject-icon bg-blue ml-2" onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    modalShow: true,
                                                    selectedItem: rowData,
                                                    operationType: buttonNokType,
                                                    modalWidth: 'md'
                                                }))
                                            }}>
                                                <i className="fas fa-times fa-1x" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    {!ownership && deleteButton(rowData)}
                                </div>
                            </>
                        );
                    }
                }
            });

        return defaultColumns;
    }

    const getLookupValue = (type) => {
        if (lookupState) {
            var list = filter(lookupState, function (o) { return o.key === type });
            if (list.length > 0) {
                return list[0].values;
            }
            else
                return [];
        }
        return [];
    }

    const getExportColumnsByGridType = () => {
        const defaultExportColumns: any = [
            { label: "Çip No", value: "microchipNumber" },
            { label: "Ad", value: "name" },
            { label: "Cins", value: "breed" },
            { label: "Cinsiyet", value: "gender" },
            { label: "Yaş", value: "age" },
            { label: "Renk", value: "color" },
            { label: "İl", value: "cityName" },
            { label: "İlçe", value: "countyName" },
            { label: "Mahalle", value: "neighbourhoodName" },
        ];

        if (opTypeSlug === tabSlugs.approvedPathInfo) {
            defaultExportColumns.push(
                { label: "Yaşıyor Mu?", value: "isAliveText" },
                { label: "Silinmiş Mi?", value: "isDeletedText" },
                { label: "Aktif/Pasif", value: "isPassiveText" },
            );
        } else if (opTypeSlug === tabSlugs.ownedPathInfo) {
            defaultExportColumns.push(
                { label: "Sahiplenme Durumu", value: "hasOwnerText" },
            );
        } else if (opTypeSlug === tabSlugs.unOwnedPathInfo) {
            defaultExportColumns.push(
                { label: "Sahiplenme Durumu", value: "hasOwnerText" },
                { label: "Aktif/Pasif", value: "isPassiveText" },
            );
        }
        
        return defaultExportColumns;
    }

    return (
        <>
            <TitleBar
                title={'Semt Sakinleri'}
                loading={streetAnimals.loading}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Semt Sakinleri',
                        link: mainPathInfo
                    }
                ]}
                tabs={ownership ? tabContent['forOwnership'] : tabContent['forApprove']}
                tabActive={state.tabIndex}
            />

            <div className="wrapper">
                <div className="mb-3">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filtreleme Kriterleri</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Form onSubmit={() => filterHandleSubmit()}>
                                <MUIGrid container spacing={3}>
                                    <MUIGrid item xs={2}>
                                        <Input
                                            id="microchipNumber"
                                            name="microchipNumber"
                                            caption="Çip No"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.microchipNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Input
                                            id="name"
                                            name="name"
                                            caption="Ad"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.name}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="breed"
                                            name="breed"
                                            caption={'Cins'}
                                            options={{
                                                data: getLookupValue(LookupConstants.BREED),
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.breed
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="gender"
                                            name="gender"
                                            caption={'Cinsiyet'}
                                            options={{
                                                data: getLookupValue(LookupConstants.GENDER),
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.gender
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="age"
                                            name="age"
                                            caption={'Yaş'}
                                            options={{
                                                data: getLookupValue(LookupConstants.AGE),
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.age
                                            }}
                                        /></MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="color"
                                            name="color"
                                            caption={'Renk'}
                                            options={{
                                                data: getLookupValue(LookupConstants.COLOR),
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.color
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={4}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="cityId"
                                            name="cityId"
                                            caption={'İl'}
                                            options={{
                                                data: locationState.cities,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.cityId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={4}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="countyId"
                                            name="countyId"
                                            caption={'İlçe'}
                                            options={{
                                                data: locationState.counties,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.countyId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={4}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="neighbourhoodId"
                                            name="neighbourhoodId"
                                            caption={'Mahalle'}
                                            options={{
                                                data: locationState.neighbourhoods,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.neighbourhoodId
                                            }}
                                        />
                                    </MUIGrid>
                                    {!ownership ?
                                        <MUIGrid item xs={3}>
                                            <Select
                                                onChange={filterHandleChange}
                                                id="isAlive"
                                                name="isAlive"
                                                caption={'Yaşıyor Mu?'}
                                                options={{
                                                    data: [{ value: "true", name: "Yaşıyor" }, { value: "false", name: "Yaşamıyor" }],
                                                    displayField: 'name',
                                                    displayValue: 'value',
                                                    selected: filterState!.isAlive
                                                }}
                                            />
                                        </MUIGrid>
                                        : null
                                    }
                                    {!ownership ?
                                        <MUIGrid item xs={3}>
                                            <Select
                                                onChange={filterHandleChange}
                                                id="isDeleted"
                                                name="isDeleted"
                                                caption={'Silinmiş Mi?'}
                                                options={{
                                                    data: [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }],
                                                    displayField: 'name',
                                                    displayValue: 'value',
                                                    selected: filterState!.isDeleted
                                                }}
                                            />
                                        </MUIGrid>
                                        : null
                                    }
                                    {!ownership || opTypeSlug === tabSlugs.unOwnedPathInfo ?
                                        <MUIGrid item xs={3}>
                                            <Select
                                                onChange={filterHandleChange}
                                                id="isPassive"
                                                name="isPassive"
                                                caption={'Aktif/Pasif'}
                                                options={{
                                                    data: [{ value: "false", name: "Aktif" }, { value: "true", name: "Pasif" }],
                                                    displayField: 'name',
                                                    displayValue: 'value',
                                                    selected: filterState!.isPassive
                                                }}
                                            />
                                        </MUIGrid>
                                        : null
                                    }
                                    <MUIGrid item xs={12}>
                                        <MUIGrid container spacing={0} direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="reset"
                                                    text="Temizle"
                                                    className='custom-blue-button'
                                                    onClick={resetFilterHandle}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="submit"
                                                    text="Ara"
                                                    className='custom-green-button'
                                                />
                                            </MUIGrid>
                                        </MUIGrid>
                                    </MUIGrid>
                                </MUIGrid>
                            </Form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="export-row mb-3 text-right">
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.PDF)}>
                        <Button type="submit" text="PDF" className='custom-orange-button' iconLeft={() => <i className="fas fa-file-pdf mr-2" />}></Button>
                    </Form>
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.EXCEL)}>
                        <Button type="submit" text="Excel" className='custom-green-button ml-3' iconLeft={() => <i className="fas fa-file-excel mr-2" />}></Button>
                        {exportDataState != null && exportDataState.length > 0 ?
                            <DownloadExcel
                                fileName={state.exportFileName}
                                buttonText="Excel İndir"
                                data={exportDataState}
                                sheetName={state.exportTitle}
                                columns={getExportColumnsByGridType()}
                            />
                            :
                            null
                        }
                    </Form>
                </div>
                {!streetAnimals.loading ?
                    (state.streetAnimalList.length ?
                        <Grid
                            data={state.streetAnimalList}
                            page={state.page}
                            rowsPerPage={state.pageSize}
                            count={state.totalCount}
                            totalPages={state.totalPages}
                            serverSide={pageSize !== state.totalCount}
                            loadingState={streetAnimals.loading}
                            onChangeRowsPerPage={(a: any) => {
                                setState(prevState => ({
                                    ...prevState,
                                    pageSize: a,
                                    page: 0
                                }))
                            }}
                            onChangePage={(a: any) => {
                                setState(prevState => ({
                                    ...prevState,
                                    page: a
                                }))
                            }}
                            header={{
                                print: false,
                                download: false,
                                search: false,
                                filter: false,
                                viewColumns: false
                            }}
                            helperInfoText={state.tabHelperText}
                            sort={false}
                            // onColumnSortChange={(column:any, direction:any) => {                                
                            //     console.log(column, direction);
                            // }}
                            labels={{
                                noData: 'Hiç Data Yok',
                                headerTooltip: 'Sırala',
                                paginationNext: 'Sonraki Sayfa',
                                paginationPrev: 'Önceki Sayfa',
                                paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                seperator: '/'
                            }}
                            hiddenColumns={['id', 'cityId', 'countyId', 'neighbourhoodId', 'isNeuter', 'isAdoptable', 'hasSpecialDiet', 'weight', 'character', 'physicalAttribute', 'username']}
                            column={getColumnsByGridType(ownership)}
                        />
                        : <Description
                            text={ownershipSlug.length ? (ownershipSlug === tabSlugs.ownedPathInfo ? 'Sahipli herhangi bir Semt Sakini yok' : 'Sahiplenmeyi bekleyen herhangi bir Semt Sakini yok') : "Herhangi bir Semt Sakini yok"} />
                    ) : null
                }

                <Modal
                    title={
                        <div className="p-3">
                            <span><i className="fad fa-paw mr-2" />
                                {operationType === operationTypes.approve ? 'Semt Sakini Onaylama İşlemi' : null}
                                {operationType === operationTypes.unApprove ? 'Semt Sakini Onay Kaldırma İşlemi' : null}
                                {operationType === operationTypes.delete ? 'Semt Sakini Pasife Alma İşlemi' : null}
                                {operationType === operationTypes.activate ? 'Semt Sakini Aktif Etme İşlemi' : null}
                                {operationType === operationTypes.owned ? 'Semt Sakini Sahiplendirme İşlemi' : null}
                                {operationType === operationTypes.unOwned ? 'Semt Sakini Sahiplenmeyi Sonlandırma İşlemi' : null}
                            </span>
                        </div>
                    }
                    show={modalShow}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={modalWidth}
                    footer={
                        <div className="p-3">
                            <Button
                                text={
                                    operationType === operationTypes.delete ? "Sil"
                                        : (operationType === operationTypes.unOwned ? 'Sonlandır' : (operationType === operationTypes.unApprove ? 'Kaldır'
                                            : (operationType === operationTypes.approve || operationType === operationTypes.owned ? 'Onayla' : (operationType === operationTypes.activate ? 'Aktif Et' : ''))))
                                }
                                className={`${operationType === operationTypes.approve || operationType === operationTypes.owned || operationType === operationTypes.activate ? 'custom-green-button' : 'custom-red-button'} h-75 mr-2`}
                                iconLeft={() => {
                                    return (
                                        <>
                                            {operationType === operationTypes.approve || operationType === operationTypes.owned || operationType === operationTypes.activate ? <i className="fad fa-check-circle mr-2" /> : null}
                                            {operationType === operationTypes.unApprove || operationType === operationTypes.unOwned ? <i className="fad fa-times-circle mr-2" /> : null}
                                            {operationType === operationTypes.delete ? <i className="fad fa-trash mr-2" /> : null}
                                        </>
                                    )
                                }}
                                onClick={() => handleClick(state.selectedItem, operationType)}
                            />
                            <Button
                                text="İptal"
                                className="custom-blue-button h-75"
                                iconLeft={() => <i className="fad fa-times mr-2" />}
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        modalShow: false,
                                        selectedItem: null,
                                        operationType: ''
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span>
                        {operationType === operationTypes.approve ? 'Semt sakinini onaylamak istediğine emin misin?' : null}
                        {operationType === operationTypes.unApprove ? 'Semt sakininin onayını kaldırmak istediğine emin misin?' : null}
                        {operationType === operationTypes.delete ? (state.selectedItem.microchipNumber == null ? '' : 'Çip No: ' + state.selectedItem.microchipNumber + ' ve ') + state.selectedItem.name + ' isimli semt sakini pasif hale getirilecektir. Bu işlem geri alınamaz. Silmek istediğinize emin misiniz?' : null}
                        {operationType === operationTypes.activate ? (state.selectedItem.microchipNumber == null ? '' : state.selectedItem.microchipNumber + ' Mikroçip nolu ') + state.selectedItem.name + ' isimli semt sakini aktif hale geçip, mobil uygulamada o mahalledeki tüm kullanıcılara görünür olacaktır. Devam etmek istiyor musunuz?' : null}
                        {operationType === operationTypes.owned ? 'Semt sakinini sahiplendirmek istediğine emin misin?' : null}
                        {operationType === operationTypes.unOwned ? 'Semt sakininin sahiplenmesini sonlandırmak istediğine emin misin?' : null}
                    </span>
                    {operationType !== operationTypes.delete && operationType !== operationTypes.activate ?
                        <p className="mt-3">
                            <i className="fad fa-info-circle mr-2" />
                            {operationType === operationTypes.approve ?
                                <>
                                    <strong>Not :</strong> Semt sakini, onaylandığında <strong>'Semt Sakinleri Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                            {operationType === operationTypes.unApprove ?
                                <>
                                    <strong>Not :</strong> Semt sakini, onayı kaldırıldığında <strong>'Onay Bekleyen Semt Sakinleri Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                            {operationType === operationTypes.owned ?
                                <>
                                    <strong>Not :</strong> Semt sakini, sahiplendirildiğinde <strong>'Sahipli Semt Sakinleri Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                            {operationType === operationTypes.unOwned ?
                                <>
                                    <strong>Not :</strong> Semt sakini, sahiplenmesi sonlandırıldığında <strong>'Sahiplendirilebilir Semt Sakinleri Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                        </p>
                        : null
                    }
                </Modal>
            </div>
        </>
    )
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ConnectComponent = connect((store: any) => ({ streetAnimals: store.streetAnimals, streetAnimalsAllRecord: store.streetAnimalsAllRecord, lookups: store.lookups, cities: store.cities, counties: store.counties, neighbourhoods: store.neighbourhoods }))(StreetAnimals) as any;
export default ConnectComponent