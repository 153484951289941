import React from 'react';

export interface IDescription {
    text: string;
}

const Description = (props: IDescription) => {
    return <div className="flex-center-center p-5">
        <i className="fad fa-info-circle fa-2x mr-2"/>
        <h6>{props.text}</h6>
    </div>
};

export default Description;
