import { IKraxResult } from "../../_actions/kraxTypes";
import { ExportNames } from "../../_constants/exportNames";

export interface IAdoptionApplicationsProps {
    items: IAdoptionApplicationItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IAdoptionApplicationItemProps {
    id: string,
    shelterAnimalName: string,
    shelterAnimalKind: string,
    shelterAnimalMicrochipNumber: string,
    shelterAnimalGender: string,
    shelterAnimalAge: string,
    shelterAnimalShelterName: string,
    assignedUserUserName: string,
    assignedUserFullName: string,
    assignedUserPhoneNumber: string,
    surveyAnswerFullName, string,
    surveyAnswerPhoneNumber: string,
    surveyAnswerEmailAddress: string,
    assignedDate: string
}

export interface IAdoptionApplicationAnswersItemProps {
    shelterAnimalAssingedUsersId: string,
    shelterAnimalAssingedUsersAssignedDate: string,
    shelterAnimalAssingedUsersShelterAnimalName: string,
    questionOrder: string,
    questionText: string,
    text: string
}

export interface IAdoptionApplicationListPageProps {
    adoptionApplications: IKraxResult<IAdoptionApplicationsProps>
}

export interface IAdoptionApplicationListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null,
    exportTitle: string,
    exportFileName: string,
    shelterList: any,
    genderList: any,
    ageList: any,
    kindList: any
}

export const IAdoptionApplicationListPageDefaultValue : IAdoptionApplicationListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    exportFileName: ExportNames.ADOPTION_APPLICATIONS.FILE_NAME,
    exportTitle: ExportNames.ADOPTION_APPLICATIONS.TITLE,
    shelterList: [],
    genderList: [],
    ageList: [],
    kindList: [],
}

export interface IAdoptionApplicationFilterProps {
    shelterAnimalName: string,
    shelterAnimalKind: string,
    shelterAnimalGender: string,
    shelterAnimalAge: string,
    shelterAnimalMicrochipNumber: string,
    shelterAnimalShelterName: string,
    assignedUserUserName: string,
    assignedUserFullName: string,
    assignedUserPhoneNumber: string,
    surveyAnswerFullName: string,
    surveyAnswerPhoneNumber: string,
    surveyAnswerEmailAddress: string,
    assignedDate: string,
    triggerFilter: boolean
}

export const IAdoptionApplicationFilterDefaultValue : IAdoptionApplicationFilterProps = {
    shelterAnimalName: '',
    shelterAnimalKind: '',
    shelterAnimalGender: '',
    shelterAnimalAge: '',
    shelterAnimalMicrochipNumber: '',
    shelterAnimalShelterName: '',
    assignedUserUserName: '',
    assignedUserFullName: '',
    assignedUserPhoneNumber: '',
    surveyAnswerFullName: '',
    surveyAnswerPhoneNumber: '',
    surveyAnswerEmailAddress: '',
    assignedDate: '',
    triggerFilter: false
}