import {IKraxResult} from "../../_actions/kraxTypes";
import {ILocationResponseProps} from "./../common/types";

export interface IUsersProps {
    items: IUserItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IUserItemProps {
    id: string,
    fullName: string,
    email: string,
    phoneNumber: string,
    username: string,
    address: any,
    isApproved: boolean,
    isDeleted: boolean,
    isApprovedText: string,
    isDeletedText: string,
    cityName: string,
    countyName: string,
    neighbourhoodName: string
}

// export interface IUserExportItemProps {
//     id: string,
//     fullName: string,
//     email: string,
//     phoneNumber: string,
//     username: string,
//     address: any,
//     isDeleted: boolean
// }

export interface IUserFilterProps {
    id: string,
    fullName: string,
    email: string,
    phoneNumber: string,
    username: string,
    cityId?: number,
    countyId?: number,
    neighbourhoodId?: number,
    isDeleted?: boolean,
    triggerFilter: boolean
}

export const IUserFilterDefaultValue : IUserFilterProps={
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    username: '',
    cityId: undefined,
    countyId: undefined,
    neighbourhoodId: undefined,
    isDeleted: undefined,
    triggerFilter: false
}

export interface IUserListPageProps {
    users: IKraxResult<IUsersProps>,
    usersAllRecord: IKraxResult<IUsersProps>,
    cities: IKraxResult<ILocationResponseProps>,
    counties: IKraxResult<ILocationResponseProps>,
    neighbourhoods: IKraxResult<ILocationResponseProps>
}

export interface IUserListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    userList: IUserItemProps[],
    tabIndex: number,
    userType: string,
    tabHelperText?: string,
    tabText?: string,
    exportTitle: string,
    exportFileName: string,
    approvalStatus: boolean,
    operationStatus: boolean,
    selectedItem: any,
    modalShow: boolean,
    operationType: string,
    modalWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined,
    exportType?: string
}

export const IUserListPageDefaultValue: IUserListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    userList: [],
    tabIndex: 0,
    userType: '',
    exportFileName: '',
    exportTitle: '',
    approvalStatus: true,
    operationStatus: false,
    selectedItem: null,
    modalShow: false,
    operationType: '',
    modalWidth: false
};
