import React from 'react';
import {connect} from '../../store'
import Sidebar from "./NoPermissionSidebar";

const NoPermissions = () => {
    let dateYear: any = new Date();
    return (        
        <div className="login-container">            
            <Sidebar>
                <div className="text-2">
                    &copy;{dateYear.getFullYear()}
                </div>
            </Sidebar>
            <div className="login-rightside-form">
                <div className="form-area">
                    <div className="title">
                        <h3 className="">
                            Yetkiniz Bulunmuyor
                        </h3>
                    </div>
                    <div className="error-container"/>
                    <div className="form">                        
                        <div className="form-field">
                            <p> Sayfayı görüntülemek için yeterli yetkiniz bulunmuyor. Lütfen sistem adminleri ile iletişime geçiniz.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default connect((state: any) => (state))(NoPermissions);
