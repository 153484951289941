export interface ILookUpListProps {
    [index: number]: { id: string; key: string; values: ILookUpItemProps[] }
}

export const ILookUpListDefaultValue: ILookUpListProps = {

}

export interface ILookUpItemProps {
    id: string,
    order: number,
    value: string
}


export interface ILocationItemProps {
    id: number,
    name: string
}

export interface ILocationResponseProps{
    [index: number]: { id: number; name: string }
}

export interface ILocationListProps {
    cities: any[],
    counties: any[],
    neighbourhoods: any[]
}


export const ILocationListDefaultValue: ILocationListProps = {
    cities: [],
    counties: [],
    neighbourhoods: []
}

export interface ITableSelectedItemProps {
    selectedItems: any[]
}

export const ITableSelectedItemDefaultValue : ITableSelectedItemProps = {
    selectedItems: []
}