import React, {memo, useEffect} from 'react';
import {Sidebar, Header} from '../../components';
import {resetStore_RouterAfterChange} from "../../_actions/resetStore_routerAfterChange";
import {useLocation} from 'react-router-dom'

const Layout = memo(({children}:any) => {

    const location = useLocation();

    useEffect(() => {
        resetStore_RouterAfterChange()
    }, [location.pathname]);

    return (
        <div className="app-container">
            <Header/>
            <Sidebar/>
            {children}
        </div>
    )
});

export default Layout;