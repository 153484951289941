import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Start from './Start';

export default({match}: any) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`}>
                <Redirect to={`${match.url}/list`}/>
            </Route>
            <Route path={`${match.url}/start`} component={Start}/>
        </Switch>
    )
};