export const sessionStorageConstants = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USER_ID: 'USER_ID',
    USER_FULLNAME: 'USER_FULLNAME',
    USER_UNIQUENAME: 'USER_UNIQUENAME',
    PERMISSIONS: 'PERMISSIONS',
    USER_EMAIL: 'USER_EMAIL',
    EXPIRED_TIME: 'EXPIRED_TIME',
    MENU: 'MENU',
    LOOKUPS: 'LOOKUPS'
};
