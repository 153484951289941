import React, {useEffect, useState} from 'react';
import {TitleBar} from '../../components'
import {Paper} from '@material-ui/core';
import {connect} from "../../store";
import {IHomePageProps, IHomePageState, IHomePageStateDefaultValue} from "./types";
import {getStatistics} from '../../_actions/home';
import {useHistory} from 'react-router-dom'
import Chart from '../../components/Chart';
import { ColorConstants } from './../../_constants/color';
import { join } from 'lodash';

interface IProps extends IHomePageProps {
}

const Home = (props: IProps) => {

    const history = useHistory();

    const [state, setState] = useState<IHomePageState>(IHomePageStateDefaultValue);
    const {dashboardStatistics: {payload, loading}} = props;

    const {
        statistics: {
            volunteerCount,
            veterinaryCount,
            memberCount,
            streetAnimalCount,
            incidentCount,
            userTypeRate,
            incidentTypeRate,
            incidentStatusRate
        }
    } = state;

    useEffect(() => {
        getStatistics();
    }, []);

    useEffect(() => {
        if (payload) {
            setState((prevState: IHomePageState) => ({
                ...prevState,
                statistics: payload
            }))
        }
    }, [payload]);

    return (
        <>
            <TitleBar
                title={'Ana Sayfa'}
                loading={false}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Ana Sayfa',
                        link: '/'
                    },
                ]}
            />
            <div className="wrapper">
                <div className="info-box-area">
                    <div className="row">
                        <div className="col">
                            <Paper className="info-box bg-blue clickable" elevation={0} onClick={() => history.push('/streetanimals')}>
                                <div className="d-flex align-items-center">
                                    <i className="fad fa-paw mr-3"/>
                                    <h6>Semt Sakini Sayısı</h6>
                                    <span className="ml-auto">{streetAnimalCount}</span>
                                </div>
                            </Paper>
                        </div>
                        <div className="col">
                            <Paper className="info-box bg-orange clickable" elevation={0} onClick={() => history.push('/users/volunteer/approved')}>
                                <div className="d-flex align-items-center">
                                    <i className="fad fa-hand-holding-seedling mr-3"/>
                                    <h6>Gönüllü Sayısı</h6>
                                    <span className="ml-auto">{volunteerCount}</span>
                                </div>
                            </Paper>
                        </div>
                        <div className="col">
                            <Paper className="info-box bg-green clickable" elevation={0} onClick={() => history.push('/users/veterinary/approved')}>
                                <div className="d-flex align-items-center">
                                    <i className="fad fa-user-md mr-3"/>
                                    <h6>Veteriner Sayısı</h6>
                                    <span className="ml-auto">{veterinaryCount}</span>
                                </div>
                            </Paper>
                        </div>
                        <div className="col">
                            <Paper className="info-box bg-red clickable" elevation={0} onClick={() => history.push('/users/member')}>
                                <div className="d-flex align-items-center">
                                    <i className="fad fa-hand-holding-heart mr-3"/>
                                    <h6>Hayvansever Sayısı</h6>
                                    <span className="ml-auto">{memberCount}</span>
                                </div>
                            </Paper>
                        </div>
                        <div className="col">
                            <Paper className="info-box bg-purple clickable" elevation={0} onClick={() => history.push('/incidence/list')}>
                                <div className="d-flex align-items-center">
                                    <i className="fad fa-asterisk mr-3"/>
                                    <h6>Vaka Sayısı</h6>
                                    <span className="ml-auto">{incidentCount}</span>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
                <div className="content chart-area">
                    <div className="row h-100">
                        <div className="col-4">
                            <Paper className="chart p-2" elevation={0}>
                                {
                                    !loading &&
                                    <Chart
                                        data={userTypeRate}
                                        width={"100%"}
                                        height={"90%"}
                                        caption="Üye Dağılımları - Rollere Göre"
                                        subCaption="Uygulamayı kullanan kullanıcıların sayısına göre hesaplanmıştır"
                                        defaultCenterLabel="Üye Tipleri"
                                        paletteColors={join([ColorConstants.LIGHT_BLUE, ColorConstants.GREEN, ColorConstants.YELLOW, ColorConstants.RED], ',')}
                                    />
                                }
                            </Paper>
                        </div>
                        <div className="col-4">
                            <Paper className="chart p-2" elevation={0}>
                                {
                                    !loading &&
                                    <Chart
                                        data={incidentTypeRate}
                                        width={"100%"}
                                        height={"90%"}
                                        caption="Vaka Tipi Dağılımı"
                                        subCaption="Uygulamada kayıtlı vakaların sayısına göre hesaplanmıştır"
                                        defaultCenterLabel="Vaka Tipleri"
                                        paletteColors={join([ColorConstants.PURPLE, ColorConstants.LIGHT_BLUE, ColorConstants.RED, ColorConstants.GREEN, ColorConstants.YELLOW], ',')}
                                    />
                                }
                            </Paper>
                        </div>
                        <div className="col-4">
                            <Paper className="chart p-2" elevation={0}>
                                {
                                    !loading &&
                                    <Chart
                                        data={incidentStatusRate}
                                        width={"100%"}
                                        height={"90%"}
                                        caption="Vakaların Durum Yüzdelikleri"
                                        subCaption="Uygulamada kayıtlı vakaların durumlarına göre hesaplanmıştır"
                                        defaultCenterLabel="Vaka Durumları"
                                        paletteColors={join([ColorConstants.LIGHT_BLUE, ColorConstants.YELLOW, ColorConstants.RED, ColorConstants.ORANGE, ColorConstants.GREEN, ColorConstants.DARK_BLUE, ColorConstants.ROSE, ColorConstants.BLACK], ',')}
                                    />
                                }
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const ConnectComponent = connect((store: any) => ({dashboardStatistics: store.dashboardStatistics}))(Home) as any;
export default ConnectComponent
