import React from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash'

const NoPermissionsSidebar = ({children}: any) => {

    return (
        <>
            <div className="kocsistem-logo">
                <img src="/kocsistemLogo.png" alt=""/>
            </div>
            <div className="login-leftside-background">
                <div className="logo-area">
                    <img src="/semtpati_logo.png" alt=""/>
                </div>
                <div className="text-area">
                    <div className="text-2">
                        SemtPati, sokak hayvanlarıyla uyum ve güven içinde bir mahalle kültürü yaratmak için tasarlanmıştır.
                    </div>
                    <div className="d-none d-md-block">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
};

const AuthSidebarStyled = styled(NoPermissionsSidebar)``;
const NoPermissionsSidebarComponent = React.memo(AuthSidebarStyled, (p: any, n: any) => isEqual(p, n));
export default NoPermissionsSidebarComponent;
