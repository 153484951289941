import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

export interface ITextBadge {
    iconClass: string;
    title: string;
    text: string | number;
}

const TextBadge = (props: ITextBadge) => {
    return (
        <div>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <i className={props.iconClass}/>
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.title} primaryTypographyProps={{variant:'body2'}} secondary={props.text} secondaryTypographyProps={{variant:'body1', color:'textPrimary'}} />
            </ListItem>
        </div>
    );
};

export default TextBadge;