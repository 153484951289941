import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import Form from './Form';

export default ({match}: any) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`}>
                <Redirect to={`${match.url}/send`} />
            </Route>
            <Route exact path={`${match.url}/send`} component={ Form } />
        </Switch>
    )
}