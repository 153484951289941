import React, { memo, useEffect, useState } from 'react';
import { TitleBar, Tooltip } from '../../components';
import { IShelterListPageProps, IShelterListPageState, IShelterListPageDefaultValue, IShelterFilterProps, IShelterFilterDefaultValue, IShelterFormPageDefaultValue } from "./types";
//import { ILookUpListProps, ILookUpListDefaultValue } from "./../common/types"
import { connect } from "../../store";
//import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { DataGrid as Grid, Modal, Button, toastMessage/*, Input, Form, Select*/ } from 'oneframe-react';
import { IconButton, Avatar /*ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid*/ } from '@material-ui/core';
import Description from "../../components/Description";
// import { LookupConstants } from './../../_constants/lookup';
// import { getLookups } from "../../_actions/common";
// import { /*capitalize, filter,*/ join } from 'lodash';
import { getShelters, removeShelter } from "../../_actions/shelters";

interface IProps extends IShelterListPageProps {
}
interface IShelterState extends IShelterListPageState {
}
// interface ILookUpListState extends ILookUpListProps {
// }
interface IShelterFilterState extends IShelterFilterProps {
}

const Shelters = memo((props: IProps) => {
    
    const history = useHistory();
    const location = useLocation();
    // const classes = useStyles();

    const { pathname } = location;

    const { shelters/*, lookups*/ } = props;    

    const [state, setState] = useState<IShelterState>(location.state == null ? IShelterListPageDefaultValue : location.state.listState);
    const [filterState/*, setFilterState*/] = useState<IShelterFilterState>(IShelterFilterDefaultValue);
    //const [lookupState, setLookupState] = useState<ILookUpListState>(ILookUpListDefaultValue);
    const { triggerFilter } = filterState;

    const { page, pageSize, modalShow, deleteControl } = state;

    const operationTypes: any = {
        edit: 'edit',
        delete: 'delete'
    };

    const editHandleClick = (id: string, operation: string) => {
        if (operation === operationTypes.edit) {
            history.push(`/shelters/edit/${id}`, {listState: state, formDefaultState: IShelterFormPageDefaultValue}) // mevcut state'i daha sonra geri alabilmek için gönderiyoruz.
        } else if (operation === operationTypes.delete) {
            removeShelter(id, () => {
                toastMessage({
                    messageType: 'success',
                    message: 'Geçici Bakım Evi Silindi',
                    position: 'topRight',
                    icon: 'fad fa-trash',
                })

                setState((prevState: IShelterState) => ({
                   ...prevState,
                   deleteControl: true,
                   modalShow: false,
                   deleteItemId: ''
                }))
            });
        }
    };

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            pageSize: 10
        }));
    }, [pathname]);

    useEffect(() => {    
        getShelters(pageSize,page, 'shelters');
    }, [page, pageSize, deleteControl, triggerFilter]);


    useEffect(() => {
        if (shelters.payload && shelters.payload.items) {
                setState((prevState: IShelterState) => ({
                            ...prevState,
                            shelterList: shelters.payload.items,
                            page: shelters.payload.pageIndex,
                            totalCount: shelters.payload.totalCount,
                            totalPages: shelters.payload.totalPages,
                            deleteControl: false                
                         }));
        }
    }, [shelters.payload]);

    return (
    <>
        <TitleBar
            title={'Geçici Bakım Evleri'}
            loading={shelters.loading}
            button={[
                {
                    text: 'Geçici Bakım Evi Ekle',
                    onClick: () => {
                        history.push('/shelters/add');
                    },
                    iconLeft: <i className="fad fa-plus mr-2"/>,
                    className: 'custom-orange-button'
                }
            ]}
            breadcrumbs={[
                {
                    text: <i className="fas fa-home" />,
                    link: '/'
                },
                {
                    text: 'Geçici Bakım Evleri',
                    link: "/shelters"
                }
            ]}
        />

        <div className='wrapper'>
        {!shelters.loading ?
            (state.shelterList.length ?
                <Grid
                    data={state.shelterList}
                    page={state.page}
                    rowsPerPage={state.pageSize}
                    count={state.totalCount}
                    totalPages={state.totalPages}
                    serverSide={pageSize !== state.totalCount}
                    loadingState={shelters.loading}
                    onChangeRowsPerPage={(a: any) => {
                        setState(prevState => ({
                            ...prevState,
                            pageSize: a,
                            page: 0
                        }))
                    }}
                    onChangePage={(a: any) => {
                        setState(prevState => ({
                            ...prevState,
                            page: a
                        }))
                    }}
                    header={{
                        print: false,
                        download: false,
                        search: false,
                        filter: false,
                        viewColumns: false
                    }}
                    helperInfoText={'geçici bakım evi var'}
                    sort={false}
                    labels={{
                        noData: 'Hiç Data Yok',
                        headerTooltip: 'Sırala',
                        paginationNext: 'Sonraki Sayfa',
                        paginationPrev: 'Önceki Sayfa',
                        paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                        seperator: '/'
                    }}
                    hiddenColumns={['id']}
                    column={ 
                    [
                        {
                            label: '',
                            displayField: '',
                            customRender: () => {
                                return (
                                    <>
                                        <div className="rounded-image">
                                            <Avatar src="/dogHouse.png" />
                                        </div>
                                    </>
                                );
                            }
                        },                        
                        {
                            label: 'Ad',
                            displayField: 'name',
                        },
                        {
                            label: 'Şehir',
                            displayField: 'cityName',
                        },
                        {
                            label: 'İlçe',
                            displayField: 'countyName',
                        },
                        {
                            label: 'Telefon Numarası',
                            displayField: 'phoneNumber',
                        },
                        {
                            label: 'İşlemler',
                            displayField: '',
                            customRender: (value: any, rowData: any) => {
                                return (
                                    <>
                                        <div className="table-icon">
                                            <Tooltip title="Düzenle" placement="bottom">
                                                <IconButton className="detail-icon bg-blue" onClick={() => editHandleClick(rowData.id, operationTypes.edit)}>
                                                    <i className="fas fa-edit fa-1x"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="bottom">
                                                <IconButton 
                                                    className="remove-icon bg-red ml-2"
                                                    onClick={() => {
                                                        //editHandleClick(rowData.id, operationTypes.delete)
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            modalShow: true,
                                                            deleteItemId: rowData.id
                                                        }))
                                                    }}>
                                                    <i className="fas fa-trash fa-1x"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                )
                            }
                        }
                    ]}
                />
                : <Description
                    text={"Herhangi bir geçici bakım evi yok" } />
            ) : null
        }
        
        <Modal
            title={
                <div className="p-3">
                    <span><i className="fad fa-question-circle mr-2"/>Geçici Bakım Evi İşlemi</span>
                </div>
            }
            show={modalShow && state.deleteItemId.length > 0}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth={'xs'}
            footer={
                <div className="p-3">
                    <Button
                        text="Sil"
                        className="custom-red-button h-75 mr-2"
                        iconLeft={() => <i className="fad fa-trash mr-2"/>}
                        onClick={() => editHandleClick(state.deleteItemId, operationTypes.delete)}
                    />
                    <Button
                        text="İptal"
                        className="custom-blue-button h-75"
                        iconLeft={() => <i className="fad fa-times mr-2"/>}
                        onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                modalShow: false,
                                deleteItemId: ''
                            }))
                        }}
                    />
                </div>
            }
        >
            <span>Geçici bakım evini silmek istediğinize emin misin?</span>
        </Modal>
        </div>
    </>)    
});

// const useStyles = makeStyles((theme: Theme) =>
//         createStyles({
//             root: {
//                 width: '100%',
//             },
//             heading: {
//                 fontSize: theme.typography.pxToRem(15),
//                 fontWeight: theme.typography.fontWeightRegular,
//             },
//         }),
//     );

const ConnectComponent = connect((store: any) => ({ shelters: store.shelters, sheltersAllRecord: store.sheltersAllRecord, lookups: store.lookups, cities: store.cities, counties: store.counties, neighbourhoods: store.neighbourhoods }))(Shelters) as any;
export default ConnectComponent