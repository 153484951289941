import jsPDF from "jspdf";
import "jspdf-autotable";
import { font } from "./pdffont";
import moment from 'moment'
import {generateExportFileName} from "../../_helpers";

const generatePDF = (headers: any[], rows: any[], title: string, fileName: string) => {

  // kaynak https://www.freecodecamp.org/news/how-to-create-pdf-reports-in-react/
  // initialize jsPDF
  const doc: any = new jsPDF('l', 'mm', [297, 210]);

  var nowTime = moment().format('DD/MM/YYYY HH:mm');

  var totalPagesExp = '{total_pages_count_string}';

  var columns: any[] = [];
  headers.forEach(h => {
    columns.push({
      title: h.label,
      dataKey: h.value
    });
  });

  doc.addFileToVFS('Roboto-regular.tff', font);
  doc.addFont('Roboto-regular.tff', 'Roboto-Regular', 'normal');
  doc.setFont('Roboto-Regular', 'normal');

  doc.text(title, 14, 15);

  doc.autoTable({
    columns: columns,
    body: rows,
    startY: 20,
    styles: { font: 'Roboto-Regular' },
    didDrawPage: data => {
      doc.setFontSize(10);
      var xOffsetDate = (doc.internal.pageSize.width) - (doc.getStringUnitWidth(nowTime) * doc.internal.getFontSize() / 2);
      doc.text(nowTime, xOffsetDate, doc.internal.pageSize.height - 10);

      let footerStr = doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + ' / ' + totalPagesExp;
      }

      // Yazılan text'i ortalamak için: (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(footerStr) * doc.internal.getFontSize() / 2);
      var xOffsetPage = 135;

      doc.text(footerStr, xOffsetPage, doc.internal.pageSize.height - 10);
    }
  },)

  var totalDataCount = 'Toplam Kayıt Sayısı: ' + rows.length;
  doc.setFontSize(10);
  doc.text(totalDataCount, 14, doc.internal.pageSize.height - 10);

  if (typeof doc.putTotalPages === 'function') { 
    doc.putTotalPages(totalPagesExp) 
  }

  doc.setLanguage("tr-TR")
  doc.save(generateExportFileName(fileName) + `.pdf`);
};

export default generatePDF;