import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {IconButton} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export interface IImageGallery {
    imgList: IImageData[];
    onDeleteImage: any
}

export interface IImageData {
    id: string,
    filename: string,
    imageData: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)'      
    },
    item:{
       width: '300px !important',
       height: '190px !important',
    },
    buttonClass: {
        position:'relative',
        zIndex: 9999999,
        top: -66,
        right: -203,
        padding: '10px !important',
    },
    img: {        
        // width: 274,
        // height: 183
        maxHeight: 183,
        width: 'auto'
    }
  }),
);

const ImageGallery = (props: IImageGallery) => {
    const classes = useStyles();    
    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5}>
            {props.imgList.map((img) => (
                <GridListTile className={classes.item} key={img.id}>
                    <img className={classes.img} src={`data:image/jpeg;base64, ${img.imageData}`} alt={img.filename} />                    
                    <div className={classes.buttonClass}>
                    <IconButton
                        className={"remove-icon bg-red ml-2"}
                        onClick={() => {props.onDeleteImage(img.id)}}
                    >
                        <i className="fas fa-trash fa-1x"/>
                    </IconButton>
                    </div>
                </GridListTile>
            ))}
            </GridList>
        </div>
    );
};

export default ImageGallery;