import React, {memo, useEffect, useState } from 'react';
import {TitleBar} from '../../components'
import { getShelterAnimalById, getShelterList, addShelterAnimal, updateShelterAnimal, deleteShelterAnimalImage } from "../../_actions/shelterAnimals";
import {connect} from "../../store";
import {IShelterAnimalFormPageDefaultValue, IShelterAnimalFormPageProps, IShelterAnimalFormPageState} from "./types";
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {Paper} from '@material-ui/core';
import { Button, Form,  Select, Input, toastMessage } from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'
import { LookupConstants } from './../../_constants/lookup';
import DropzoneComponent from './../../components/Dropzone';
import { filter } from 'lodash';
import ImageGallery from "./ImageGallery";
import uuidv4 from "uuidv4";
import { getLookups} from './../../_helpers';
import {ShelterAnimalStatusList} from './../../_constants/shelterAnimalStatusType'
import Compress from "react-image-file-resizer";

interface IProps extends IShelterAnimalFormPageProps {
}

interface IFormState extends IShelterAnimalFormPageState {
}

const ShelterAnimal = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const parameter = useParams();    
    const paramID: string | null = parameter && parameter['id'] && parameter['id'].length ? parameter['id'] : null;    

    const [state, setState] = useState<IFormState>(IShelterAnimalFormPageDefaultValue);    
    const { shelterAnimalItem, lookups } = props;
    const [loading, setLoading] = useState(false);
   
    const showLoading = () => {        
        setLoading(true);
    }

    const hideLoading = () => {
        setLoading(false);
    }

    useEffect(() => {        
        // yeni kayıtta eski resimler geliyor. bunları temizlemek için yazıldı. Default value ataması yeterli olmuyor. Array den tek tek çıkartılması gerekiyor.        
        if (paramID == null) {
            var images = state.images;
            var length = images.length;

            for (let index = 0; index < length; index++) {
                images.splice(index, 1);
            }
            setState((prevState:IFormState) => ({...prevState, images: images}));
        }
    }, []);

    useEffect(() => {        
        if (paramID != null) {
            getShelterAnimalById(paramID, function(_ : any, response:any) {
                if (response.payload) { 
                setState((prevState: IFormState) => ({
                                     ...prevState,
                                     id: response.payload.id,
                                     insertedDate: response.payload.insertedDate,
                                     insertedUser: response.payload.insertedUser,
                                     updatedUser: response.payload.updatedUser,
                                     updatedDate: response.payload.updatedDate,
                                     breed: response.payload.breed,
                                     age: response.payload.age,
                                     gender: response.payload.gender,
                                     shelterId: response.payload.shelterId,
                                     about: response.payload.about,
                                     name: response.payload.name,
                                     physicalAttribute: response.payload.physicalattribute,    
                                     weight: response.payload.weight,
                                     status: response.payload.status,
                                     earringNumber: response.payload.earringNumber,
                                     kind: response.payload.kind,
                                     character: response.payload.character,
                                     microchipNumber: response.payload.microchipNumber,
                                     images: response.payload.imageBase == null ? [] : response.payload.imageBase,
                                     isAlive: response.payload.isAlive,
                                     isDeleted: response.payload.isDeleted,
                                     isActive: response.payload.isActive
                                 }));
                }
            });
        }

            var allLookups:any = getLookups();                    
            var ageList = filter(allLookups, function (o) { return o.key === LookupConstants.AGE });
            var genderList = filter(allLookups, function (o) { return o.key === LookupConstants.GENDER });
            var weightList = filter(allLookups, function (o) { return o.key === LookupConstants.WEIGHT });
            var physicalAttributeList = filter(allLookups, function (o) { return o.key === LookupConstants.PHYSICALATTRIBUTE });
            var kindList = filter(allLookups, function (o) { return o.key === LookupConstants.KIND });
            var characterList = filter(allLookups, function (o) { return o.key === LookupConstants.CHARACTER });

            setState((prevState: IFormState) => ({...prevState, 
                ageList: ageList[0].values,
                genderList: genderList[0].values,
                weightList: weightList[0].values,
                physicalAttributeList: physicalAttributeList[0].values,
                kindList: kindList[0].values,
                characterList: characterList[0].values,
            }));

            getShelterList(function (_:any, response:any) {
                if (response.payload) {                    
                    setState((prevState: IFormState) => ({...prevState, shelterList: response.payload.items}));
                }
            });         
        
    }, [paramID]);

    const handleSubmit = async () => {
        showLoading();
        const data: any = {
            name: state.name,
            breed: state.breed,
            age: state.age,
            gender: state.gender,
            shelterId: state.shelterId,
            weight: state.weight,
            images: state.images,
            about: state.about,
            physicalAttribute: state.physicalAttribute,
            kind:state.kind,
            earringNumber: state.earringNumber,
            status: state.status,
            character: state.character,
            microchipNumber: state.microchipNumber,
            isDelete: state.isDeleted,
            isActive: state.isActive,
            isAlive: state.isAlive,
        }

        const onError = (_:any, response:any) => {   
            hideLoading();         
            if (response.error.errors) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: response.error.errors.message,
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',                  
                });
            }
        };

        if (paramID !== null) {                        
            data.id = state.id;
            await updateShelterAnimal(data, (_:any, response:any) => {
                hideLoading();
                toastMessage({
                    messageType: 'success',
                    message: 'Geçici Bakım Evi Sakini Güncellendi',
                    position: 'topRight',
                    icon: 'fad fa-exclamation-square',
                });
    
                redirectToList(response);
            }, onError);
        } else {
            await addShelterAnimal(data, (_:any, response:any) => {
                hideLoading();

                toastMessage({
                    messageType: 'success',
                    message: 'Geçici Bakım Evi Sakini Eklendi',
                    position: 'topRight',
                    icon: 'fad fa-exclamation-square',
                });

                redirectToList(response);                
            }, onError);
        } 
    };

    const redirectToList = async (response) => {    
        if (response.statusCode === 200){ 
            history.replace('/shelteranimals/list', location.state != null ? {listState: location.state.listState} : null);
        }
    }

    const handleChange = (e: any) => {        
        setState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onFileUpladed = async (file) => {  
        showLoading();      
        const image = await resizeFile(file);
        const result = clearBase64(image);

        var images = state.images;
        if (images == null){
            images = [];
        }

        images.push({
            id: uuidv4(),
            fileName: file.name,
            imageData: result,
            isNew: true
        });
        
        setState((prevState:IFormState) => ({...prevState, images: images}));        
        hideLoading();
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Compress.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            50,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
  });

    const onDeleteImage = (id: string) => {
        showLoading();        
        var images = state.images;
        var image = filter(images, function (o) { return o.id === id });
        if(!image[0].isNew) // önceden eklenmiş db de kayıt var silelim.
        {
            var data = {
                ShelterAnimalId: state.id,
                ImageId: id
            }
    
            deleteShelterAnimalImage(data, (_:any, response:any) => {
                hideLoading();
                if (response.statusCode === 200) {                     
                    var index = images.map(function(e) { return e.id == id }).indexOf(true);
                    images.splice(index, 1);       
                    setState((prevState:IFormState) => ({...prevState, images: images}));
                }
            });
        }
        else {
            //db de kayıt yok sadece listeden çıkartalım.                        
            var index = images.map(function(e) { return e.id == id }).indexOf(true);
            images.splice(index, 1);
            setState((prevState:IFormState) => ({...prevState, images: images}));
            hideLoading();
        }        
    }

    const clearBase64 = (imageData:any) => {
        if (imageData != null)
        {
            // base64 çevirimi sonrasında kayıtbaşındaki değerleri siler.
            let searchText:string = ';base64,';
            let index:number = imageData.indexOf(searchText);
            return imageData.substring(searchText.length + index);
        } 
        else {
            return "";
        }
    }

    const renderImages = () => {
        
        var imgs: any[] = [];
        
        if (state.images && state.images.length > 0) {
            imgs = state.images;
        }

        return <div>                            
                <ImageGallery imgList={imgs} onDeleteImage={onDeleteImage} />
            </div>
    }

    


    const renderFormInfo = () => {
        return (
            paramID !== null && !shelterAnimalItem.loading ?
                <>
                    <div className="ml-auto info-area">
                        <em><strong>Ekleyen Kişi : </strong><span>{state.insertedUser}</span></em>
                        <em><strong>Eklenme Tarihi : </strong><span>{state.insertedDate && moment(state.insertedDate).format('LL')}</span></em>
                        {(state.updatedUser && state.updatedUser.length) || (state.updatedDate && state.updatedDate.length) ?
                            <>
                                <em><strong>Son Güncelleyen Kişi : </strong><span>{state.updatedUser}</span></em>
                                <em><strong>Son Güncellenme Tarihi : </strong><span>{state.updatedDate && moment(state.updatedDate).format('LL')}</span></em>
                            </>
                            :
                            null
                        }
                    </div>
                </> : null
        );
    };

    
    const renderForm = () => {        
        return (
            !shelterAnimalItem.loading && !lookups.loading ? 
            <>                
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Geçici Bakım Evi Sakini Detayı</strong></h6>
                                {renderFormInfo()}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <Form onSubmit={() => handleSubmit()}>
                        <div className='row mb-4'>
                            <div className="col-12">
                            <DropzoneComponent onFileUpladed = {onFileUpladed} />   
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-12'>                            
                                {renderImages()}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-3">
                                <div className='form-field'>
                                    <Select 
                                        id='kind'
                                        name='kind'
                                        caption={'Tür'}
                                        className="custom-login-field"
                                        options={{
                                            data: state.kindList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.kind
                                        }}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',   
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>                                
                            </div>
                            <div className='col-3'>
                                    <Input
                                        id="microchipNumber"
                                        name="microchipNumber"
                                        caption="Mikroçip"
                                        className="custom-login-field"                                        
                                        value={state!.microchipNumber || ''}
                                        onChange={handleChange}                                        
                                    />
                            </div>
                            <div className='col-3'>
                                    <Input
                                        id="earringNumber"
                                        name="earringNumber"
                                        caption="Küpe Numarası"
                                        className="custom-login-field"                                        
                                        value={state!.earringNumber || ''}
                                        onChange={handleChange}                                        
                                    />
                            </div>
                            <div className="col-3">
                                <div className="form-field">
                                    <Input
                                        id="name"
                                        name="name"
                                        caption="İsim"
                                        className="custom-login-field"
                                        value={state!.name || ''}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',   
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>                                         
                            </div>                            
                        </div>                       
                        <div className="row mb-4">
                        <div className="col-3">
                                    <Select
                                        onChange={handleChange}
                                        id="gender"
                                        name="gender"
                                        caption={'Cinsiyet'}
                                        options={{
                                            data: state.genderList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.gender
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                            </div>
                            <div className="col-3">
                                <div className="form-field">
                                <Select
                                        onChange={handleChange}
                                        id="age"
                                        name="age"
                                        caption={'Yaş'}
                                        options={{
                                            data: state.ageList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.age
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                    
                                </div>
                            </div>  
                            <div className="col-3">
                                <div className="form-field">
                                <Select
                                        onChange={handleChange}
                                        id="weight"
                                        name="weight"
                                        caption={'Kilosu'}
                                        options={{
                                            data: state.weightList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.weight
                                        }}                                        
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="character"
                                        name="character"
                                        caption={'Karakteri'}
                                        options={{
                                            data: state.characterList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.character
                                        }}                                        
                                    />
                                </div>
                            </div>   
                        </div>      
                        <div className="row mb-4">
                        <div className="col-3">
                                <div className="form-field">
                                <Select
                                        onChange={handleChange}
                                        id="physicalAttribute"
                                        name="physicalAttribute"
                                        caption={'Fiziksel Durum'}
                                        options={{
                                            data: state.physicalAttributeList,
                                            displayField: 'value',
                                            displayValue: 'value',
                                            selected: state!.physicalAttribute
                                        }}                                        
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="shelterId"
                                        name="shelterId"
                                        caption={'Bulunduğu Yer'}
                                        options={{
                                            data: state.shelterList,
                                            displayField: 'name',
                                            displayValue: 'id',
                                            selected: state!.shelterId
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div> 
                            <div className="col-3">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="isAlive"
                                        name="isAlive"
                                        caption={'Yaşıyor Mu?'}
                                        options = {{
                                            data: [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }],
                                            displayField: 'name',
                                            displayValue: 'value',
                                            selected: state!.isAlive.toString()
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div> 
                            <div className='col-3'>
                                <div className='form-field'>
                                    <Select
                                        onChange={ handleChange }
                                        id="isDeleted"
                                        name="isDeleted"
                                        caption= {'Silinmiş Mi?'}
                                        options = {{
                                            data: [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }],
                                            displayField: 'name',
                                            displayValue: 'value',
                                            selected: state!.isDeleted.toString()
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>       
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <div className='form-field'>
                                    <Select
                                        onChange={ handleChange }
                                        id="isActive"
                                        name="isActive"
                                        caption= {'Aktif/Pasif'}
                                        options={{
                                            data: [{ value: "true", name: "Aktif" }, { value: "false", name: "Pasif" }],
                                            displayField: 'name',
                                            displayValue: 'value',
                                            selected: state!.isActive.toString()
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='form-field'>
                                    <Select
                                        onChange={ handleChange }
                                        id="status"
                                        name="status"
                                        caption= {'Sahiplendirme Durumu'}
                                        options = {{
                                            data: ShelterAnimalStatusList,
                                            displayField: 'text',
                                            displayValue: 'value',
                                            selected: state!.status
                                        }}
                                    />
                                </div>
                            </div>
                        </div>          
                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-field">                                    
                                    <Input
                                        id="about"
                                        name="about"
                                        caption="Hakkında"
                                        type="text"
                                        multiline
                                        rows={3}
                                        className="custom-login-field"
                                        value={state!.about || ''}
                                        onChange={handleChange}                                        
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-2 ml-auto">
                                <div className="form-field">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        text={'Kaydet'}
                                        className="custom-blue-button"
                                        iconRight={() => <i className="fad fa-save ml-2"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Paper>
            </> : null
        );
    };

    return (
        <div>
            <TitleBar
                title={paramID !== null ? 'Geçici Bakım Evi Sakini Düzenle' : 'Geçici Bakım Evi Sakini Ekle'}
                loading={shelterAnimalItem.loading || loading}
                button={[
                    {
                        text: 'Geçici Bakım Evi Sakini Listesine Dön',
                        onClick: () => {                             
                            history.replace('/shelteranimals/list', location.state != null ? {listState: location.state.listState} : null);
                        },
                        iconLeft: <i className="fad fa-arrow-left mr-2"/>,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Geçici Bakım Evi Sakinleri',
                        link: '/shelteranimal'
                    },
                    {
                        text: paramID !== null ? 'Geçici Bakım Evi Sakini Düzenle' : 'Geçici Bakım Evi Sakini Ekle',
                        link: '/shelteranimal/add'
                    }
                ]}
            />

            <div className="wrapper">
                {renderForm()}
            </div>
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {
        shelterAnimalItem: store.shelterAnimalItem,
        lookups: store.lookups,
        deleteShelterAnimalImage: store.deleteShelterAnimalImage
    }))(ShelterAnimal) as any;
export default ConnectComponent
