import React, { memo, useEffect, useState } from 'react';
import { TitleBar, Tooltip } from '../../components'
import { getIncident, getIncidentTypes } from "../../_actions/incident";
import { connect } from "../../store";
import { DataGrid as Grid, Modal, Button, Input, DatePicker, Form, Select } from 'oneframe-react'
import { IIncidentListPageDefaultValue, IIncidentListPageProps, IIncidentListPageState, IIncidentFilterDefaultValue, IIncidentFilterProps, IIncidentItemProps, ITypeListProps, ITypeListDefaultValue } from "./types";
import { ILookUpListProps, ILookUpListDefaultValue, ILocationListProps, ILocationListDefaultValue } from "./../common/types"
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/tr'
import { IconButton, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Description from "../../components/Description";
import { filter, join } from 'lodash';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getCities, getCountiesOfCity, getLookups, getNeighbourhoodsOfCounty } from '../../_actions/common';
import { LookupConstants } from './../../_constants/lookup'
import DownloadExcel from './../../components/ExcelExport/exceldownload'
import generatePDF from '../../components/PdfExport/pdfexport';
import { ExportTypes } from './../../_constants/exportType';
import { ExportNames } from './../../_constants/exportNames';

interface IProps extends IIncidentListPageProps {
}

interface IIncidentState extends IIncidentListPageState {
}

interface IIncidentFilterState extends IIncidentFilterProps {
}

interface ILookUpListState extends ILookUpListProps {
}

interface ILocationListState extends ILocationListProps {

}

interface ITypeListState extends ITypeListProps {
}

const Incident = memo((props: IProps) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    // detay sayfasından geri dönerken, kaldığı sayfadan devam edebilmesi için yapıldı.
    const [state, setState] = useState<IIncidentState>(location.state == null ? IIncidentListPageDefaultValue : location.state.listState);
    const [lookupState, setLookupState] = useState<ILookUpListState>(ILookUpListDefaultValue);
    const [filterState, setFilterState] = useState<IIncidentFilterState>(IIncidentFilterDefaultValue);
    const [locationState, setLocationState] = useState<ILocationListState>(ILocationListDefaultValue);
    const [typeState, setTypeState] = useState<ITypeListState>(ITypeListDefaultValue);
    const [exportDataState, setExportDataState] = useState<IIncidentItemProps[] | null>(null);
    const { incident: { payload, loading }, incidentAllRecord, incidentTypes, lookups, cities, counties, neighbourhoods } = props;
    const { page, pageSize, deleteControl, modalShow } = state;
    const { triggerFilter } = filterState;

    const { pathName } = location;

    const operationTypes: any = {
        edit: 'edit'
    };

    useEffect(() => {
        getIncident(pageSize, page, filterState, 'incident');
    }, [page, pageSize, deleteControl, triggerFilter]);

    useEffect(() => {
        const lookupList = [LookupConstants.INCIDENT_TYPE];
        getLookups(join(lookupList, LookupConstants.LOOKUPSEPARATOR));
        getIncidentTypes();
        getCities();
    }, [pathName])

    useEffect(() => {
        if (payload && payload.items) {

            // Daha önceden yüklenmiş olan payload'daki page ve pageSize'ı iptal etmek için eklendi. Çünkü sonsuz döngüye giriyordu.
            if (page == payload.pageIndex && pageSize == payload.pageSize) {
                setState((prevState: IIncidentState) => ({
                    ...prevState,
                    incidentList: payload.items,
                    page: payload.pageIndex,
                    totalCount: payload.totalCount,
                    totalPages: payload.totalPages,
                    pageSize: payload.pageSize,
                    deleteControl: false,
                }));
            }
        }

    }, [payload]);

    useEffect(() => {
        if (incidentAllRecord.payload && incidentAllRecord.payload.items) {
            incidentAllRecord.payload.items.forEach((item) => {
                item.insertedDate = moment(item.insertedDate).format('LL');
            });

            if (state.exportType == ExportTypes.EXCEL) {
                setExportDataState(incidentAllRecord.payload.items);
            } else if (state.exportType == ExportTypes.PDF) {
                generatePDF(exportColumns, incidentAllRecord.payload.items, ExportNames.INCIDENCE.TITLE, ExportNames.INCIDENCE.FILE_NAME)
            }

            setState((prevState: IIncidentState) => ({
                ...prevState,
                exportType: ''
            }));
        }
    }, [incidentAllRecord.payload]);

    useEffect(() => {
        if (incidentTypes.payload && Object.keys(incidentTypes.payload).length > 0) {
            setTypeState((prevState: any) => ({
                ...prevState,
                incidentTypes: incidentTypes.payload
            }));
        }
    }, [incidentTypes.payload]);

    useEffect(() => {
        if (lookups.payload && Object.keys(lookups.payload).length > 0) {
            setLookupState(lookups.payload);
        }
    }, [lookups.payload]);

    useEffect(() => {
        if (cities.payload && Object.keys(cities.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                cities: cities.payload
            }));
        }
    }, [cities.payload]);

    useEffect(() => {
        if (filterState.cityId != null) {
            getCountiesOfCity(filterState.cityId);
        }
    }, [filterState.cityId]);

    useEffect(() => {
        if (counties.payload && Object.keys(counties.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                counties: counties.payload
            }));
        }
    }, [counties.payload]);

    useEffect(() => {
        if (filterState.countyId != null) {
            getNeighbourhoodsOfCounty(filterState.countyId);
        }
    }, [filterState.countyId]);

    useEffect(() => {
        if (neighbourhoods.payload && Object.keys(neighbourhoods.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                neighbourhoods: neighbourhoods.payload
            }));
        }
    }, [neighbourhoods.payload]);

    const getAllDataOnHandleClick = (exportType: string) => {
        setState((prevState: IIncidentState) => ({
            ...prevState,
            exportType: exportType
        }));

        // state sıfırlanmazsa ikinci kez indirilemiyor.
        setExportDataState(null);
        // tüm kayıtları çekebilmek için totalCount ve page 0 kullanılıyor.
        getIncident(32000, 0, filterState, 'incidentAllRecord');
        // useEffect incidentAllRecord.payload kısmına düşer.
    }

    const handleClick = (id: string, operation: string) => {
        if (operation === operationTypes.edit) {
            history.push(`/incidence/edit/${id}`, {listState: state}) // mevcut state'i geri alabilmek için gönderiyoruz.
        }
    };

    const filterHandleChange = (e: any) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const filterHandleSubmit = () => {
        // arama sonrasında page 0 a çekilmeli bu filtreyi de tetikler. page 0 ise, else kısmı tetikler.
        if (state.page != 0) {
            setState((prevState) => ({
                ...prevState,
                page: 0
            }));
        }
        else {
            //triggerFilter değiştiğinde use effect getIncident a düşer.
            setFilterState((prevState) => ({
                ...prevState,
                triggerFilter: !triggerFilter
            }));
        }
    }

    const resetFilterHandle = () => {
        setFilterState(IIncidentFilterDefaultValue);
    }

    const getLookupValue = (type) => {
        if (lookupState) {
            var list = filter(lookupState, function (o) { return o.key === type });
            if (list.length > 0) {
                return list[0].values;
            } else
                return [];
        }
        return [];
    }

    const exportColumns = [
        { label: "Vaka ID", value: "incidentId" },
        { label: "Vaka Kayıt Tarihi", value: "insertedDate" },
        { label: "Vaka Tipi", value: "incidentType" },
        { label: "Durumu", value: "statusText" },
        { label: "Semt Sakini Adı", value: "streetAnimalName" },
        { label: "İl", value: "cityName" },
        { label: "ilçe", value: "countyName" },
        { label: "Mahalle", value: "neighbourhoodName" },
        { label: "Aktif/Pasif", value: "isDeleted" },
    ]

    return (
        <div>
            <TitleBar
                title={'Vakalar'}
                loading={loading}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Vakalar',
                        link: '/incident'
                    }
                ]}
            />

            <div className="wrapper">
                <div className="mb-3">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filtreleme Kriterleri</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Form onSubmit={() => filterHandleSubmit()}>
                                <MUIGrid container spacing={3}>
                                    <MUIGrid item xs={2}>
                                        <Input
                                            id="incidentId"
                                            name="incidentId"
                                            caption="Vaka Id"
                                            type="number"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.incidentId != null ? String(filterState.incidentId) : undefined}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <DatePicker id="insertedDate"
                                            name="insertedDate"
                                            caption="Vaka Kayıt Tarihi"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.insertedDate}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="incidentType"
                                            name="incidentType"
                                            caption={'Vaka Tipi'}
                                            options={{
                                                data: getLookupValue(LookupConstants.INCIDENT_TYPE),
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.incidentType
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="streetAnimalName"
                                            name="streetAnimalName"
                                            caption="Semt Sakini Adı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.streetAnimalName}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={2}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="status"
                                            name="status"
                                            caption={'Durumu'}
                                            options={{
                                                data: typeState.incidentTypes,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.status
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="cityId"
                                            name="cityId"
                                            caption={'İl'}
                                            options={{
                                                data: locationState.cities,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.cityId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="countyId"
                                            name="countyId"
                                            caption={'İlçe'}
                                            options={{
                                                data: locationState.counties,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.countyId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="neighbourhoodId"
                                            name="neighbourhoodId"
                                            caption={'Mahalle'}
                                            options={{
                                                data: locationState.neighbourhoods,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.neighbourhoodId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="isDeleted"
                                            name="isDeleted"
                                            caption={'Aktif/Pasif'}
                                            options={{
                                                data: [{ value: "false", name: "Aktif" }, { value: "true", name: "Pasif" }],
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected: filterState!.isDeleted
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={12}>
                                        <MUIGrid container spacing={0} direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="reset"
                                                    text="Temizle"
                                                    className='custom-blue-button'
                                                    onClick={resetFilterHandle}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="submit"
                                                    text="Ara"
                                                    className='custom-green-button'
                                                />
                                            </MUIGrid>
                                        </MUIGrid>
                                    </MUIGrid>
                                </MUIGrid>
                            </Form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="export-row mb-3 text-right">
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.PDF)}>
                        <Button type="submit" text="PDF" className='custom-orange-button' iconLeft={() => <i className="fas fa-file-pdf mr-2" />}></Button>
                    </Form>
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.EXCEL)}>
                        <Button type="submit" text="Excel" className='custom-green-button ml-3' iconLeft={() => <i className="fas fa-file-excel mr-2" />}></Button>
                        {exportDataState != null && exportDataState.length > 0 ?
                            <DownloadExcel
                                fileName={ExportNames.INCIDENCE.FILE_NAME}
                                buttonText="Excel İndir"
                                data={exportDataState}
                                sheetName= {ExportNames.INCIDENCE.TITLE}
                                columns = {exportColumns}
                            />
                            :
                            null
                        }
                    </Form>
                </div>
                {!loading ?
                    (
                        state.incidentList.length ?
                            <Grid
                                data={state.incidentList}
                                page={state.page}
                                rowsPerPage={state.pageSize}
                                count={state.totalCount}
                                totalPages={state.totalPages}
                                serverSide={true}
                                loadingState={loading}
                                onChangeRowsPerPage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        pageSize: a,
                                        page: 0
                                    }))
                                }}
                                onChangePage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        page: a
                                    }))
                                }}
                                header={{
                                    print: false,
                                    download: false,
                                    search: false,
                                    filter: false,
                                    viewColumns: false,
                                }}
                                helperInfoText={'vaka var'}
                                sort={false}
                                labels={{
                                    noData: 'Hiç Data Yok',
                                    headerTooltip: 'Sırala',
                                    paginationNext: 'Sonraki Sayfa',
                                    paginationPrev: 'Önceki Sayfa',
                                    paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                    seperator: '/'
                                }}
                                hiddenColumns={['Id']}
                                column={[
                                    {
                                        label: 'Vaka ID',
                                        displayField: 'incidentId'
                                    },
                                    {
                                        label: 'Vaka Kayıt Tarihi',
                                        displayField: 'insertedDate',
                                        customRender: (a) => {
                                            return a && moment(a).format('LL')
                                        }
                                    },
                                    {
                                        label: 'Vaka Tipi',
                                        displayField: 'incidentType'
                                    },
                                    {
                                        label: 'Durumu',
                                        displayField: 'statusText'
                                    },
                                    {
                                        label: 'Semt Sakini Adı',
                                        displayField: 'streetAnimalName'
                                    },
                                    {
                                        label: 'İl',
                                        displayField: 'cityName'
                                    },
                                    {
                                        label: 'ilçe',
                                        displayField: 'countyName'
                                    },
                                    {
                                        label: 'Mahalle',
                                        displayField: 'neighbourhoodName'
                                    },
                                    {
                                        label: 'Aktif/Pasif',
                                        displayField: 'isDeleted'
                                    },
                                    {
                                        label: 'İşlemler',
                                        displayField: '',
                                        customRender: (value: any, rowData: any) => {
                                            return (
                                                <>
                                                    <div className="table-icon">
                                                        <Tooltip title="Detay" placement="bottom">
                                                            <IconButton className="detail-icon bg-blue" onClick={() => handleClick(rowData.id, operationTypes.edit)}>
                                                                <i className="fas fa-edit fa-1x" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </>
                                            )
                                        }
                                    }
                                ]}
                            />
                            : <Description text="Herhangi bir kayıt bulunamadı" />
                    ) : null
                }

                <Modal
                    title={
                        <div className="p-3">
                            <span><i className="fad fa-question-circle mr-2" />Soru Silme İşlemi</span>
                        </div>
                    }
                    show={modalShow && state.deleteItemId.length > 0}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={'xs'}
                    footer={
                        <div className="p-3">
                            <Button
                                text="Sil"
                                className="custom-red-button h-75 mr-2"
                                iconLeft={() => <i className="fad fa-trash mr-2" />}
                                onClick={() => handleClick(state.deleteItemId, operationTypes.delete)}
                            />
                            <Button
                                text="İptal"
                                className="custom-blue-button h-75"
                                iconLeft={() => <i className="fad fa-times mr-2" />}
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        modalShow: false,
                                        deleteItemId: ''
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span>Soruyu silmek istediğine emin misin?</span>
                </Modal>
            </div>
        </div>
    )
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ConnectComponent = connect((store: any) => ({ incident: store.incident, incidentAllRecord: store.incidentAllRecord, incidentTypes: store.incidentTypes, lookups: store.lookups, cities: store.cities, counties: store.counties, neighbourhoods: store.neighbourhoods }))(Incident) as any;
export default ConnectComponent