import React, {useState} from 'react';
import {connect} from '../../store'
import {Button, Form, Input} from 'oneframe-react'
import {Link} from 'react-router-dom';
import {LoginStateType, LoginPropsType, LoginStateDefaultValue} from "./type";
import {checkAuthentication} from "../../_actions/auth";
import Sidebar from "./Sidebar";
import { getHomePage } from "./../../_helpers/index";

type IState = LoginStateType

const Login = (props: LoginPropsType) => {

    let dateYear: any = new Date();

    const {history} = props;
    const [state, setState] = useState<IState>(LoginStateDefaultValue) as any;

    const handleSubmit = async () => {
        const authCheck = await checkAuthentication(state);

        if (!authCheck.error) {
            history.push(getHomePage());
        }
    };

    const handleChange = (e: any) => {
        setState((prevState: IState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    };

    return (
        <div className="login-container">
            <Sidebar>
                <div className="text-2">
                    &copy;{dateYear.getFullYear()}
                </div>
            </Sidebar>
            <div className="login-rightside-form">
                <div className="form-area">
                    <div className="title">
                        <h3 className="">
                            SEMTPATİ'YE HOŞGELDİNİZ
                        </h3>
                    </div>
                    <div className="error-container"/>
                    <div className="form">
                        <Form onSubmit={() => handleSubmit()}>
                            <div className="form-field">
                                <Input
                                    id="email"
                                    name="email"
                                    caption="E-Posta"
                                    className="custom-login-field"
                                    value={state!.email || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'email',
                                            message: 'Geçerli bir e-posta adresi girin'
                                        }
                                    ]}
                                />
                            </div>
                            <div className="form-field">
                                <Input
                                    id="password"
                                    name="password"
                                    caption="Şifre"
                                    type="password"
                                    className="custom-login-field"
                                    value={state!.password || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'minLength',
                                            value: 6,
                                            message: 'Minimum 6 karakter girilmelidir',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-field mb-5">
                                <Link to={'/auth/forgotpassword'} className="">
                                    Şifreni mi unuttun?
                                </Link>
                            </div>
                            <div className="form-field">
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="custom-orange-button p-3"
                                    text={'Giriş'}
                                    iconRight={() => <i className="fad fa-arrow-right ml-2"/>}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default connect((state: any) => (state))(Login);
