import React, {useState} from 'react';
import styled from "styled-components";
import {isEqual} from 'lodash'
import {connect} from '../../store'
import {Button, Form, Input} from 'oneframe-react'
import {NewPasswordStateType, NewPasswordStateDefaultValue} from "./type";
import {resetPassword} from "../../_actions/auth";
import Sidebar from "./Sidebar";
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

type IState = NewPasswordStateType

const Login = (props: any) => {

    const [state, setState] = useState<IState>(NewPasswordStateDefaultValue) as any;

    const location = useLocation();
    const parameter = queryString.parse(location.search);

    const handleSubmit = async () => {
        let data: any = {
            ...parameter,
            ...state
        };
        let result: any = await resetPassword(data);

        if (result.statusCode === 200) {
            setState(NewPasswordStateDefaultValue);
        }
    };

    const handleChange = (e: any) => {
        setState((prevState: IState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <div className="login-container">
            <Sidebar>
                <div className="text-2">
                    Üyeliğin var mı?
                    <Button
                        type="button"
                        onClick={() => props.history.push('/auth/login')}
                        className="custom-blue-button outline ml-3 p-3"
                        text={'Giriş yap'}
                    />

                </div>
            </Sidebar>
            <div className="login-rightside-form">
                <div className="form-area">
                    <h3>
                        Yeni şifre oluştur
                    </h3>
                    <div className="form">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-field">
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    caption="Yeni Şifre"
                                    className="custom-login-field"
                                    value={state!.password || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'minLength',
                                            value: 6,
                                            message: 'Minimum 6 karakter girilmelidir',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-field">
                                <Input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    caption="Yeni Şifre Tekrar"
                                    className="custom-login-field"
                                    value={state!.confirmPassword || ''}
                                    onChange={handleChange}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'custom',
                                            validate: (inputValue: any) => {
                                                return inputValue === state.password;
                                            },
                                            message: 'Parola bilgileri uyuşmuyor',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-field">
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="custom-orange-button p-3"
                                    text={'Oluştur'}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
};

const LoginStyled = styled(Login)``;
const LoginComponent = React.memo(LoginStyled, (p: any, n: any) => isEqual(p, n));
export default connect((state: any) => (state))(LoginComponent);
