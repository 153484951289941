import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {krax} from "../store";

import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();


export const getLookups = async(lookups:string) => {

    const urlSuffix = '/lookups';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?keys=${lookups}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'lookups',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

export const getAllLookups = async(success:any) => {
    const urlSuffix = '/lookups';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'lookups',
        request: kraxRequest,
        onSuccess: success,
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

export const getCities = async() => {
    const urlSuffix = '/cities';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'cities',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

export const getCountiesOfCity = async(cityId: number) => {
    const urlSuffix = '/cities';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${cityId}/counties`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'counties',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}


export const getNeighbourhoodsOfCounty = async(countyId: number) => {
    const urlSuffix = '/counties';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${countyId}/neighbourhoods`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'neighbourhoods',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

export const apiGetRequest = async(url:string, kraxName: string, success:any) => {    
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };
    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: success,
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

export const apiPostRequest = async(url:string, kraxName: string, data, onSuccess:any, onError?: any) => {    
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: data
    };

    const defaultError = (_: any, state: any, error: any) => {       

        if (error && !state.error) {
            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: 'ERR_CONNECTION_REFUSED',
                position: 'topRight',
                overlay: true,
                icon: 'fad fa-exclamation-square'                
            })
        } else {
            handleErrorType(error);
            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: state.error.message,
                position: 'topRight',
                overlay: true,
                icon: 'fad fa-exclamation-square'                
            })
        }
    }

    return krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: onSuccess,
        onError: onError == null ? defaultError : onError          
    });
}

export const apiPostRequestWithoutAuthorization = async(url:string, kraxName: string, data, onSuccess:any, onError?: any) => {    
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data
    };

    const defaultError = (_: any, state: any, error: any) => {       

        if (error && !state.error) {
            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: 'ERR_CONNECTION_REFUSED',
                position: 'topRight',
                overlay: true,
                icon: 'fad fa-exclamation-square'                
            })
        } else {
            handleErrorType(error);
            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: state.error.message,
                position: 'topRight',
                overlay: true,
                icon: 'fad fa-exclamation-square'                
            })
        }
    }

    return krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: onSuccess,
        onError: onError == null ? defaultError : onError          
    });
}