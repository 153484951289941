import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {krax} from "../store";
import {toastMessage} from 'oneframe-react';
import { CategoryGroup } from '../_constants/categoryGroup';

const urlBase = () => getAuthApiBaseUrl();

export const getFaq = async (PageSize: number = 50, PageIndex: number = 0, Group: CategoryGroup = CategoryGroup.Faq) => {

    const urlSuffix = '/FAQInfo';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?PageSize=${PageSize}&PageIndex=${PageIndex}&Group=${Group}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'faq',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const getFaqCategories = async (Group: CategoryGroup = CategoryGroup.Faq) => {

    const urlSuffix = '/FAQCategory';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?Group=${Group}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'faqCategories',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};


export const getFaqById = async (id: string) => {

    const urlSuffix = '/FAQInfo';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'faqItem',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const updateFaq = async (id: string, data: any) => {

    const urlSuffix = '/FAQInfo';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: data
    };

    return krax({
        name: 'faq',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Soru Güncellendi',
                position: 'topRight',
                icon: 'fad fa-question',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const setFaq = async (data: any) => {

    const urlSuffix = '/FAQInfo';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: data
    };

    return krax({
        name: 'faq',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Soru Eklendi',
                position: 'topRight',
                icon: 'fad fa-check',
            })
        },
        onError: (_: any, state: any, error: any) => {

            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const removeFaq = async (id: string) => {

    const urlSuffix = '/FAQInfo/deleteFaq';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'faq',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Soru Silindi',
                position: 'topRight',
                icon: 'fad fa-trash',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};
