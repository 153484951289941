export const ShelterAnimalStatusType = {
    Adoptable: 0,    
    Adopted: 2,
};

export const ShelterAnimalStatusList =
     [
        {
            value: ShelterAnimalStatusType.Adoptable,
            text: 'Sahiplendirilebilir'
        },
        {
            value: ShelterAnimalStatusType.Adopted,
            text: 'Sahiplendirildi'
        },
    ];