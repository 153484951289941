import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export interface IImageGallery {
    imgList: IImageData[];
}

export interface IImageData {
    id: string,
    filename: string,
    imageData: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    img: {
        maxWidth: '100%',
        height: "auto",
    },
  }),
);

const ImageGallery = (props: IImageGallery) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5}>
            {props.imgList.map((img) => (
                <GridListTile key={img.id}>
                    <img className={classes.img} src={`data:image/jpeg;base64, ${img.imageData}`} alt={img.filename} />                    
                </GridListTile>
            ))}
            </GridList>
        </div>
    );
};

export default ImageGallery;