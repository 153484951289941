import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {krax} from "../store";
import { /*apiGetRequest, , apiPostRequest*/ 
apiPostRequest} from "./common";

import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

export const getShelters = async (PageSize: number = 50, PageIndex: number = 0, kraxName= 'shelters') => {    
    const urlSuffix = '/shelter/getList';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?PageSize=${PageSize}&PageIndex=${PageIndex}`));

    //apiGetRequest(url, 'shelterList', success);
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };
    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const getShelterById = async (id: string, success : any) => {
    const urlSuffix = '/shelter';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));
    console.log(url);
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'shelterItem',
        request: kraxRequest,
        onSuccess: success
        // (_: any, response: any) => {
        //     success(response);
        // },
        ,
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const removeShelter = async (id: string, success: any) => {
    debugger;
    const urlSuffix = '/Shelter/delete';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    apiPostRequest(url, 'deleteShelter', null, success);
};

export const updateShelter = async (id: string, data: any) => {

    const urlSuffix = '/shelter';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: data
    };

    return krax({
        name: 'shelters',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Geçici Bakım Evi Güncellendi',
                position: 'topRight',
                icon: 'fad fa-check',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const setShelter = async (data: any) => {

    const urlSuffix = '/shelter';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: data
    };

    return krax({
        name: 'shelterList',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Geçici Bakım Evi Eklendi',
                position: 'topRight',
                icon: 'fad fa-check',
            })
        },
        onError: (_: any, state: any, error: any) => {

            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};