import React, { memo, useEffect, useState } from 'react';
import { TitleBar, Tooltip } from '../../components'
import { getUsers, updateApprovalStatus, removeUser, changeUserToVolunteer } from "../../_actions/users";
import { connect } from "../../store";
import { IUserListPageDefaultValue, IUserListPageProps, IUserListPageState, IUserFilterDefaultValue, IUserFilterProps, IUserItemProps } from "./types";
import { ILocationListProps, ILocationListDefaultValue } from "./../common/types"
import { useHistory, useLocation } from 'react-router-dom'
import { capitalize } from 'lodash'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Avatar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DataGrid as Grid, Modal, Button, Form, Input, Select } from 'oneframe-react'
import Description from "../../components/Description";
import { getCities, getCountiesOfCity, getNeighbourhoodsOfCounty } from '../../_actions/common';
import DownloadExcel from './../../components/ExcelExport/exceldownload'
import generatePDF from '../../components/PdfExport/pdfexport';
import { ExportTypes } from './../../_constants/exportType';
import { ExportNames } from './../../_constants/exportNames';

interface IProps extends IUserListPageProps {
}

interface IUserState extends IUserListPageState {
}

interface IUserFilterState extends IUserFilterProps {
}

interface ILocationListState extends ILocationListProps {

}

const Users = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [state, setState] = useState(IUserListPageDefaultValue);
    const [filterState, setFilterState] = useState<IUserFilterState>(IUserFilterDefaultValue);
    const [locationState, setLocationState] = useState<ILocationListState>(ILocationListDefaultValue);
    const [exportDataState, setExportDataState] = useState<IUserItemProps[] | null>(null);
    const { users: { payload, loading }, usersAllRecord, cities, counties, neighbourhoods } = props;
    const { page, pageSize, userType, approvalStatus, operationStatus, modalShow, operationType, modalWidth } = state;
    const { triggerFilter } = filterState;
    const tabInfo = ['volunteer', 'veterinary', 'member'];
    const operationTypes: any = {
        approve: 'approve',
        unApprove: 'unApprove',
        delete: 'delete',
        changeToVolunteer: 'changeToVolunteer'
    };
    const mainPathInfo: string = '/users';
    const tabSlugs: any = {
        approvedPathInfo: 'approved',
        unApprovedPathInfo: 'unapproved'
    };
    const approveSlugs = [tabSlugs.approvedPathInfo, tabSlugs.unApprovedPathInfo];
    const { pathname } = location;

    const setTabIndex = (slug: any) => {
        if (slug && slug === tabSlugs.unApprovedPathInfo) return approveSlugs.indexOf(slug);
        if (slug && slug === tabSlugs.approvedPathInfo) return approveSlugs.indexOf(slug);
        return 0;
    };

    const getTabContentItem = (userType: string, userTypeApproveSlug: any, request: string) => {
        return tabContent[capitalize(userType)][userTypeApproveSlug ? approveSlugs.indexOf(userTypeApproveSlug) : 0][request];
    };

    useEffect(() => {
        const userTypeSlug = pathname.split('/')[2];
        const userTypeApproveSlug = pathname.split('/')[3];

        setState(prevState => ({
            ...prevState,
            tabIndex: setTabIndex(userTypeApproveSlug),
            userType: capitalize(userTypeSlug),
            tabHelperText: getTabContentItem(userTypeSlug, userTypeApproveSlug, 'helperText'),
            tabText: getTabContentItem(userTypeSlug, operationType === operationTypes.approve ? tabSlugs.unApprovedPathInfo : tabSlugs.approvedPathInfo, 'text'),
            exportTitle: getTabContentItem(userTypeSlug, userTypeApproveSlug, 'exportTitle'),
            exportFileName: getTabContentItem(userTypeSlug, userTypeApproveSlug, 'exportFileName'),
            pageSize: 10,
            approvalStatus: !pathname.includes(tabSlugs.unApprovedPathInfo)
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps

        getCities();
    }, [pathname]);

    useEffect(() => {
        if (userType.length) {
            const userTypeSlug = pathname.split('/')[2];
            getUsers(capitalize(userTypeSlug), pageSize, page, approvalStatus, filterState, 'users')
        }
    }, [page, pageSize, userType, approvalStatus, operationStatus, triggerFilter]);

    useEffect(() => {
        if (payload && payload.items) {
            setState((prevState: IUserState) => ({
                ...prevState,
                userList: payload.items,
                page: payload.pageIndex,
                totalCount: payload.totalCount,
                totalPages: payload.totalPages,
                operationStatus: false,
                approvalStatus: !pathname.includes(tabSlugs.unApprovedPathInfo)
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload]);

    useEffect(() => {
        if (usersAllRecord.payload && usersAllRecord.payload.items) {
            usersAllRecord.payload.items.forEach((item) => {
                item.cityName = item.address != null ? item.address.cityName : '';
                item.countyName = item.address != null ? item.address.countyName : '';
                item.neighbourhoodName = item.address != null ? item.address.neighbourhoodName : '';
                item.isApprovedText = item.isApproved ? "Onaylanmış" : "Onaylanmamış";
                item.isDeletedText = item.isDeleted ? "Pasif" : "Aktif";
            });

            if (state.exportType == ExportTypes.EXCEL) {
                setExportDataState(usersAllRecord.payload.items);
            } else if (state.exportType == ExportTypes.PDF) {
                generatePDF(exportColumns(), usersAllRecord.payload.items, state.exportTitle, state.exportFileName)
            }

            setState((prevState: IUserState) => ({
                ...prevState,
                exportType: ''
            }));
        }
    }, [usersAllRecord.payload]);

    useEffect(() => {
        if (cities.payload && Object.keys(cities.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                cities: cities.payload
            }));
        }
    }, [cities.payload]);

    useEffect(() => {
        if (filterState.cityId != null) {
            getCountiesOfCity(filterState.cityId);
        }
    }, [filterState.cityId]);

    useEffect(() => {
        if (counties.payload && Object.keys(counties.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                counties: counties.payload
            }));
        }
    }, [counties.payload]);

    useEffect(() => {
        if (filterState.countyId != null) {
            getNeighbourhoodsOfCounty(filterState.countyId);
        }
    }, [filterState.countyId]);

    useEffect(() => {
        if (neighbourhoods.payload && Object.keys(neighbourhoods.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                neighbourhoods: neighbourhoods.payload
            }));
        }
    }, [neighbourhoods.payload]);

    const getAllDataOnHandleClick = (exportType: string) => {
        setState((prevState: IUserState) => ({
            ...prevState,
            exportType: exportType
        }));

        // state sıfırlanmazsa ikinci kez indirilemiyor.
        setExportDataState(null);
        // tüm kayıtları çekebilmek için totalCount ve page 0 kullanılıyor.
        const userTypeSlug = pathname.split('/')[2];
        getUsers(capitalize(userTypeSlug), 32000, 0, approvalStatus, filterState, 'usersAllRecord');
        // useEffect usersAllRecord.payload kısmına düşer.
    }

    const resetByOperation = () => {
        setState((prevState: IUserListPageState) => ({
            ...prevState,
            operationStatus: true,
            modalShow: false,
            selectedItem: null,
            operationType: '',
        }));
        return true;
    };

    const handleClick = (rowData: any, operation: string) => {
        if (rowData && (operation === operationTypes.approve || operation === operationTypes.unApprove)) {
            updateApprovalStatus(rowData);
            resetByOperation();        
        } else if (rowData && (operation === operationTypes.changeToVolunteer)) {
            changeUserToVolunteer(rowData.id);
            resetByOperation();   
        }        
        else if (rowData && operation === operationTypes.delete) {
            removeUser(rowData.username);
            resetByOperation();
        }
        return true;
    };

    const filterHandleChange = (e: any) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const filterHandleSubmit = () => {
        // arama sonrasında page 0 a çekilmeli bu filtreyi de tetikler. page 0 ise, else kısmı tetikler.
        if (state.page != 0) {
            setState((prevState) => ({
                ...prevState,
                page: 0
            }));
        }
        else {
            //triggerFilter değiştiğinde use effect getUser a düşer.
            setFilterState((prevState) => ({
                ...prevState,
                triggerFilter: !triggerFilter
            }));
        }
    }

    const resetFilterHandle = () => {
        setFilterState(IUserFilterDefaultValue);
    }

    const tabContent = {
        Volunteer: [
            {
                text: 'Gönüllüler',
                helperText: `onaylı gönüllü var`,
                exportFileName: ExportNames.USERS.VOLUNTEER.APPROVED.FILE_NAME,
                exportTitle: ExportNames.USERS.VOLUNTEER.APPROVED.TITLE,
                onClick: () => {
                    history.push(`${mainPathInfo + '/' + tabInfo[0] + '/' + tabSlugs.approvedPathInfo}`)
                },
                iconLeft: <i className="fad fa-hand-holding-seedling" />,
            },
            {
                text: 'Onay Bekleyen Gönüllüler',
                helperText: `gönüllü onay bekliyor`,
                exportFileName: ExportNames.USERS.VOLUNTEER.UNAPPROVED.FILE_NAME,
                exportTitle: ExportNames.USERS.VOLUNTEER.UNAPPROVED.TITLE,
                onClick: () => {
                    history.push(`${mainPathInfo + '/' + tabInfo[0] + '/' + tabSlugs.unApprovedPathInfo}`);
                },
                iconLeft:
                    <i className="fad fa-check-square" />,
            }
        ],
        Veterinary: [
            {
                text: 'Veterinerler',
                helperText: `onaylı veteriner var`,
                exportFileName: ExportNames.USERS.VETERINARY.APPROVED.FILE_NAME,
                exportTitle: ExportNames.USERS.VETERINARY.APPROVED.TITLE,
                onClick: () => {
                    history.push(`${mainPathInfo + '/' + tabInfo[1] + '/' + tabSlugs.approvedPathInfo}`)
                },
                iconLeft:
                    <i className="fad fa-user-md" />,
            },
            {
                text: 'Onay Bekleyen Veterinerler',
                helperText: `veteriner onay bekliyor`,
                exportFileName: ExportNames.USERS.VETERINARY.UNAPPROVED.FILE_NAME,
                exportTitle: ExportNames.USERS.VETERINARY.UNAPPROVED.TITLE,
                onClick: () => {
                    history.push(`${mainPathInfo + '/' + tabInfo[1] + '/' + tabSlugs.unApprovedPathInfo}`);
                },
                iconLeft:
                    <i className="fad fa-check-square" />,
            }
        ],
        Member: [
            {
                text: 'Hayvanseverler',
                helperText: 'hayvansever var',
                exportFileName: ExportNames.USERS.MEMBER.FILE_NAME,
                exportTitle: ExportNames.USERS.MEMBER.TITLE,
                onClick:
                    () => {
                        history.push(`${mainPathInfo + '/' + tabInfo[2]}`);
                    },
                iconLeft:
                    <i className="fad fa-hand-holding-heart" />,
            }
        ]
    };

    const exportColumns = () => {
        var defaultExportColumns = [
            { label: "Adı Soyadı", value: "fullName" },
            { label: "E-Posta", value: "email" },
            { label: "Kullanıcı Adı", value: "username" },
            { label: "Telefon Numarası", value: "phoneNumber" },
            { label: "İl", value: "cityName" },
            { label: "İlçe", value: "countyName" },
            { label: "Mahalle", value: "neighbourhoodName" },
            { label: "Aktif/Pasif", value: "isDeletedText" },
        ];

        if (state.userType != "Member") {
            defaultExportColumns.push({ label: "Onay Durumu", value: "isApprovedText" });
        }

        return defaultExportColumns;
    }

    return (
        <div>
            <TitleBar
                title={'Üyeler'}
                loading={loading}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Üyeler',
                        link: mainPathInfo
                    }
                ]}
                tabActive={state.tabIndex}
                tabs={tabContent[state.userType]}
            />
            <div className="wrapper">
                <div className="mb-3">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filtreleme Kriterleri</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Form onSubmit={() => filterHandleSubmit()}>
                                <MUIGrid container spacing={3}>
                                    <MUIGrid item xs={4}>
                                        <Input
                                            id="fullName"
                                            name="fullName"
                                            caption="Adı Soyadı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.fullName}
                                        />

                                    </MUIGrid>
                                    <MUIGrid item xs={4}>
                                        <Input
                                            id="username"
                                            name="username"
                                            caption="Kullanıcı Adı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.username}
                                        />

                                    </MUIGrid>
                                    <MUIGrid item xs={4}>
                                        <Input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            caption="Telefon Numarası"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.phoneNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="cityId"
                                            name="cityId"
                                            caption={'İl'}
                                            options={{
                                                data: locationState.cities,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.cityId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="countyId"
                                            name="countyId"
                                            caption={'İlçe'}
                                            options={{
                                                data: locationState.counties,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.countyId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="neighbourhoodId"
                                            name="neighbourhoodId"
                                            caption={'Mahalle'}
                                            options={{
                                                data: locationState.neighbourhoods,
                                                displayField: 'name',
                                                displayValue: 'id',
                                                selected: filterState!.neighbourhoodId
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="isDeleted"
                                            name="isDeleted"
                                            caption={'Aktif/Pasif'}
                                            options={{
                                                data: [{ value: "false", name: "Aktif" }, { value: "true", name: "Pasif" }],
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected: filterState!.isDeleted
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={12}>
                                        <MUIGrid container spacing={0} direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="reset"
                                                    text="Temizle"
                                                    className='custom-blue-button'
                                                    onClick={resetFilterHandle}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="submit"
                                                    text="Ara"
                                                    className='custom-green-button'
                                                />
                                            </MUIGrid>
                                        </MUIGrid>
                                    </MUIGrid>
                                </MUIGrid>
                            </Form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="export-row mb-3 text-right">
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.PDF)}>
                        <Button type="submit" text="PDF" className='custom-orange-button' iconLeft={() => <i className="fas fa-file-pdf mr-2" />}></Button>
                    </Form>
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.EXCEL)}>
                        <Button type="submit" text="Excel" className='custom-green-button ml-3' iconLeft={() => <i className="fas fa-file-excel mr-2" />}></Button>
                        {exportDataState != null && exportDataState.length > 0 ?
                            <DownloadExcel
                                fileName={state.exportFileName}
                                buttonText="Excel İndir"
                                data={exportDataState}
                                sheetName= {state.exportTitle}
                                columns = {exportColumns()}
                            />
                            :
                            null
                        }
                    </Form>
                </div>
                {!loading ?
                    (state.userList.length ?
                        <Grid
                            key={state.tabIndex}
                            data={state.userList}
                            page={state.page}
                            rowsPerPage={state.pageSize}
                            count={state.totalCount}
                            totalPages={state.totalPages}
                            serverSide={pageSize !== state.totalCount}
                            loadingState={loading}
                            onChangeRowsPerPage={(a: any) => {
                                setState(prevState => ({
                                    ...prevState,
                                    pageSize: a,
                                    page: 0
                                }))
                            }}
                            onChangePage={(a: any) => {
                                setState(prevState => ({
                                    ...prevState,
                                    page: a
                                }))
                            }}
                            header={{
                                print: false,
                                download: false,
                                search: false,
                                filter: false,
                                viewColumns: false
                            }}
                            helperInfoText={state.tabHelperText}
                            sort={false}
                            labels={{
                                noData: 'Hiç Data Yok',
                                headerTooltip: 'Sırala',
                                paginationNext: 'Sonraki Sayfa',
                                paginationPrev: 'Önceki Sayfa',
                                paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                seperator: '/'
                            }}
                            hiddenColumns={userType !== Object.keys(tabContent)[2] ? ['id', 'email'] : ['id', 'isApproved', 'email']}
                            column={[
                                {
                                    label: ' ',
                                    displayField: 'fullName',
                                    customRender: (val: any) => {
                                        let letter: any = val.split(' ');
                                        let characters: string = capitalize(letter[0][0]);
                                        characters += typeof letter[1] !== 'undefined' ? capitalize(letter[1][0]) : ' ';
                                        return (
                                            <>
                                                <div className="rounded-image">
                                                    <Avatar>{characters}</Avatar>
                                                </div>
                                            </>
                                        );
                                    }
                                },
                                {
                                    label: 'Adı Soyadı',
                                    displayField: 'fullName'
                                },
                                {
                                    label: 'E-Posta',
                                    displayField: 'email',
                                },
                                {
                                    label: 'Kullanıcı Adı',
                                    displayField: 'username'
                                },
                                {
                                    label: 'Telefon Numarası',
                                    displayField: 'phoneNumber',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <>
                                                {value}
                                            </>
                                        )
                                    }
                                },
                                {
                                    label: 'İl',
                                    displayField: '',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <>
                                                {allValue.address && allValue.address.cityName}
                                            </>
                                        )
                                    }
                                },
                                {
                                    label: 'İlçe',
                                    displayField: '',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <>
                                                {allValue.address && allValue.address.countyName}
                                            </>
                                        )
                                    }
                                },
                                {
                                    label: 'Mahalle',
                                    displayField: '',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <>
                                                {allValue.address && allValue.address.neighbourhoodName}
                                            </>
                                        )
                                    }
                                },
                                {
                                    label: 'Aktif/Pasif',
                                    displayField: 'isDeleted',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <div className={value ? 'txt-red' : 'txt-green'}>
                                                {value ? <span>Pasif</span> : <span>Aktif</span>}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: 'Onay Durumu',
                                    displayField: 'isApproved',
                                    customRender: (value: any, allValue: any) => {
                                        return (
                                            <div className={value ? 'txt-green' : 'txt-blue'}>
                                                {value ? <span>Onaylanmış</span> : <span>Onay Bekliyor</span>}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: 'İşlemler',
                                    displayField: '',
                                    customRender: (value: any, rowData: any) => {
                                        return (
                                            <>
                                                <div className="table-icon">
                                                    {
                                                        !approvalStatus ?
                                                            <>
                                                                <Tooltip title="Onayla" placement="bottom">
                                                                    <IconButton className="confirm-icon bg-green" onClick={() => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            modalShow: true,
                                                                            selectedItem: rowData,
                                                                            operationType: operationTypes.approve,
                                                                            modalWidth: 'md'
                                                                        }))
                                                                    }}>
                                                                        <i className="fas fa-user-check fa-1x" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                {userType !== Object.keys(tabContent)[2] ?
                                                                    <Tooltip title="Onayı Kaldır" placement="bottom">
                                                                        <IconButton className="approve-icon bg-blue" onClick={() => {
                                                                            setState(prevState => ({
                                                                                ...prevState,
                                                                                modalShow: true,
                                                                                selectedItem: rowData,
                                                                                operationType: operationTypes.unApprove,
                                                                                modalWidth: 'md'
                                                                            }))
                                                                        }}>
                                                                            <i className="fas fa-user-slash fa-1x" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    : null
                                                                }
                                                            </>
                                                    }
                                                    {
                                                        userType === 'Member' ?                                                        
                                                        <Tooltip title="Gönüllü kullanıcıya çevir" placement="bottom">
                                                            <IconButton className="confirm-icon bg-green" onClick={() => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    modalShow: true,
                                                                    selectedItem: rowData,
                                                                    operationType: operationTypes.changeToVolunteer,
                                                                    modalWidth: 'md'
                                                                }))
                                                            }}>
                                                                <i className="fas fa-user-check fa-1x" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        !rowData.isDeleted ? 
                                                        <>
                                                            <Tooltip title="Sil" placement="bottom">
                                                                <IconButton
                                                                    className="remove-icon bg-red ml-2"
                                                                    onClick={() => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            modalShow: true,
                                                                            selectedItem: rowData,
                                                                            operationType: operationTypes.delete,
                                                                            modalWidth: 'xs'
                                                                        }))
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash fa-1x" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                }
                            ]}
                        />
                        : <Description text={state.userType == "Member" ? `${state.tabText} listesinde herhangi bir kayıt yok` : approvalStatus ? `Onaylanmış ${state.tabText} listesinde herhangi bir kayıt yok` : `Onay Bekleyen ${state.tabText} listesinde herhangi bir kayıt yok`} />
                    ) : null
                }

                <Modal
                    title={
                        <div className="p-3">
                            <span>
                                {operationType === operationTypes.approve ? <i className="fad fa-user-check mr-2" /> : null}
                                {operationType === operationTypes.approve ? 'Üye Onaylama İşlemi' : null}
                                {operationType === operationTypes.unApprove ? <i className="fad fa-user-slash mr-2" /> : null}
                                {operationType === operationTypes.unApprove ? 'Üye Onay Kaldırma İşlemi' : null}
                                {operationType === operationTypes.delete ? <i className="fad fa-user mr-2" /> : null}
                                {operationType === operationTypes.delete ? 'Üye Pasife Alma İşlemi' : null}
                                {operationType === operationTypes.changeToVolunteer ? 'Üyeyi Gönüllüye Çevirme İşlemi': null}
                            </span>
                        </div>
                    }
                    show={modalShow}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={modalWidth}
                    footer={
                        <div className="p-3">
                            <Button
                                text={
                                    operationType === operationTypes.delete ? "Pasife Al"
                                        : (operationType === operationTypes.unApprove ? 'Onayı Kaldır'
                                            : (operationType === operationTypes.approve ? 'Onayla'
                                                : (operationType === operationTypes.changeToVolunteer ? 'Çevir': '')))
                                }
                                className={`${operationType === operationTypes.approve ? 'custom-green-button' : 'custom-red-button'} h-75 mr-2`}
                                iconLeft={() => {
                                    return (
                                        <>
                                            {operationType === operationTypes.approve || operationType === operationTypes.changeToVolunteer ? <i className="fad fa-check-circle mr-2" /> : null}
                                            {operationType === operationTypes.unApprove ? <i className="fad fa-times-circle mr-2" /> : null}
                                            {operationType === operationTypes.delete ? <i className="fad fa-trash mr-2" /> : null}
                                        </>
                                    )
                                }}
                                onClick={() => handleClick(state.selectedItem, operationType)}
                            />
                            <Button
                                text="İptal"
                                className="custom-blue-button h-75"
                                iconLeft={() => <i className="fad fa-times mr-2" />}
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        modalShow: false,
                                        selectedItem: null,
                                        operationType: '',
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span>
                        {operationType === operationTypes.approve ? 'Üyeyi onaylamak istediğine emin misin?' : null}
                        {operationType === operationTypes.unApprove ? 'Üyenin onayını kaldırmak istediğine emin misin?' : null}
                        {operationType === operationTypes.delete ? 'Kullanıcı adı ' + state.selectedItem.username + ' olan üye kaydı pasif hale getirilecektir. İşlem geri alınamaz. Pasifleştirme işlemini onaylıyor musunuz?' : null}
                        {operationType === operationTypes.changeToVolunteer ? 'Kullanıcı tipi Gönüllü olarak güncellenecektir. Devam etmek istiyor musunuz?' : null}
                    </span>
                    {operationType !== operationTypes.delete ?
                        <p className="mt-3">
                            <i className="fad fa-info-circle mr-2" />
                            {operationType === operationTypes.approve ?
                                <>
                                    <strong>Not :</strong> Üye, onaylandığında <strong>'{state.tabText} Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                            {operationType === operationTypes.unApprove ?
                                <>
                                    <strong>Not :</strong> Üye, onayı kaldırıldığında <strong>'Onay Bekleyen {state.tabText} Listesi'</strong>ne eklenecektir.
                                </>
                                : null
                            }
                        </p>
                        : null
                    }
                </Modal>
            </div>
        </div>
    )
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ConnectComponent = connect((store: any) => ({ users: store.users, usersAllRecord: store.usersAllRecord, cities: store.cities, counties: store.counties, neighbourhoods: store.neighbourhoods }))(Users) as any;
export default ConnectComponent
