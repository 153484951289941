import React, {memo, useEffect, useState} from 'react';
import {TitleBar} from '../../components'
import { getShelterById, updateShelter, setShelter } from "../../_actions/shelters";
import { ILocationListProps, ILocationListDefaultValue } from "./../common/types"
import {connect} from "../../store";
import {IShelterFormPageDefaultValue, IShelterFormPageProps, IShelterFormPageState} from "./types";
import {useHistory, useParams, useLocation} from 'react-router-dom'
import { getCities, getCountiesOfCity } from '../../_actions/common';
import {Paper} from '@material-ui/core';
import {Button, Form, Input, Select} from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'
// import { getLookupsWithSuccess } from '../../_actions/common';
// import { LookupConstants } from './../../_constants/lookshelter';
// import { filter, join } from 'lodash';

interface IProps extends IShelterFormPageProps {
}

interface IFormState extends IShelterFormPageState {
}

interface ILocationListState extends ILocationListProps {

}

const Shelter = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const parameter = useParams();    
    const paramID: string | null = parameter && parameter['id'] && parameter['id'].length ? parameter['id'] : null;

    const [state, setState] = useState<IFormState>(IShelterFormPageDefaultValue);
    const [locationState, setLocationState] = useState<ILocationListState>(ILocationListDefaultValue);    
    const { shelterItem, cities, counties } = props;
   
    useEffect(() => {
        getCities();        
        if (paramID != null) {
            getShelterById(paramID, function(_ : any, response:any) {
                if (response.payload) {
                setState((prevState: IFormState) => ({
                                     ...prevState,
                                     id: response.payload.id,
                                     insertedDate: response.payload.insertedDate,
                                     insertedUser: response.payload.insertedUser,
                                     name: response.payload.name,
                                     cityId: response.payload.cityId,
                                     countyId: response.payload.countyId,
                                     phoneNumber: response.payload.phoneNumber
                                 }));
                }
            });
        }
    }, [paramID]);

    useEffect(() => {
        if (cities.payload && Object.keys(cities.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                cities: cities.payload
            }));
        }
    }, [cities.payload]);

    useEffect(() => {
        if (state.cityId != null) {
            getCountiesOfCity(state.cityId);
        }
    }, [state.cityId]);

    useEffect(() => {
        if (counties.payload && Object.keys(counties.payload).length > 0) {
            setLocationState((prevState: any) => ({
                ...prevState,
                counties: counties.payload
            }));
        }
    }, [counties.payload]);

    const handleSubmit = async () => {
        let data: any = {
            name: state.name,
            cityId: state.cityId,
            countyId: state.countyId,
            phoneNumber: state.phoneNumber,
            id: state.id
        };

        let x: any = '';

        if (paramID !== null) {
            x = await updateShelter(paramID, data);
        } else {
            x = await setShelter(data);
        }

        x.statusCode === 200 && history.replace('/shelters/list', location.state != null ? {listState: location.state.listState} : null);
    };

    const handleChange = (e: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    
    const renderFormInfo = () => {
        return (
            paramID !== null && !shelterItem.loading ?
                <>
                    <div className="ml-auto info-area">
                        <em><strong>Ekleyen Kişi : </strong><span>{state.insertedUser}</span></em>
                        <em><strong>Eklenme Tarihi : </strong><span>{state.insertedDate && moment(state.insertedDate).format('LL')}</span></em>
                        {(state.updatedUser && state.updatedUser.length) || (state.updatedDate && state.updatedDate.length) ?
                            <>
                                <em><strong>Son Güncelleyen Kişi : </strong><span>{state.updatedUser}</span></em>
                                <em><strong>Son Güncellenme Tarihi : </strong><span>{state.updatedDate && moment(state.updatedDate).format('LL')}</span></em>
                            </>
                            :
                            null
                        }
                    </div>
                </> : null
        );
    };

    const renderForm = () => {
        return (
            !shelterItem.loading ? 
            <>
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Geçici Bakım Evi Detayı</strong></h6>
                                {renderFormInfo()}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <Form onSubmit={() => handleSubmit()}>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className="form-field">
                                    <Input
                                        id="name"
                                        name="name"
                                        caption={'Ad'}
                                        className="custom-login-field"
                                        value={state!.name || ''}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="cityId"
                                        name="cityId"
                                        caption={'Şehir'}
                                        options={{
                                            data: locationState.cities,
                                            displayField: 'name',
                                            displayValue: 'id',
                                            selected: state!.cityId
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="countyId"
                                        name="countyId"
                                        caption={'İlçe'}
                                        options={{
                                            data: locationState.counties,
                                            displayField: 'name',
                                            displayValue: 'id',
                                            selected: state!.countyId
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-field">
                                    <Input
                                        onChange={handleChange}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        caption={'Telefon Numarası'}
                                        value={state!.phoneNumber || ''}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-2 ml-auto">
                                <div className="form-field">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        text={'Kaydet'}
                                        className="custom-blue-button"
                                        iconRight={() => <i className="fad fa-save ml-2"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Paper>
            </> : null
        );
    };

    return (
        <div>
            <TitleBar
                title={paramID !== null ? 'Geçici Bakım Evi Düzenle' : 'Geçici Bakım Evi Ekle'}
                loading={shelterItem.loading}
                button={[
                    {
                        text: 'Geçici Bakım Evi Listesine Dön',
                        onClick: () => {                             
                            history.replace('/shelters/list', location.state != null ? {listState: location.state.listState} : null);
                        },
                        iconLeft: <i className="fad fa-arrow-left mr-2"/>,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Geçici Bakım Evleri',
                        link: '/shelters'
                    },
                    {
                        text: paramID !== null ? 'Geçici Bakım Evi Düzenle' : 'Geçici Bakım Evi Ekle',
                        link: '/shelters/add'
                    }
                ]}
            />

            <div className="wrapper">
                {renderForm()}
            </div>
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {
        shelterItem: store.shelterItem,
        cities: store.cities,
        counties: store.counties     
    }))(Shelter) as any;
export default ConnectComponent
