import React, { memo, useEffect, useState } from 'react';
import { TitleBar } from '../../components'
import { getIncidentAction, getIncidentById } from "../../_actions/incident";
import { connect } from "../../store";
import { IIncidentFormPageDefaultValue, IIncidentFormPageProps, IIncidentFormPageState, IIncidentActionListPageState, IIncidentActionListPageDefaultValue, } from "./types";
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Paper, Divider } from '@material-ui/core';
import { DataGrid, Form } from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'
import Description from "../../components/Description"
import CardDetailInfo from "../../components/Card/detail-info"
import ImageGallery from "../../components/Image/gallery"

interface IProps extends IIncidentFormPageProps {
}

interface IFormState extends IIncidentFormPageState {
}

interface IIncidentActionState extends IIncidentActionListPageState {
}

const Incident = memo((props: IProps) => {
    // const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const parameter = useParams();
    const paramID: string | null = parameter && parameter['id'] && parameter['id'].length ? parameter['id'] : null;

    const [state, setState] = useState<IFormState>(IIncidentFormPageDefaultValue);
    const [actionState, setActionState] = useState<IIncidentActionState>(IIncidentActionListPageDefaultValue);
    const { incidentItem, incidentActions } = props;
    const { page, pageSize } = actionState;

    useEffect(() => {
        if (paramID != null) {
            getIncidentById(paramID);
        }
    }, [paramID]);

    useEffect(() => {
        if (paramID != null) {
            getIncidentAction(pageSize, page, paramID);
        }
    }, [page, pageSize, paramID]);

    useEffect(() => {
        if (incidentItem.payload && paramID !== null) {
            const { incidentId, insertedDate, incidentType, status, statusText, streetAnimalName, cityName, countyName, neighbourhoodName, activePassive, detail, insertedUser, imageIds, imageBase, cancelComment, cancelInsidentReason } = incidentItem.payload;
            if (incidentId) {
                setState((prevState: IFormState) => ({
                    ...prevState,
                    incidentId: incidentId,
                    insertedDate: insertedDate,
                    incidentType: incidentType,
                    status: status,
                    statusText: statusText,
                    streetAnimalName: streetAnimalName,
                    cityName: cityName,
                    countyName: countyName,
                    neighbourhoodName: neighbourhoodName,
                    activePassive: activePassive,
                    detail: detail,
                    insertedUser: insertedUser,
                    imageIds: imageIds,
                    imageBase: imageBase,
                    cancelComment: cancelComment,
                    cancelInsidentReason: cancelInsidentReason
                }));
            }
        }
    }, [incidentItem.payload]);

    useEffect(() => {
        if (incidentActions.payload && incidentActions.payload.items) {
            setActionState((prevState: IIncidentActionState) => ({
                ...prevState,
                incidentActionList: incidentActions.payload.items,
                page: incidentActions.payload.pageIndex,
                totalCount: incidentActions.payload.totalCount,
                totalPages: incidentActions.payload.totalPages,
                pageSize: incidentActions.payload.pageSize
            }));
        }

    }, [incidentActions.payload]);

    const renderFormInfo = () => {
        return (
            paramID !== null && !incidentItem.loading ?
                <>
                    <div className="ml-auto info-area">
                        <em><strong>Ekleyen Kişi : </strong><span>{state.insertedUser}</span></em>
                        <em><strong>Eklenme Tarihi : </strong><span>{state.insertedDate && moment(state.insertedDate).format('LL')}</span></em>
                    </div>
                </> : null
        );
    };

    const renderImages = () => {
        var imgs: any[] = [];

        if (state.imageBase != null) {
            imgs = state.imageBase;
        }

        return <div><ImageGallery imgList={imgs} /></div>
    }

    const renderForm = () => {
        return (
            <>
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Vaka Detayı</strong></h6>
                                {renderFormInfo()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Form onSubmit={() => null}>
                        <div className="row mb-4">
                            {renderImages()}
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="row mb-4">
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Vaka Id"
                                            text={state!.incidentId}
                                            iconClass="fas fa-briefcase-medical"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Vaka Tipi"
                                            text={state!.incidentType}
                                            iconClass="fas fa-band-aid"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Durumu"
                                            text={state!.statusText}
                                            iconClass="fas fa-info-circle"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="İl"
                                            text={state!.cityName}
                                            iconClass="fas fa-map"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="İlçe"
                                            text={state!.countyName}
                                            iconClass="fas fa-map-marked-alt"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Mahalle"
                                            text={state!.neighbourhoodName}
                                            iconClass="fas fa-map-signs"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Semt Sakini Adı"
                                            text={state!.streetAnimalName}
                                            iconClass="fas fa-paw"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Aktif/Pasif"
                                            text={state!.activePassive}
                                            iconClass="fas fa-toggle-on"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="Açıklama"
                                            text={state!.detail}
                                            iconClass="fas fa-clipboard-list"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                </div>
                                {
                                    state!.status == "Cancel" ? <div className="row mb-4">
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="İptal Nedeni"
                                            text={state!.cancelInsidentReason}
                                            iconClass="fas fa-times-circle"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                    <div className="col-4">
                                        <CardDetailInfo
                                            title="İptal Açıklaması"
                                            text={state!.cancelComment}
                                            iconClass="fas fa-calendar-minus"
                                        />
                                        <Divider variant="middle" />
                                    </div>
                                </div> : null
                                }
                            </div>
                        </div>
                    </Form>
                </Paper>
            </>
        );
    };

    const renderGrid = () => {
        return (
            <>
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Vaka Aksiyonları</strong></h6>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row mb-4">
                        <div className="col">
                            {!incidentActions.loading ? (
                                actionState.incidentActionList.length ?
                                    <DataGrid
                                        data={actionState.incidentActionList}
                                        page={actionState.page}
                                        rowsPerPage={actionState.pageSize}
                                        count={actionState.totalCount}
                                        totalPages={actionState.totalPages}
                                        serverSide={true}
                                        loadingState={incidentActions.loading}
                                        onChangeRowsPerPage={(a: any) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                pageSize: a,
                                                page: 0
                                            }))
                                        }}
                                        onChangePage={(a: any) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                page: a
                                            }))
                                        }}
                                        header={{
                                            print: false,
                                            download: false,
                                            search: false,
                                            filter: false,
                                            viewColumns: false,
                                        }}
                                        helperInfoText={'aksiyon var'}
                                        sort={false}
                                        labels={{
                                            noData: 'Hiç Data Yok',
                                            headerTooltip: 'Sırala',
                                            paginationNext: 'Sonraki Sayfa',
                                            paginationPrev: 'Önceki Sayfa',
                                            paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                            seperator: '/'
                                        }}
                                        hiddenColumns={['id']}
                                        column={[
                                            {
                                                label: 'Kayıt Tarihi',
                                                displayField: 'date',
                                                customRender: (a) => {
                                                    return a && moment(a).format('LLL')
                                                }
                                            },
                                            {
                                                label: 'Kayıt Eden Kullanıcı',
                                                displayField: 'insertedUser'
                                            },
                                            {
                                                label: 'Detay',
                                                displayField: 'detail'
                                            }
                                        ]}
                                    />
                                    : <Description text="Herhangi bir aksiyon yok" />
                            ) : null
                            }
                        </div>
                    </div>
                </Paper>
            </>
        );
    };

    return (
        <div>
            <TitleBar
                title="Vaka Detayı"
                loading={incidentItem.loading}
                button={[
                    {
                        text: 'Vaka Listesine Dön',
                        onClick: () => {
                            history.replace('/incidence/list', location.state != null ? { listState: location.state.listState } : null);
                        },
                        iconLeft: <i className="fad fa-arrow-left mr-2" />,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Vaka Listesi',
                        link: '/incidence'
                    },
                    {
                        text: "Vaka",
                        link: '/'
                    }
                ]}
            />
            {!incidentItem.loading && !incidentActions.loading ?
                <div className="wrapper">
                    {renderForm()}
                    {renderGrid()}
                </div> : null
            }
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {
        incidentItem: store.incidentItem,
        incidentActions: store.incidentActions
    }))(Incident) as any;
export default ConnectComponent