import React, {useState} from 'react';
import styled from "styled-components";
import {isEqual, set} from 'lodash'
import {connect} from '../../store'
import {Button, Form, Input} from 'oneframe-react'
import {PasswordStateType, PasswordPropsType, LoginStateDefaultValue} from "./type";
import {forgotPassword} from "../../_actions/auth";
import Sidebar from "./Sidebar";

type IState = PasswordStateType

const Login = (props: PasswordPropsType) => {

    const [state, setState] = useState<IState>(LoginStateDefaultValue) as any;

    return (
        <div className="login-container">
            <Sidebar>
                <div className="text-2">
                    Üyeliğin var mı?
                    <Button
                        type="button"
                        onClick={() => props.history.push('/auth/login')}
                        className="custom-blue-button outline ml-3 p-3"
                        text={'Giriş yap'}
                    />

                </div>
            </Sidebar>
            <div className="login-rightside-form">
                <div className="form-area">
                    <h3>
                        Şifre Hatırlat
                    </h3>
                    <p className="mt-4">
                        E-Posta adresinize yeni şifre maili yollayalım.
                    </p>
                    <div className="form">
                        <Form onSubmit={() => forgotPassword(state)}>
                            <div className="form-field">
                                <Input
                                    id="email"
                                    name="email"
                                    caption="E-Posta"
                                    className="custom-login-field"
                                    value={state!.email || ''}
                                    onChange={(e: any) => setState({...set(state, e.target.name, e.target.value)})}
                                    validate={[
                                        {
                                            required: 'required',
                                            message: 'Bu alan boş olamaz'
                                        },
                                        {
                                            required: 'email',
                                            message: 'Geçerli bir e-posta adresi girin'
                                        }
                                    ]}
                                />
                            </div>
                            <div className="form-field">
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="custom-orange-button p-3"
                                    text={'Gönder'}
                                    iconRight={() => <i className="fad fa-share ml-2"/>}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
};

const LoginStyled = styled(Login)``;
const LoginComponent = React.memo(LoginStyled, (p: any, n: any) => isEqual(p, n));
export default connect((state: any) => (state))(LoginComponent);
