export type ChangePasswordStateType = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

export const ChangePasswordStateDefaultValue: ChangePasswordStateType = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
};

