import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {IShelterAnimalFilterProps} from "./../pages/ShelterAnimals/types";
import {krax} from "../store";
import { apiGetRequest, apiPostRequest } from "./common";

import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

interface IShelterAnimalFilterState extends IShelterAnimalFilterProps{
}

export const getShelterAnimals = async (PageSize: number = 50, PageIndex: number = 0, filterState: IShelterAnimalFilterState, kraxName= 'shelterAnimals') => {    
    const urlSuffix = '/shelteranimals/List';
    const url:string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            PageSize: PageSize,
            PageIndex: PageIndex,            
            Breed: filterState.breed,            
            Gender: filterState.gender,
            Age: filterState.age,
            Name: filterState.name,
            Weight: filterState.weight,
            ShelterName: filterState.shelterName,
            Kind: filterState.kind,
            MicrochipNumber: filterState.microchipNumber,
            IsAdminPanel: true,
            IsDeleted: filterState.isDeleted,
            IsActive: filterState.isActive,
            IsAlive: filterState.isAlive,
            Status: filterState.status,
        }
    };
    
    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const getShelterAnimalById = async (id: string, success : any) => {
    const urlSuffix = '/shelteranimals/detail';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    apiGetRequest(url, 'shelterAnimalItem', success);
};

export const getShelterList = async(success:any) => {
    const urlSuffix = '/shelter/getList';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiGetRequest(url, 'shelterList', success);
}

export const updateShelterAnimal = async (data:any, success:any, error: any) => {
    const urlSuffix = '/shelteranimals/update';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequest(url, 'updateShelterAnimal', data, success, error);
}


export const deleteShelterAnimal = async (id: string, success:any) => {
    const urlSuffix = '/shelterAnimals/delete';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    apiPostRequest(url, 'deleteShelterAnimal', null, success);
}

export const deleteShelterAnimalImage = async (data:any, success:any) => {
    const urlSuffix = '/shelteranimals/DeleteShelterAnimalImage';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequest(url, 'deleteShelterAnimalImage', data, success); 
}


// export const addShelterAnimal = async (data:any, success:any) => {
//     const urlSuffix = '/shelteranimals/add';
//     const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

//     var response :any = await fetch(url,
//          {
//              method: 'POST',
//              mode: 'cors',
//              headers: buildRequestHeader(),
//              body:  data,
//          }
//     ).catch((error) => {
//         toastMessage({
//             messageType: 'error',
//             title: 'Hata oluştu',
//             message: 'ERR_CONNECTION_REFUSED',
//             position: 'topRight',
//             overlay: true,
//             icon: 'fad fa-exclamation-square',
//             target: '.error-container',
//         });
//        console.log(error)
//      });

//     if(response.status == 200) {        
//         success();
//     }
// }

export const addShelterAnimal = async (data:any, success:any, onError) => {    
    const urlSuffix = '/shelteranimals/add';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequest(url, 'addShelterAnimal', data, success, onError);
}
