import React from 'react';
//import kvkkFile from '/ilgili_kisi_basvuru_formu.docx';

const kvkk = () => {
    return(
        <div className="survey-container">
        {/* <div className="kocsistem-logo">
            <img src="/kocsistemLogo.png" alt=""/>
        </div> */}
        <div className="survey-form">
                    <div className="form-area">
                    {/* <div className="logo-area">
                        <img src="/semtpati_logo.png" alt=""/>
                    </div> */}
                    
                <h6 className='centered-title'>İSTANBUL BÜYÜKŞEHİR BELEDİYE BAŞKANLIĞI SEMTPATİ UYGULAMASI KAPSAMINDA KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</h6>
                <br/>
                <b>Veri Sorumlusu: </b>İstanbul Büyükşehir Belediye Başkanlığı İstanbul Büyükşehir Belediye Başkanlığı Kemalpaşa Mh. 15  Temmuz Şehitleri Cd. No:5 34134 Fatih / İstanbul
                <br/>
                <br/>
                <p>
                İstanbul Büyükşehir Belediye Başkanlığı (“İBB”) olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ve ilgili mevzuat ve yasal düzenlemeler doğrultusunda; sunduğumuz hizmetlerden sizleri faydalandırmak adına siz vatandaşlarımızın kişisel verilerinin işlenmesi, saklanması ve aktarılmasına ilişkin olarak işbu aydınlatma metni hazırlanmıştır.
                </p>
                <br/>
                <br/>
                <b>Hangi Kişisel Verileriniz İşlenmekte ve Kişisel Verilerinizin İşlenme Amaçları, Hukuki Sebepleri ve Toplama Yöntemleri Nelerdir?</b>
                <br/>
                <br/>
                <p>SemtPati uygulaması aracılığıyla yürüttüğümüz faaliyetler ve iş süreçleri kapsamında siz vatandaşlarımızın, kimlik verileri (ad, soyad) ve iletişim verileri (cep telefonu numarası, e-posta adresi, ikamet edilen semt bilgileri) üyelik süreci kapsamında elektronik formlar aracılığıyla; mobil cihazınız üzerinden izin vermeniz durumunda cihazınız aracılığıyla, uygulamaya giriş sağlamanız ve uygulama üzerindeki işlemleriniz kapsamında işlem güvenliği verileri (IP adresi bilgileri, şifre bilgileri),  lokasyon verileri (konum bilgileri) mobil cihazınız aracılığıyla elde edilmekte ve işlenmektedir.</p>
                <br/>
                <br/>
                <p>Söz konusu kişisel verileriniz iletişim faaliyetlerinin yürütülmesi, vatandaş ilişkileri yönetimi süreçlerinin yürütülmesi, vatandaşların memnuniyetine yönelik aktivitelerin yürütülmesi, organizasyon ve etkinlik yönetimi, sosyal sorumluluk ve sivil toplum aktivitelerinin yürütülmesi, hizmete ilişkin iş ve destek süreçlerinin yürütülmesi, hizmet operasyon süreçlerinin yürütülmesi, hizmetlerin geliştirilmesine yönelik faaliyetlerin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi, bilgi güvenliği süreçlerinin yürütülmesi, iş faaliyetlerinin yürütülmesi/denetimi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amacıyla işlenmektedir. </p>
                <br/>
                <br/>
                <p>Süreç özelinde işlenen kimlik, iletişim ve lokasyon verileriniz, KVKK’nın 5. maddesinin 2. fıkrasının (c) bendi ‘‘Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması’’, (ç) bendi “Veri sorumlusunun hukuki yükümlülüklerini yerine getirmesi için zorunlu olması” ve (f) bendi ‘‘İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebeplerine dayanılarak işlenecektir.</p>
                <br/>
                <br/>
                <p>İşlem güvenliği verileriniz, KVKK’nın 5. maddesinin 2. fıkrasının (a) bendi “Kanunlarda açıkça öngörülmesi’’ ve (f) bendi ‘‘İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebeplerine dayanılarak işlenecektir.</p>
                <br/>
                <br/>
                <b>Kişisel Verileriniz Kimlere ve Hangi Amaçlarla Aktarılabilecektir?</b>
                <br/><br/>
                <p>Kişisel verileriniz;</p>
                <ul className='ulPoint'>
                    <li>Yasal düzenlemeler ve tabi olduğumuz mevzuat uyarınca, bir mahkeme kararı veya yetkili kılınmış idari mercilerin talebi üzerine faaliyetlerin mevzuata uygun yürütülmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla <b>Yetkili Kamu Kurum ve Kuruluşlarına,</b></li>
                    <li>Sosyal sorumluluk ve sivil toplum aktivitelerinin yürütülmesi, hizmete ilişkin iş ve destek süreçlerinin yürütülmesi, organizasyon ve etkinlik yönetimi amaçlarıyla ve İBB ile gerçekleştirilen iş birlikleri kapsamında <b>İstanbul Gönüllüleri’ne,</b></li>
                    <li>Hizmete ilişkin iş ve destek süreçlerinin yürütülmesi, hizmet operasyon süreçlerinin yürütülmesi, hizmetlerin geliştirilmesine yönelik faaliyetlerin yürütülmesi, bilgi güvenliği süreçlerinin yürütülmesi amaçlarıyla <b>İş Ortakları’na,</b></li>
                </ul>
                <p>aktarılabilecektir.</p>
                <br/><br/>
                <b>İlgili Kişi Olarak Haklarınız Nelerdir?</b>
                <br/><br/>
                <p>KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin olarak;</p>
            <ul className='ulPoint'>
                <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,</li>
                <li>KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,</li>
                <li>Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep ettiğinizde, bu durumun kişisel verilerinizi aktardığımız üçüncü kişilere bildirilmesini isteme,</li>
                <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve</li>
                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep etme</li>
            </ul>
            <p>haklarına sahipsiniz. </p>
            <br/> <br/>
            <b>Haklarınızı Nasıl Kullanabilirsiniz?</b>
            <br/> <br/>
            <p>Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz <a href="/ilgili_kisi_basvuru_formu.docx" download="İlgili Kişi Başvuru Formu.docx">
                    İlgili Kişi Başvuru Formu
                </a>  aracılığıyla; </p>
            <ul className='ulPoint'>
                <li>Islak imzalı ve kimliğinizi tevsik edici belgeler ile İstanbul Büyükşehir Belediye Başkanlığı Kemalpaşa Mh. 15 Temmuz Şehitleri Cd. No:5 34134 Fatih / İstanbul adresine göndererek,</li>
                <li>Geçerli bir kimlik belgesi ile birlikte İstanbul Büyükşehir Belediye Başkanlığı’na bizzat başvurarak, </li>
                <li>Mobil imza veya güvenli elektronik imza ile imzalayıp kvkk_bildirim@ibb.gov.tr adresine e-posta gönderilerek,</li>
                <li>Kayıtlı elektronik posta (KEP) adresi ve güvenli elektronik imza ya da mobil imza kullanmak suretiyle ibb@hs01.kep.tr kayıtlı elektronik posta adresimize göndererek,</li>
            </ul>
            <p>İstanbul Büyükşehir Belediye Başkanlığı’na iletebilirsiniz.</p>
            <br/><br/>
            <p>Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca, İlgili Kişi’nin, başvurusunda isim, soyisim, başvuru yazılı ise imza, T.C. kimlik numarası, (başvuruda bulunan kişinin yabancı olması halinde uyruğu, pasaport numarası veya varsa kimlik numarası), tebligata esas yerleşim yeri veya iş yeri adresi, varsa bildirime esas elektronik posta adresi, telefon numarası ve faks numarası ile talep konusuna dair bilgilerin bulunması zorunludur.</p>
            <br/><br/>
            <p>İlgili Kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve kullanmayı talep ettiği hakka ilişkin açıklamaları içeren başvuruda talep edilen hususu açık ve anlaşılır şekilde belirtmelidir. Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi gerekmektedir.</p>
            <br/><br/>
            <p>Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle birlikte, başkası adına hareket ediliyor ise başvuruyu yapanın bu konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi (vekâletname) gerekmektedir. Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin eklenmesi gerekmektedir.</p>
            <br/><br/>
            <p>Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler değerlendirmeye alınmayacaktır.</p>
            <br/><br/>
            <b>Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar Sürede Cevaplanır?</b>
            <br/><br/>
            <p>Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek, bize ulaştığı tarihten itibaren en geç 30 gün içerisinde cevaplanır. Başvurunuzun olumsuz değerlendirilmesi halinde gerekçeli ret sebepleri başvuruda belirttiğiniz adrese elektronik posta veya posta yolu başta olmak üzere İlgili Kişi Başvuru Formu’nda seçilen usullerinden biri ile gönderilir.</p>
            <br/> <br/>
            </div>
            </div>
        </div>
    )
}


export default kvkk