import {/*buildRequestHeader,*/ getAuthApiBaseUrl/*, handleErrorType*/} from "../_helpers";
import {IAdoptionApplicationFilterProps} from "./../pages/AdoptionApplications/types";
// import {krax} from "../store";
import { apiPostRequest } from "./common";

// import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

interface IAdoptionApplicationFilterState extends IAdoptionApplicationFilterProps{
}

export const getAdoptionApplications = async(PageSize: number = 50, PageIndex: number = 0, filterState: IAdoptionApplicationFilterState, kraxName= 'adoptionApplications') => {
    const urlSuffix = '/shelteranimals/adoption-application-list';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const data = {
        PageSize: PageSize,
        PageIndex: PageIndex,
        ShelterAnimalName: filterState.shelterAnimalName,
        ShelterAnimalKind: filterState.shelterAnimalKind,
        ShelterAnimalGender: filterState.shelterAnimalGender,
        ShelterAnimalAge: filterState.shelterAnimalAge,
        ShelterAnimalMicrochipNumber: filterState.shelterAnimalMicrochipNumber,
        ShelterAnimalShelterName: filterState.shelterAnimalShelterName,
        AssignedUserUserName: filterState.assignedUserUserName,
        AssignedUserFullName: filterState.assignedUserFullName,
        AssignedUserPhoneNumber: filterState.assignedUserPhoneNumber,
        SurveyAnswerFullName: filterState.surveyAnswerFullName,
        SurveyAnswerPhoneNumber: filterState.surveyAnswerPhoneNumber,
        SurveyAnswerEmailAddress: filterState.surveyAnswerEmailAddress,
        AssignedDate: filterState.assignedDate,
    }

    apiPostRequest(url, kraxName, data, (_: any, state: any) => {});
}

export const getAdoptionApplicationAnswersExport = async (data:any, success:any, onError) => {
    const urlSuffix = '/shelteranimals/adoption-application-list/export';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequest(url, 'adoptionApplicationAnswers', data, success, onError);
}