import {toastMessage} from "oneframe-react";
import {sessionStorageConstants} from "../_constants";
import moment from 'moment';
import {MenuConstants} from './../_constants/menu';
import { getAllLookups } from "../_actions/common";

export const isAuthenticated = () => getActiveToken(true) != null;

export const getItem = (key: any) => {
    const item: any = sessionStorage.getItem(key);
    return item !== 'undefined' ? JSON.parse(item) : null;
};

export const setItem = (key: any, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key: any) => {
    sessionStorage.removeItem(key);
};

export const getAuthApiBaseUrl = () => {
    return process.env.REACT_APP_API_URL
};

export const getActiveToken = (checkExpiration: boolean = false) => {
    const token: any = getItem(sessionStorageConstants.ACCESS_TOKEN);
    if (checkExpiration && token && tokenIsExpired(token)) return null;
    return token;
};

export const tokenIsExpired = (token: any) => {
    const jwt: any = parseJwt(token);
    const current_time: any = new Date().getTime() / 1000;
    return current_time > jwt.exp;
};

export const parseJwt = (token: any) => {
    const base64Url: any = token.split('.')[1];
    const base64: any = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export const apiStatus = (statusCode: number) => {
    return statusCode >= 200 && statusCode < 300
};

export function buildRequestHeader() {
    const headers: any = {};

    if (isAuthenticated) {
        headers.Authorization = 'Bearer ' + getActiveToken(true);
    }

    return headers;
}

export const isLocalApi = () => window.location.hostname === 'localhost' && process.env.REACT_APP_USE_LOCAL_API === '1'


export function handleErrorType(errCode: number) {
    if (errCode && errCode === 401) {
        window.location.href = '/login?ref=' + window.location.pathname
    }

    if (errCode && errCode === 403) {
        toastMessage({
            messageType: 'error',
            message: 'Something went wrong',
            position: 'center',
            overlay: true,
            icon: 'fad fa-exclamation-square',
            timeout: 1000000000
        })
    }
}

export function changeObjectStructure(data: any) {
    /*eslint-disable*/
    data.map((item: any) => {
        delete Object.assign(item, {['label']: item['key']})['key'];
    });
    /*eslint-disable*/

    return data;
}

export const authSetInfoForStorage = (payload) => {
    const token: any = parseJwt(payload.token);
    setItem(sessionStorageConstants.ACCESS_TOKEN, payload.token);

    const authEmail: any = payload.claims.filter((item: any) => item.type === 'email');
    const uniqueName: any = payload.claims.filter((item: any) => item.type === 'unique_name');
    const givenName: any = payload.claims.filter((item: any) => item.type === 'given_name');
    const claims: any = payload.claims.filter((item:any) => item.type === 'Menu');

    setItem(sessionStorageConstants.USER_EMAIL, authEmail[0].value);
    setItem(sessionStorageConstants.USER_UNIQUENAME, uniqueName[0].value);
    setItem(sessionStorageConstants.USER_FULLNAME, givenName[0].value);
    setItem(sessionStorageConstants.EXPIRED_TIME, token.exp);
    setItem(sessionStorageConstants.MENU, JSON.stringify(claims));

    saveLookup();
}

export const getClaims = () => {
    const permissions = getItem(sessionStorageConstants.MENU);
    return JSON.parse(permissions);
}

export const hasUserPermission = (claimValue: string) => {    
    var claims = getClaims();
    var claim = claims.filter((item:any) => item.value === claimValue);

    if( claim != null && claim.length > 0 ){
        return true;
    } else {
        return false;
    }
}

export const generateExportFileName = (fileName) => {
    var now = moment().format('DDMMYYYYHHmm');
    return "SemtPati_" + fileName + "_" + now;
}

export const getHomePage = () => {
    var claims = getClaims();

    var claim = claims.filter((item:any) => item.value === MenuConstants.Menu_Dasboard);

    if (claim.length > 0){
        return '/';
    }

    var claim = claims.filter((item:any) => item.value === MenuConstants.Menu_ShelterAnimal);

    if(claim.length > 0){
        return '/shelterAnimals';
    }

    var claim = claims.filter((item:any) => item.value === MenuConstants.Menu_ToInform);

    if(claim.length > 0){
        return '/toinform';
    }

    return '/';
}

export const saveLookup = async () => {
    getAllLookups((_:any, response:any)=> {
        if(response.statusCode === 200) {            
            setItem(sessionStorageConstants.LOOKUPS, JSON.stringify(response.payload));
        }
    });
}

export const getLookups = () => {    
    var lookups = getItem(sessionStorageConstants.LOOKUPS);
    return JSON.parse(lookups);
}