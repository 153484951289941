import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PrivateRoute from './_route/PrivateRoute'
import {Home, Auth, Users, StreetAnimals, ShelterAnimals, Faq, Incidence, Profile, Shelters, ToInform, NoPermission, Survey, Kvkk, Notifications, AdoptionApplications} from "./pages";
import {ScrolltoTop} from "./components"
import {MenuConstants} from "./_constants/menu"

const App = () => {

    return (
        <Router>
            <ScrolltoTop>
                <Switch>
                    <PrivateRoute exact path="/" component={Home}  claimValue={MenuConstants.Menu_Dasboard} />
                    <PrivateRoute path="/home" component={Home}  claimValue={MenuConstants.Menu_Dasboard} />
                    <PrivateRoute path="/streetanimals" component={StreetAnimals} claimValue={MenuConstants.Menu_StreetAnimal}/>
                    <PrivateRoute path="/faq" component={Faq} claimValue = {MenuConstants.Menu_FAQ_Info}/>
                    <PrivateRoute path="/users" component={Users} claimValue = {MenuConstants.Menu_User}/>
                    <PrivateRoute path="/incidence" component={Incidence} claimValue={MenuConstants.Menu_Incident}/>
                    <PrivateRoute path="/profile" component={Profile} claimValue={MenuConstants.Menu_Change_Password} />
                    <PrivateRoute path="/shelteranimals" component={ShelterAnimals} claimValue={MenuConstants.Menu_ShelterAnimal} />
                    <PrivateRoute path="/shelters" component={Shelters} claimValue={MenuConstants.Menu_ShelterAnimal}/>
                    <PrivateRoute path="/toinform" component={ToInform} claimValue = {MenuConstants.Menu_ToInform}/>
                    <PrivateRoute path="/notifications" component={Notifications} claimValue={MenuConstants.Menu_Notification} />
                    <PrivateRoute path="/adoptionapplications" component={AdoptionApplications} claimValue={MenuConstants.Menu_AdoptionApplication} />
                    <Route path="/auth" component={Auth} />
                    <Route path="/nopermission" component={NoPermission} />
                    <Route path="/survey" component={Survey} />
                    <Route path="/kvkk" component= {Kvkk} />
                </Switch>
            </ScrolltoTop>
        </Router>
    );
};

export default App as any
