export type LoginStateType = {
    email: string,
    password: string
}

export interface LoginPropsType extends LoginStateType {
    history?: any,
    location?: any
}

export type PasswordStateType = {
    email: string,
}

export type NewPasswordStateType = {
    token?: string,
    email?: string,
    password: string,
    confirmPassword: string
}

export interface PasswordPropsType extends PasswordStateType {
    history?: any,
    location?: any
}

export const LoginStateDefaultValue: LoginStateType = {
    email: '',
    password: ''
};

export const NewPasswordStateDefaultValue: NewPasswordStateType = {
    password: '',
    confirmPassword: ''
};

