import {getAuthApiBaseUrl, handleErrorType, authSetInfoForStorage} from "../_helpers";
import {krax} from "../store";
import {LoginStateType, PasswordStateType, NewPasswordStateType} from "../pages/Auth/type";
import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

export const checkAuthentication = async (values: LoginStateType) => {

    const urlSuffix = '/users/AdminLogin';
    const url: string = String(new URL(urlBase() + urlSuffix));
    const data: any = {
        email: values.email,
        password: values.password
    };

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        body: data
    };

    return await krax({
        name: 'loginWithToken',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            authSetInfoForStorage(state.payload);

            toastMessage({
                messageType: 'success',
                message: 'Giriş Başarılı',
                position: 'topRight',
                icon: 'fad fa-check',
            })
        },
        onError: (_: any, state: any, error: any) => {

            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};


export const logOut = async () => {

    return await krax({
        name: 'loginWithToken',
        payload: {},
        onSuccess: (_: any, state: any) => {
            sessionStorage.clear();

            toastMessage({
                messageType: 'success',
                message: 'Çıkış Başarılı',
                position: 'topRight',
                icon: 'fad fa-check',
            })
        }
    });
};

export const forgotPassword = async (values: PasswordStateType) => {

    const urlSuffix = '/users/forgotpassword';
    const url: string = String(new URL(urlBase() + urlSuffix));
    const data: any = {
        email: values.email,
    };

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(data => {
            if (data.status === 200) {
                toastMessage({
                    messageType: 'info',
                    message: 'E-Posta adresinizi kontrol edin',
                    position: 'topRight',
                    icon: 'fad fa-check',
                })
            }
            // console.log('Success:', data);
        })
        .catch((error) => {
            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: "",
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',
            });
            console.error('Error:', error);
        });
};

export const resetPassword = async (data: NewPasswordStateType) => {

    const urlSuffix = '/users/resetpassword';
    const url: string = String(new URL(urlBase() + urlSuffix));

    const bodyData: any = {
        ...data
    };

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        body: bodyData
    };

    return await krax({
        name: 'passwordChange',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'info',
                message: 'Şifreniz başarıyla değiştirildi',
                position: 'topRight',
                icon: 'fad fa-check',
            });
        },
        onError: (_: any, state: any, error: any) => {

            if (error && state && state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                })
            }
        }
    });
};

