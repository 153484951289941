import React from 'react';
import {IChartProps} from "./types";
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface IProps extends IChartProps {
}

const ChartComponent = (props: IProps) => {

    const {data, height = '100%', width = '100%', caption = 'Chart', subCaption = '', defaultCenterLabel = 'Chart Center', paletteColors} = props;

    let configs = {
        type: "doughnut2d",
        width: width,
        height: height,
        dataFormat: "JSON",
        dataSource: {
            chart: {
                caption: caption,
                subcaption: subCaption,
                showpercentvalues: "1",
                defaultcenterlabel: defaultCenterLabel,
                aligncaptionwithcanvas: "0",
                captionpadding: "0",
                decimals: "1",
                plottooltext:
                    "<b>$percentValue</b> - <b>$label</b>",
                centerlabel: "$value",
                theme: "fusion",
                paletteColors: paletteColors,
            },
            data: data
        }
    };

    return (
        <>
            <ReactFC {...configs} />
        </>
    );
};

export default ChartComponent as any;