import React, { memo, useEffect, useState } from 'react';
import { TitleBar, Tooltip } from '../../components';
import { IShelterAnimalListPageProps, IShelterAnimalListPageState, IShelterAnimalListPageDefaultValue, IShelterAnimalFilterProps, IShelterAnimalFilterDefaultValue, IShelterAnimalFormPageDefaultValue, IShelterAnimalItemProps } from "./types";
import { connect } from "../../store";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { DataGrid as Grid, Modal, Button, toastMessage, Input, Form, Select } from 'oneframe-react';
import { IconButton, Avatar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Description from "../../components/Description";
import { LookupConstants } from './../../_constants/lookup';
import { getLookups} from './../../_helpers';
import { filter } from 'lodash';
import { getShelterAnimals, deleteShelterAnimal, getShelterList } from "../../_actions/shelterAnimals";
import DownloadExcel from './../../components/ExcelExport/exceldownload'
import generatePDF from '../../components/PdfExport/pdfexport';
import { ExportTypes } from './../../_constants/exportType';
import { ExportNames } from './../../_constants/exportNames';
import {ShelterAnimalStatusType, ShelterAnimalStatusList} from './../../_constants/shelterAnimalStatusType'

interface IProps extends IShelterAnimalListPageProps {
}
interface IShelterAnimalState extends IShelterAnimalListPageState {
}
interface IShelterAnimalFilterState extends IShelterAnimalFilterProps {
}

const ShelterAnimals = memo((props: IProps) => {
    
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const { pathname } = location;

    const { shelterAnimals, shelterAnimalsAllRecord } = props;    

    const [state, setState] = useState<IShelterAnimalState>(location.state == null ? IShelterAnimalListPageDefaultValue : location.state.listState);
    const [filterState, setFilterState] = useState<IShelterAnimalFilterState>(IShelterAnimalFilterDefaultValue);
    const [exportDataState, setExportDataState] = useState<IShelterAnimalItemProps[] | null>(null);
    const { triggerFilter } = filterState;

    const { page, pageSize, modalShow, deleteControl } = state;

    const operationTypes: any = {
        edit: 'edit',
        delete: 'delete'
    };

    const getAllDataOnHandleClick = (exportType: string) => {
        setState((prevState: IShelterAnimalState) => ({
            ...prevState,
            exportType: exportType
        }));

        // state sıfırlanmazsa ikinci kez indirilemiyor.
        setExportDataState(null);
        // tüm kayıtları çekebilmek için totalCount ve page 0 kullanılıyor.
        getShelterAnimals(32000, 0, filterState, 'shelterAnimalsAllRecord');
        // useEffect shelterAnimalsAllRecord.payload kısmına düşer.
    }

    const handleClick = (id: string, operation: string) => {

        if (operation === operationTypes.edit) {
            history.push(`/shelteranimals/edit/${id}`, {listState: state, formDefaultState: IShelterAnimalFormPageDefaultValue}) // mevcut state'i daha sonra geri alabilmek için gönderiyoruz.
        } else if (operation === operationTypes.delete) {
            deleteShelterAnimal(id, () => {
                toastMessage({
                    messageType: 'success',
                    message: 'Geçici Bakım Evi Sakini Silindi',
                    position: 'topRight',
                    icon: 'fad fa-trash',
                })

                setState((prevState: IShelterAnimalState) => ({
                   ...prevState,
                   deleteControl: true,
                   modalShow: false,
                   deleteItemId: ''
                }))
            });
        }
    };

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            pageSize: 10
        }));

        getShelterList(function (_:any, response:any) {
            if (response.payload) {
                var allLookups:any = getLookups();
                var ageList = filter(allLookups, function (o) { return o.key === LookupConstants.AGE });
                var genderList = filter(allLookups, function (o) { return o.key === LookupConstants.GENDER });
                var weightList = filter(allLookups, function (o) { return o.key === LookupConstants.WEIGHT });
                var kindList = filter(allLookups, function (o) { return o.key === LookupConstants.KIND });

                setState((prevState: IShelterAnimalState) => ({...prevState, 
                    ageList: ageList[0].values,
                    genderList: genderList[0].values,
                    weightList: weightList[0].values,
                    kindList: kindList[0].values,
                    shelterList: response.payload.items,
                }));
            }
        });
    }, [pathname]);

    useEffect(() => {        
        getShelterAnimals(pageSize, page, filterState, 'shelterAnimals');
    }, [page, pageSize, deleteControl, triggerFilter]);

    useEffect(() => {
        if (shelterAnimals.payload && shelterAnimals.payload.items) {
            // Daha önceden yüklenmiş olan payload'daki page ve pageSize'ı iptal etmek için eklendi. Çünkü sonsuz döngüye giriyordu.
            if (page == shelterAnimals.payload.pageIndex && pageSize == shelterAnimals.payload.pageSize) {
                setState((prevState: IShelterAnimalState) => ({
                    ...prevState,
                    shelterAnimalList: shelterAnimals.payload.items,
                    page: shelterAnimals.payload.pageIndex,
                    totalCount: shelterAnimals.payload.totalCount,
                    totalPages: shelterAnimals.payload.totalPages                
                }));
            }
        }        
    }, [shelterAnimals.payload]);

    useEffect(() => {
        if (shelterAnimalsAllRecord.payload && shelterAnimalsAllRecord.payload.items) {
            var customizedData = customizeData(shelterAnimalsAllRecord.payload.items);

            if (state.exportType == ExportTypes.EXCEL) {
                setExportDataState(customizedData);
            } else if (state.exportType == ExportTypes.PDF) {
                generatePDF(exportColumns, customizedData, ExportNames.SHELTER_ANIMALS.TITLE, ExportNames.SHELTER_ANIMALS.FILE_NAME)
            }

            setState((prevState: IShelterAnimalState) => ({
                ...prevState,
                exportType: ''
            }));
        }
    }, [shelterAnimalsAllRecord.payload]);

    const filterHandleChange = (e: any) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const filterHandleSubmit = () => {
        // arama sonrasında page 0 a çekilmeli bu filtreyi de tetikler. page 0 ise, else kısmı tetikler.
        if (state.page != 0) {
            setState((prevState) => ({
                ...prevState,
                page: 0
            }));
        }
        else {
            //triggerFilter değiştiğinde use effect getIncident a düşer.
            setFilterState((prevState) => ({
                ...prevState,
                triggerFilter: !triggerFilter
            }));
        }
    }

    const resetFilterHandle = () => {
        setFilterState(IShelterAnimalFilterDefaultValue);
    }

    const customizeData = (allData: any) => {
        allData.forEach((item) => {
            item.isAliveText = item.isAlive ? "Evet" : "Hayır";
            item.isDeletedText = item.isDeleted ? "Evet" : "Hayır";
            item.isPassiveText = item.isPassive ? "Pasif" : "Aktif";
            item.status = item.status == ShelterAnimalStatusType.Adoptable ? "Sahiplendirilebilir" : "Sahiplendirildi";
        });

        return allData;
    }

    const exportColumns = [
        { label: "Tür", value: "kind"},
        { label: "Mikroçip Numarası", value: "microchipNumber" },
        { label: "İsim", value: "name" },
        { label: "Cinsiyet", value: "gender" },
        { label: "Yaş", value: "age" },
        { label: "Kilosu", value: "weight" },        
        { label: "Geçici Bakım Evi", value: "shelterName" },
        { label: "Aktif/Pasif", value: "isPassiveText"},
        { label: "Silinmiş Mi?", value: "isDeletedText"},
        { label: "Yaşıyor Mu?", value: "isAliveText" },
        { label: "Sahiplendirme Durumu", value: "status" },
    ]

    return (
    <>
        <TitleBar
            title={'Geçici Bakım Evi Sakinleri'}
            loading={shelterAnimals.loading}
            button={[
                {
                    text: 'Geçici Bakım Evi Sakini Ekle',
                    onClick: () => {
                        history.push('/shelteranimals/add');
                    },
                    iconLeft: <i className="fad fa-plus mr-2"/>,
                    className: 'custom-orange-button'
                }
            ]}
            breadcrumbs={[
                {
                    text: <i className="fas fa-home" />,
                    link: '/'
                },
                {
                    text: 'Geçici Bakım Evi Sakinleri',
                    link: "/shelteranimals"
                }
            ]}
        />

        <div className="wrapper">
        <div className="mb-3">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filtreleme Kriterleri</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Form onSubmit={() => filterHandleSubmit()}>
                                <MUIGrid container spacing={3}>     
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="kind"
                                            name="kind"
                                            caption={'Tür'}
                                            options={{
                                                data: state.kindList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.kind
                                            }}
                                        />
                                    </MUIGrid>                               
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="name"
                                            name="name"
                                            caption="İsim"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.name}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="microchipNumber"
                                            name="microchipNumber"
                                            caption="Mikroçip No"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.microchipNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="gender"
                                            name="gender"
                                            caption={'Cinsiyet'}
                                            options={{
                                                data: state.genderList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.gender
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="age"
                                            name="age"
                                            caption={'Yaş'}
                                            options={{
                                                data: state.ageList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.age
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="weight"
                                            name="weight"
                                            caption={'Kilosu'}
                                            options={{
                                                data: state.weightList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.weight
                                            }}
                                        />
                                    </MUIGrid>                                   
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="shelterName"
                                            name="shelterName"
                                            caption={'Geçici Bakım Evi'}
                                            options={{
                                                data: state.shelterList,
                                                displayField: 'name',
                                                displayValue: 'name',
                                                selected: filterState!.shelterName
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="isActive"
                                            name="isActive"
                                            caption={'Aktif/Pasif'}
                                            options = {{
                                                data: [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }],
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected: filterState!.isActive! ? filterState!.isActive!.toString() : null
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="isDeleted"
                                            name="isDeleted"
                                            caption={'Silinmiş Mi?'}
                                            options = {{
                                                data: [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }],
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected: filterState!.isDeleted ? filterState!.isDeleted!.toString() : null
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="isAlive"
                                            name="isAlive"
                                            caption={'Yaşıyor Mu?'}
                                            options={{
                                                data: [{ value: "true", name: "Yaşıyor" }, { value: "false", name: "Yaşamıyor" }],
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected: filterState!.isAlive
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="status"
                                            name="status"
                                            caption={'Sahiplendirme Durumu'}
                                            options = {{
                                                data: ShelterAnimalStatusList,
                                                displayField: 'text',
                                                displayValue: 'value',
                                                selected: filterState!.status
                                            }}
                                        />
                                    </MUIGrid>      

                                    <MUIGrid item xs={12}>
                                        <MUIGrid container spacing={0} direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="reset"
                                                    text="Temizle"
                                                    className='custom-blue-button'
                                                    onClick={resetFilterHandle}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="submit"
                                                    text="Ara"
                                                    className='custom-green-button'
                                                />
                                            </MUIGrid>
                                        </MUIGrid>
                                    </MUIGrid>
                                </MUIGrid>
                            </Form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="export-row mb-3 text-right">
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.PDF)}>
                        <Button type="submit" text="PDF" className='custom-orange-button' iconLeft={() => <i className="fas fa-file-pdf mr-2" />}></Button>
                    </Form>
                    <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.EXCEL)}>
                        <Button type="submit" text="Excel" className='custom-green-button ml-3' iconLeft={() => <i className="fas fa-file-excel mr-2" />}></Button>
                        {exportDataState != null && exportDataState.length > 0 ?
                            <DownloadExcel
                                fileName={ExportNames.SHELTER_ANIMALS.FILE_NAME}
                                buttonText="Excel İndir"
                                data={exportDataState}
                                sheetName= {ExportNames.SHELTER_ANIMALS.TITLE}
                                columns = {exportColumns}
                            />
                            :
                            null
                        }
                    </Form>
                </div>
        {!shelterAnimals.loading ?
            (state.shelterAnimalList.length ?
                <Grid
                    data={state.shelterAnimalList}
                    page={state.page}
                    rowsPerPage={state.pageSize}
                    count={state.totalCount}                    
                    totalPages={state.totalPages}                    
                    serverSide={pageSize !== state.totalCount}
                    loadingState={shelterAnimals.loading}
                    onChangeRowsPerPage={(a: any) => {
                        setState(prevState => ({
                            ...prevState,
                            pageSize: a,
                            page: 0
                        }))
                    }}
                    onChangePage={(a: any) => {
                        setState(prevState => ({
                            ...prevState,
                            page: a
                        }))
                    }}
                    header={{
                        print: false,
                        download: false,
                        search: false,
                        filter: false,
                        viewColumns: false
                    }}
                    helperInfoText={' geçici bakım evi sakini var'}
                    sort={false}
                    labels={{
                        noData: 'Hiç Data Yok',
                        headerTooltip: 'Sırala',
                        paginationNext: 'Sonraki Sayfa',
                        paginationPrev: 'Önceki Sayfa',
                        paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                        seperator: '/'
                    }}
                    hiddenColumns={['id']}
                    column={ 
                    [
                        {
                            label: '',
                            displayField: '',
                            customRender: () => {
                                return (
                                    <>
                                        <div className="rounded-image">
                                            <Avatar src="/streetAnimal.png" />
                                        </div>
                                    </>
                                );
                            }
                        }, 
                        {
                            label:'Tür',
                            displayField: 'kind'
                        },
                        {
                            label: 'İsim',
                            displayField: 'name',
                        },
                        {
                            label:'Mikroçip No',
                            displayField: 'microchipNumber'
                        },
                        {
                            label: 'Cinsiyet',
                            displayField: 'gender',
                        },
                        {
                            label: 'Yaş',
                            displayField: 'age',
                        },
                        {
                            label:'Kilosu',
                            displayField: 'weight',
                        },                        
                        {
                            label: 'Geçici Bakım Evi',
                            displayField: 'shelterName',                            
                        },
                        {
                            label: 'Aktif/Pasif',
                            displayField: 'isActive',
                            customRender: (value: any, allValue: any) => {
                                return (
                                    <div className={value ? 'txt-green' : 'txt-red'}>
                                        {value ? <span>Aktif</span> : <span>Pasif</span>}
                                    </div>
                                )
                            }
                        },
                        {
                            label: 'Silinmiş Mi?',
                            displayField: 'isDeleted',
                            customRender: (value: any, allValue: any) => {
                                return (
                                    <div className={value ? 'txt-red' : 'txt-green'}>
                                        {value ? <span>Evet</span> : <span>Hayır</span>}
                                    </div>
                                )
                            }
                        },
                        {
                            label: 'Yaşıyor Mu?',
                            displayField: 'isAlive',
                            customRender: (value: any, allValue: any) => {
                                return (
                                    <div className={value ? 'txt-green': 'txt-red'}>
                                        {value ? <span>Evet</span> : <span>Hayır</span>}
                                    </div>
                                )
                            }
                        },
                        {
                            label: 'Sahiplendirme Durumu',
                            displayField: 'status',
                            customRender: (value: any, allValue: any) => {
                                return (
                                    <div className={value == ShelterAnimalStatusType.Adoptable ? 'txt-green': 'txt-red'}>
                                        {value == ShelterAnimalStatusType.Adoptable ? <span>Sahiplendirilebilir</span> : <span>Sahiplendirildi</span>}
                                    </div>
                                )
                            }
                        },
                        {
                            label: 'İşlemler',
                            displayField: '',
                            customRender: (value: any, rowData: any) => {
                                return (
                                    <>
                                        <div className="table-icon">
                                            <Tooltip title="Düzenle" placement="bottom">
                                                <IconButton className="detail-icon bg-blue" onClick={() => handleClick(rowData.id, operationTypes.edit)}>
                                                    <i className="fas fa-edit fa-1x"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil" placement="bottom">
                                                <IconButton
                                                    className="remove-icon bg-red ml-2"
                                                    onClick={() => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            modalShow: true,
                                                            deleteItemId: rowData.id
                                                        }))
                                                    }}
                                                >
                                                    <i className="fas fa-trash fa-1x"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                )
                            }
                        }
                    ]}
                />
                : <Description
                    text={"Herhangi bir Geçici Bakım Evi Sakini yok" } />
            ) : null
        }
        

                  <Modal
                    title={
                        <div className="p-3">
                            <span><i className="fad fa-question-circle mr-2"/>Geçici Bakım Evi Sakini İşlemi</span>
                        </div>
                    }
                    show={modalShow && state.deleteItemId.length > 0}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={'xs'}
                    footer={
                        <div className="p-3">
                            <Button
                                text="Sil"
                                className="custom-red-button h-75 mr-2"
                                iconLeft={() => <i className="fad fa-trash mr-2"/>}
                                onClick={() => handleClick(state.deleteItemId, operationTypes.delete)}
                            />
                            <Button
                                text="İptal"
                                className="custom-blue-button h-75"
                                iconLeft={() => <i className="fad fa-times mr-2"/>}
                                onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        modalShow: false,
                                        deleteItemId: ''
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span>Geçici bakım evi sakinini silmek istediğinize emin misin?</span>
                </Modal>
            </div>
    </>)    
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ConnectComponent = connect((store: any) => ({ shelterAnimals: store.shelterAnimals, shelterAnimalsAllRecord: store.shelterAnimalsAllRecord, lookups: store.lookups, cities: store.cities, counties: store.counties, neighbourhoods: store.neighbourhoods }))(ShelterAnimals) as any;
export default ConnectComponent