import {IKraxResult} from "../../_actions/kraxTypes";
import {/*ILocationResponseProps,*/ ILookUpListProps} from "./../common/types";
import { ExportNames } from './../../_constants/exportNames';


export interface IShelterAnimalsProps {
    items: IShelterAnimalItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}



export interface IShelterAnimalItemProps {
    name: string,
    age: string,
    gender: string,
    breed: string,    
    id: string,
    weight: string,
    microchipNumber:string,
    shelterName: string
}

export interface IShelterAnimalListPageProps {
    shelterAnimals: IKraxResult<IShelterAnimalsProps>,
    shelterAnimalsAllRecord: IKraxResult<IShelterAnimalsProps>,
    lookups: IKraxResult<ILookUpListProps>    
}

export interface IShelterAnimalListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    shelterAnimalList: IShelterAnimalItemProps[],
    exportTitle: string,
    exportFileName: string, 
    selectedItem: any,
    operationType: string,
    modalWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined,
    tabIndex: number,
    shelterList: any,
    genderList: any,
    ageList: any,
    weightList: any,
    kindList: any,
    earringNumber: string,
    exportType?: string,
    shelterName: string,
    deleteControl: boolean,
    deleteItemId: string,
    microchipNumber: string,
    modalShow: boolean
}

export const IShelterAnimalListPageDefaultValue: IShelterAnimalListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    shelterAnimalList: [],
    exportFileName: ExportNames.SHELTER_ANIMALS.FILE_NAME,
    exportTitle: ExportNames.SHELTER_ANIMALS.TITLE,
    selectedItem: null,
    operationType: '',
    modalWidth: false,
    tabIndex: 0,
    shelterList: [],
    genderList: [],
    ageList: [],
    weightList: [],
    kindList: [],    
    shelterName: '',
    earringNumber: '',
    microchipNumber: '',
    deleteControl: false,
    deleteItemId: '',
    modalShow: false
};

export interface IShelterAnimalFilterProps {
    name: string,
    breed: string,    
    gender: string,    
    age: string,
    weight: string,    
    shelterName: string,
    kind: string,
    microchipNumber: string,
    isDeleted?: boolean,
    isAlive?: boolean,
    isActive?: boolean,
    status?: number,
    triggerFilter: boolean
}

export const IShelterAnimalFilterDefaultValue : IShelterAnimalFilterProps={   
    name: '',     
    breed: '',      
    gender: '',    
    age: '',
    weight: '',    
    shelterName: '',
    kind: '',
    microchipNumber: '',     
    triggerFilter: false
}


export interface IShelterAnimalFormPageProps {    
    shelterAnimalItem: IKraxResult<IShelterAnimalFormPageState>
    lookups: IKraxResult<ILookUpListProps>
}


export interface IShelterAnimalFormPageState {
    id: string,
    name: string,
    breed: string,
    breedList: any,
    gender: string,
    genderList: any,
    age: string,
    ageList: any,
    shelterId: string,
    shelterList: any,
    weight: string,
    weightList: any,
    physicalAttributeList: any,
    physicalAttribute: string,
    updatedDate: string,
    updatedUser: string
    insertedDate: string,
    insertedUser: string,
    about: string, 
    status: number,
    earringNumber: string,
    kind: string,
    kindList: any,
    images?: any,
    character: string,
    characterList: any,
    microchipNumber: string,
    isAlive: boolean,
    isDeleted: boolean,
    isActive: boolean
}

export const IShelterAnimalFormPageDefaultValue: IShelterAnimalFormPageState = {
    id: '',
    name: '',
    breed: '',
    breedList:[],
    gender: '',
    genderList:[],
    age: '',    
    ageList:[],
    shelterId: '',
    shelterList: [],
    weight: '',
    weightList: [],
    physicalAttributeList: [],
    physicalAttribute: '',
    updatedDate: '',
    updatedUser: '',
    insertedDate: '',
    insertedUser: '',
    about: '',
    status: 0,
    earringNumber: '',
    kind: '',
    kindList: [],
    images:[],
    character: '',
    characterList:[],
    microchipNumber: '',
    isAlive: true,
    isDeleted: false,
    isActive: true
};

export interface IImageItemProps {
    id: string,
    filename: string,
    imageData: string,
    isNew: boolean
}