import {getAuthApiBaseUrl} from "../_helpers";
import { apiGetRequest, apiPostRequest } from "./common";

const urlBase = () => getAuthApiBaseUrl();

export const sendNotification = async (data:any, success:any, onError) => {    
    const urlSuffix = '/notifications/send';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequest(url, 'sendNotification', data, success, onError);
}

export const lastSentInfo = async(success:any) => {
    const urlSuffix = '/notifications/last-sent-info';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiGetRequest(url, 'lastSentInfo', success);
}