import React from "react";
import ReactExport from "react-export-excel";
import {generateExportFileName} from "../../_helpers";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


interface IExcelDownloadProps  {
    fileName: string,
    buttonText: string,
    data: any[],
    sheetName: string,
    columns: any[]
}

class DownloadExcel extends React.Component<IExcelDownloadProps> {
    render() {
        return (
            <ExcelFile filename={generateExportFileName(this.props.fileName)} 
                element={<button>Download Data</button>} hideElement={true}>            
                <ExcelSheet data={ this.props.data } name={ this.props.sheetName }>
                    {this.props.columns.map((col) => (                        
                        <ExcelColumn key={col.label} label={col.label} value={col.value} />                        
                    ))}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default DownloadExcel;