import React, {memo} from 'react';
import {Button} from 'oneframe-react'
import BreadCrumb from '../Breadcrumb'
import {LinearProgress, Tabs, Tab} from '@material-ui/core';

interface IButton {
    text: string,
    link?: string,
    iconLeft?: React.ReactNode,
    iconRight?: React.ReactNode,
    onClick?: () => void,
    className?: string
}

interface ITab extends IButton {
}

interface ITitleBar {
    title: string,
    button?: IButton[],
    loading?: boolean,
    breadcrumbs?: any[],
    history?: any,
    tabActive?: number,
    tabs?: ITab[]
}

const TitleBarComponent = memo((props: ITitleBar) => {

    const {title, button, loading, tabs, tabActive, breadcrumbs} = props;

    const eventType: any = (item: any) => {
        let x: any = {};

        if (item.link) {
            x = {
                href: item.link
            }
        } else if (item.onClick) {
            x = {
                onClick: item.onClick
            }
        }

        return x
    };

    return (
        <>
            <div className="title-bar">
                <div className="title-area">
                    <div className="left">
                        <div className="user-name">
                            <BreadCrumb data={breadcrumbs}/>
                        </div>
                        <h1>
                            {title}
                        </h1>
                    </div>
                    <div className="right">
                        {button && button.length && button.map((item: any, i: number) => {

                            return (
                                <Button
                                    key={i}
                                    {...eventType(item)}
                                    text={item!.text}
                                    iconLeft={item!.iconLeft}
                                    iconRight={item!.iconRight}
                                    className={item!.className}
                                />
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            {loading && <LinearProgress/>}

            {tabs && <div className="tabs-container">
                <Tabs value={tabActive}>
                    {tabs && tabs.length && tabs.map((item: any, i: number) => {
                        return (
                            <Tab
                                key={i}
                                {...eventType(item)}
                                icon={item!.iconLeft}
                                label={item!.text}
                            />
                        )
                    })
                    }
                </Tabs>
            </div>}
        </>
    )
});

export default TitleBarComponent;



