import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';

export default ({match}: any) => {
    return (
        <Switch>
            <Route path={`${match.url}/login`} component={Login}/>
            <Route path={`${match.url}/forgotpassword`} component={ForgotPassword}/>
            <Route path={`${match.url}/newpassword`} component={NewPassword}/>
        </Switch>
    )
};

