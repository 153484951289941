import {IKraxResult} from "../../_actions/kraxTypes";
import {ILocationResponseProps, ILookUpListProps} from "./../common/types";

export interface IIncidentProps {
    items: IIncidentItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IIncidentItemProps {
    id: string,
    incidentId: number,
    insertedDate: string,
    incidentType: string,
    statusText: string,
    streetAnimalName: string,
    cityName: string,
    countyName: string,
    neighbourhoodName:string,
    isDeleted:string
}

export interface IIncidentFilterProps {
    incidentId?: number,
    insertedDate: string,
    incidentType?: string,
    status: string,
    streetAnimalName: string,
    cityId?: number,
    countyId?: number,
    neighbourhoodId?: number,
    isDeleted?: boolean,
    triggerFilter: boolean
}

export const IIncidentFilterDefaultValue : IIncidentFilterProps={
    incidentId: undefined,
    insertedDate: '',
    status: '',
    streetAnimalName: '',
    triggerFilter: false
}

export interface IIncidentListPageProps {
    incident: IKraxResult<IIncidentProps>,
    incidentAllRecord: IKraxResult<IIncidentProps>,
    incidentTypes: IKraxResult<IIncidentTypesResponseProps>,
    lookups: IKraxResult<ILookUpListProps>,
    cities: IKraxResult<ILocationResponseProps>,
    counties: IKraxResult<ILocationResponseProps>,
    neighbourhoods: IKraxResult<ILocationResponseProps>
}

export interface IIncidentTypesResponseProps{
    [index: number]: { id: string; name: string }
}

export interface ITypeListProps {
    incidentTypes: any[]
}


export const ITypeListDefaultValue: ITypeListProps = {
    incidentTypes: []
}

export interface IIncidentListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    incidentList: IIncidentItemProps[],
    deleteControl: boolean,
    deleteItemId: string,
    modalShow: boolean,
    exportType?: string
}

export const IIncidentListPageDefaultValue: IIncidentListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    incidentList: [],
    deleteControl: false,
    deleteItemId: '',
    modalShow: false
};

export interface IIncidentDetailItemProps {
    id: string,
    incidentId: number,
    insertedDate: string,
    incidentType: string,
    status: string,
    statusText: string,
    streetAnimalName: string,
    cityName: string,
    countyName: string,
    neighbourhoodName:string,
    activePassive:string,
    detail:string,
    insertedUser:string,
    imageIds: string[],
    imageBase: IImageItemProps[],
    cancelComment: string,
    cancelInsidentReason: string
}



export interface IImageItemProps {
    id: string,
    filename: string,
    imageData: string
}

export interface IImageProps {
    images: IImageItemProps[]
}

export interface IIncidentFormPageProps {    
    incidentItem: IKraxResult<IIncidentDetailItemProps>
    incidentActions: IKraxResult<IIncidentActionProps>
}

 export interface IIncidentFormPageState {
    id: string,
    incidentId: number,
    insertedDate: string,
    incidentType: string,
    status: string,
    statusText: string,
    streetAnimalName: string,
    cityName: string,
    countyName: string,
    neighbourhoodName:string,
    activePassive:string,
    detail:string,
    insertedUser:string,
    imageIds: any,
    imageBase: any,
    cancelComment: string,
    cancelInsidentReason: string
}

 export const IIncidentFormPageDefaultValue: IIncidentFormPageState = {
    id: "",
    incidentId: 0,
    insertedDate: "",
    incidentType: "",
    status: "",
    statusText: "",
    streetAnimalName: "",
    cityName: "",
    countyName: "",
    neighbourhoodName:"",
    activePassive:"",
    detail:"",
    insertedUser:"",
    imageIds: [],
    imageBase: [],
    cancelComment: "",
    cancelInsidentReason: ""
 };

 export interface IIncidentActionProps {
    items: IIncidentActionItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

 export interface IIncidentActionItemProps {
    id: string,
    date: string,
    insertedUser: string,
    detail: string
}

 export interface IIncidentActionListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    incidentActionList: IIncidentActionItemProps[]
}

export const IIncidentActionListPageDefaultValue: IIncidentActionListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    incidentActionList: []
};