import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from './store'
import {ThemeProvider, theme, GlobalStyle} from "./_utility";
import './_assets/styles/main.scss'

const app = <App/>;
const container = (
    <>
        <ThemeProvider theme={theme}>
            <Provider>
                {app}
            </Provider>
        </ThemeProvider>
        <GlobalStyle/>
    </>
);

const documentContainer = document.getElementById('root');
ReactDOM.render(container, documentContainer);
serviceWorker.unregister();
