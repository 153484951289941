import { getAuthApiBaseUrl } from "../_helpers";
import { apiPostRequestWithoutAuthorization } from "./common";

const urlBase = () => getAuthApiBaseUrl();

export const getSurveySubscriberInfo = async (data: any, success: any, error: any) => {
    const urlSuffix = '/survey/GetSubscriberInfo';
    const url:string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequestWithoutAuthorization(url, 'surveySubscriberInfo', data, success, error);
}

export const saveSurveyAnswer = async (data: any, success: any, onError) => {
    const urlSuffix = '/survey/SaveAnswer';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    apiPostRequestWithoutAuthorization(url, 'saveSurveyAnswer', data, success, onError);
}