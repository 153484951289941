import React, { memo, useEffect, useState } from 'react';
import {TitleBar} from '../../components'
import { sendNotification, lastSentInfo } from "../../_actions/notifications";
import {connect} from "../../store";
import { INotificationFormPageDefaultValue, INotificationFormPageState } from "./types";
import { Paper } from '@material-ui/core';
import { Button, Form, Input, toastMessage, Modal } from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'

interface IProps {
}

interface IFormState extends INotificationFormPageState {
}

const SendNotification = memo((props: IProps) => {
    const [state, setState] = useState<IFormState>(INotificationFormPageDefaultValue);
    const [loading, setLoading] = useState(false);

    const { modalShow } = state;

    const showLoading = () => {
        setLoading(true);
    }

    const hideLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        getLastSentInfo();
    }, []);

    const getLastSentInfo = () => {
        lastSentInfo(function (_:any, response:any) {
            if (response.payload) {               
                setState((prevState: IFormState) => ({...prevState, lastSentDate: response.payload.lastSentDate}));
            }
        });
    }

    const sendNotificationClick = async () => {
        showLoading();
        const data: any = {
            title: state.title,
            body: state.body,
        }

        const onError = (_:any, response:any) => {   
            hideLoading();
            var errorMessage = "Bilinmeyen bir hata oluştu";      
            if (response.error.errors) {
                errorMessage = response.error.errors.message;
            }

            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: errorMessage,
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',                  
            });
        };

        await sendNotification(data, (_:any, response:any) => {
            hideLoading();

            toastMessage({
                messageType: 'success',
                message: 'Bildirim Başarıyla Gönderildi',
                position: 'center',
                timeout: 0,
                icon: 'fad fa-exclamation-square',
            });

            setState(INotificationFormPageDefaultValue);

            getLastSentInfo();                
        }, onError);
    }

    const handleSubmit = async () => {
        setState(prevState => ({
            ...prevState,
            modalShow: true
        }))
    }

    const handleChange = (e: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const renderFormInfo = () => {
        return (
            <div className="ml-auto info-area">
                <em><strong>Son Bildirim Gönderim Zamanı : </strong><span>{state.lastSentDate ? moment(state.lastSentDate).format('LL') : '-'}</span></em>
            </div>
        )
    }

    const renderForm = () => {
        return (
            <Paper className="p-4" elevation={0}>
                <div className="row mb-4">
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <h6><strong>Bildirim Detayı</strong></h6>
                            {renderFormInfo()}
                        </div>
                    </div>
                </div>
                <hr/>
                <Form onSubmit={() => handleSubmit()}>
                    <div className="row mb-4">
                        <div className="col-6 offset-3">
                            <Input
                                id="title"
                                name="title"
                                caption="Başlık"
                                className="custom-login-field"                                        
                                value={state!.title || ''}
                                onChange={handleChange}
                                validate={[
                                    {
                                        required: 'required',
                                        message: 'Bu alan boş olamaz'
                                    }
                                ]}                                        
                            />                               
                        </div>                            
                    </div>
                    <div className="row mb-4">
                        <div className="col-6 offset-3">
                            <Input
                                id="body"
                                name="body"
                                caption="İçerik"
                                className="custom-login-field"                                        
                                value={state!.body || ''}
                                multiline
                                rows={4}
                                onChange={handleChange}
                                validate={[
                                    {
                                        required: 'required',
                                        message: 'Bu alan boş olamaz'
                                    }
                                ]}                                       
                            />                               
                        </div>                            
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-2 ml-auto">
                            <div className="form-field">
                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={loading}
                                    text={'Gönder'}
                                    className="custom-blue-button"
                                    iconRight={() => <i className="fad fa-paper-plane ml-2"/>}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Paper>
        )
    }

    return (
        <div>
            <TitleBar
                title='Bildirim Gönder'
                loading={loading}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Bildirimler',
                        link: '/notifications'
                    },
                    {
                        text: 'Bildirim Gönder',
                        link: '/notifications/send'
                    }
                ]}
            />

            <div className="wrapper">
                { renderForm() }
            </div>
            <Modal
                title={
                    <div className="p-3">
                        <span><i className="fad fa-question-circle mr-2"/>Bildirim Gönderme İşlemi</span>
                    </div>
                }
                show={modalShow}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={'xs'}
                footer={
                    <div className="p-3">
                        <Button
                            text="Gönder"
                            className="custom-green-button h-75 mr-2"
                            iconLeft={() => <i className="fad fa-paper-plane mr-2"/>}
                            onClick={async () => {
                                setState(prevState => ({
                                    ...prevState,
                                    modalShow: false
                                }))

                                sendNotificationClick();
                            }}
                        />
                        <Button
                            text="İptal"
                            className="custom-blue-button h-75"
                            iconLeft={() => <i className="fad fa-times mr-2"/>}
                            onClick={() => {
                                setState(prevState => ({
                                    ...prevState,
                                    modalShow: false
                                }))
                            }}
                        />
                    </div>
                }
            >
                <span>Tüm kullanıcılara bildirim gönderilecektir. Emin misiniz?</span>
            </Modal>
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {

    }))(SendNotification) as any;
export default ConnectComponent