import {buildRequestHeader, changeObjectStructure, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {krax} from "../store";
import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

export const getStatistics = async () => {

    const urlSuffix = '/dashboard/statistics';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'dashboardStatistics',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

            const {
                payload: {
                    userTypeRate,
                    incidentTypeRate,
                    incidentStatusRate
                }
            } = state;

            changeObjectStructure(userTypeRate);
            changeObjectStructure(incidentTypeRate);
            changeObjectStructure(incidentStatusRate);
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};
