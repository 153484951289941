import React, {memo} from 'react';
import {Tooltip} from '@material-ui/core';
import {ITooltipProps} from "./type";

export default memo<ITooltipProps>((props: ITooltipProps) => {

    return (
        <Tooltip
            title={props.title}
            placement={props.placement || 'top'}
        >
            {props.children}
        </Tooltip>
    )
});
