import React, {memo, useEffect, useState} from 'react';
import {TitleBar} from '../../components'
import {getFaqById, getFaqCategories, setFaq, updateFaq/*, deleteFAQInfoImage*/} from "../../_actions/toinform";
import {connect} from "../../store";
import {IFaqFormPageDefaultValue, IFaqFormPageProps, IFaqFormPageState} from "./types";
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {Paper} from '@material-ui/core';
import {Button, Form, Input, Select} from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CategoryGroup } from './../../_constants/categoryGroup';
import { EditorToolbar } from './../../_constants/editorToolbar';
//import DropzoneComponent from './../../components/Dropzone';
// import ImageGallery from "./ImageGallery";
// import { filter } from 'lodash';
// import uuidv4 from "uuidv4";


interface IProps extends IFaqFormPageProps {
}

interface IFormState extends IFaqFormPageState {
}

const Faq = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const parameter = useParams();
    const paramID: string | null = parameter && parameter['id'] && parameter['id'].length ? parameter['id'] : null;

    const [state, setState] = useState<IFormState>(IFaqFormPageDefaultValue);    
    const {faqCategories, faqItem} = props;
   
    useEffect(() => {
        getFaqCategories(CategoryGroup.Info);        
        
        if (paramID !== null) {
            getFaqById(paramID);
        }
    }, [paramID]);

    useEffect(() => {
        if (faqCategories.payload) {
            setState((prevState: IFormState) => ({
                ...prevState,
                faqCategoryList: faqCategories.payload
            }))
        }
    }, [faqCategories.payload]);

    useEffect(() => {
        if (faqItem.payload && paramID !== null) {
            const {answer, question, categoryId, insertedDate, insertedUser, updatedDate, updatedUser, categoryName/*, imageBase*/} = faqItem.payload;

            setState((prevState: IFormState) => ({
                ...prevState,
                question: question,
                answer: answer,
                categoryId: categoryId,
                insertedUser: insertedUser,
                insertedDate: insertedDate,
                updatedDate: updatedDate,
                updatedUser: updatedUser,
                categoryName: categoryName,
                //images: imageBase == null ? [] : imageBase
            }))
        }
    }, [faqItem.payload, paramID]);

    const handleSubmit = async () => {
        let data: any = {
            question: state.question,
            answer: state.answer,
            categoryId: state.categoryId,
            //images: state.images
        };

        let x: any = '';

        if (paramID !== null) {
            x = await updateFaq(paramID, data);
        } else {
            x = await setFaq(data);
        }

        x.statusCode === 200 && history.replace('/toinform/list', location.state != null ? {listState: location.state.listState} : null);
    };

    const handleChange = (e: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleEditorOnChange = (value) => {
        setState((prevState: any) => ({
            ...prevState,
            answer: value
        }))
    }

    // const onFileUpladed = (file) => {        
    //     convertBase64(file);
    // }

    // const onDeleteImage = (id: string) => {
    //     var images = state.images;
    //     var image = filter(images, function (o) { return o.id === id });
    //     if(!image[0].isNew) // önceden eklenmiş db de kayıt var silelim.
    //     {
    //         var data = {
    //             FAQInfoId: paramID,
    //             ImageId: id
    //         }            
    //         deleteFAQInfoImage(data, (_:any, response:any) => {                
                
    //             if (response.statusCode === 200) { 
    //                 var index = images.map(function(e) { return e.id == id }).indexOf(true);
    //                 images.splice(index, 1);       
    //                 setState((prevState:IFormState) => ({...prevState, images: images}));
    //             }
    //         });
    //     } else {
    //         //db de kayıt yok sadece listeden çıkartalım.                        
    //         var index = images.map(function(e) { return e.id == id }).indexOf(true);
    //         images.splice(index, 1);
    //         setState((prevState:IFormState) => ({...prevState, images: images}));
    //     }
    // }

    // const convertBase64 = (file) => {
        
    //     var reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {            
    //         var images = state.images;            

    //         var result = clearBase64(reader.result);
    //         if (images == null){
    //             images = [];
    //         }

    //         images.push({
    //             id: uuidv4(),
    //             fileName: file.name,
    //             imageData: result,
    //             isNew: true
    //         });
            
    //         setState((prevState:IFormState) => ({...prevState, images: images}));            
    //     };
    //     reader.onerror = function (error) {
    //         console.log('Error: ', error);
    //     };
    // }

    // const clearBase64 = (imageData:any) => {
    //     if (imageData != null)
    //     {
    //         // base64 çevirimi sonrasında kayıtbaşındaki değerleri siler.
    //         let searchText:string = ';base64,';
    //         let index:number = imageData.indexOf(searchText);
    //         return imageData.substring(searchText.length + index);
    //     } 
    //     else {
    //         return "";
    //     }
    // }

    // const renderImages = () => {
        
    //     var imgs: any[] = [];
        
    //     if (state.images && state.images.length > 0) {
    //         imgs = state.images;
    //     }

    //     return <div>
    //             <ImageGallery imgList={imgs} onDeleteImage={onDeleteImage} />
    //         </div>
    // }

    const renderFormInfo = () => {
        return (
            paramID !== null && !faqItem.loading ?
                <>
                    <div className="ml-auto info-area">
                        <em><strong>Ekleyen Kişi : </strong><span>{state.insertedUser}</span></em>
                        <em><strong>Eklenme Tarihi : </strong><span>{state.insertedDate && moment(state.insertedDate).format('LL')}</span></em>
                        {(state.updatedUser && state.updatedUser.length) || (state.updatedDate && state.updatedDate.length) ?
                            <>
                                <em><strong>Son Güncelleyen Kişi : </strong><span>{state.updatedUser}</span></em>
                                <em><strong>Son Güncellenme Tarihi : </strong><span>{state.updatedDate && moment(state.updatedDate).format('LL')}</span></em>
                            </>
                            :
                            null
                        }
                    </div>
                </> : null
        );
    };

    const renderForm = () => {
        return (
            !faqCategories.loading ? 
            <>
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Bilgilendirme İçeriği</strong></h6>
                                {renderFormInfo()}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <Form onSubmit={() => handleSubmit()}>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className="form-field">
                                    <Input
                                        id="question"
                                        name="question"
                                        caption="Bilgilendirme Başlığı"
                                        className="custom-login-field"
                                        value={state!.question || ''}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="categoryId"
                                        name="categoryId"
                                        caption={'Kategori Seçimi'}
                                        options={{
                                            data: state.faqCategoryList,
                                            displayField: 'name',
                                            displayValue: 'id',
                                            selected: state!.categoryId
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className='row mb-4'>                            
                            <div className="col-12">
                                <div className='MuiFormControl-root MuiTextField-root oneframe-form-field custom-login-field MuiFormControl-fullWidth'>
                                    <label className='MuiFormLabel-root MuiInputLabel-root oneframe-input-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-filled' style={{transform: "translate(14px, -20px) scale(0.8)"}}>Bilgilendirme resmi</label>
                                    <DropzoneComponent onFileUpladed = {onFileUpladed} />                         
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='row mb-4'>
                            <div className='col-12'>                            
                                {renderImages()}
                            </div>
                        </div> */}

                        <div className="row mb-4">
                            <div className="col">
                            
                            <div className='MuiFormControl-root MuiTextField-root oneframe-form-field custom-login-field MuiFormControl-fullWidth'>
                            <label className='MuiFormLabel-root MuiInputLabel-root oneframe-input-label MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-filled' style={{transform: "translate(14px, -20px) scale(0.8)"}}>Bilgilendirme metni</label>
                                
                                    <ReactQuill theme="snow" 
                                        id= "answer"
                                        defaultValue = ""
                                        modules={ EditorToolbar }
                                        value={ state!.answer || "" } 
                                        onChange={ handleEditorOnChange }/>
                                </div>
                                </div>
                            
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-2 ml-auto">
                                <div className="form-field">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        text={'Kaydet'}
                                        className="custom-blue-button"
                                        iconRight={() => <i className="fad fa-save ml-2"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Paper>
            </> : null
        );
    };

    return (
        <div>
            <TitleBar
                title={paramID !== null ? 'Bilgilendirme Düzenle' : 'Bilgilendirme Ekle'}
                loading={faqItem.loading}
                button={[
                    {
                        text: 'Bilgilen Listesine Dön',
                        onClick: () => {                             
                            history.replace('/toinform/list', location.state != null ? {listState: location.state.listState} : null);
                        },
                        iconLeft: <i className="fad fa-arrow-left mr-2"/>,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Bilgilen',
                        link: '/toinform'
                    },
                    {
                        text: paramID !== null ? 'Bilgilendirme Düzenle' : 'Bilgilendirme Ekle',
                        link: '/toinform/add'
                    }
                ]}
            />

            <div className="wrapper">
                {renderForm()}
            </div>
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {
        faqItem: store.faqItem,
        faqCategories: store.faqCategories
    }))(Faq) as any;
export default ConnectComponent
