import React, {memo, useEffect, useState} from 'react';
import {TitleBar} from '../../components'
import {getFaqById, getFaqCategories, setFaq, updateFaq} from "../../_actions/faq";
import {connect} from "../../store";
import {IFaqFormPageDefaultValue, IFaqFormPageProps, IFaqFormPageState} from "./types";
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {Paper} from '@material-ui/core';
import {Button, Form, Input, Select} from 'oneframe-react'
import moment from 'moment'
import 'moment/locale/tr'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CategoryGroup } from './../../_constants/categoryGroup';
import { EditorToolbar } from './../../_constants/editorToolbar';

interface IProps extends IFaqFormPageProps {
}

interface IFormState extends IFaqFormPageState {
}

const Faq = memo((props: IProps) => {

    const history = useHistory();
    const location = useLocation();
    const parameter = useParams();
    const paramID: string | null = parameter && parameter['id'] && parameter['id'].length ? parameter['id'] : null;

    const [state, setState] = useState<IFormState>(IFaqFormPageDefaultValue);    
    const {faqCategories, faqItem} = props;
   
    useEffect(() => {
        getFaqCategories(CategoryGroup.Faq);        
        
        if (paramID !== null) {
            getFaqById(paramID);
        }
    }, [paramID]);

    useEffect(() => {
        if (faqCategories.payload) {
            setState((prevState: IFormState) => ({
                ...prevState,
                faqCategoryList: faqCategories.payload
            }))
        }
    }, [faqCategories.payload]);

    useEffect(() => {
        if (faqItem.payload && paramID !== null) {
            const {answer, question, categoryId, insertedDate, insertedUser, updatedDate, updatedUser, categoryName} = faqItem.payload;

            setState((prevState: IFormState) => ({
                ...prevState,
                question: question,
                answer: answer,
                categoryId: categoryId,
                insertedUser: insertedUser,
                insertedDate: insertedDate,
                updatedDate: updatedDate,
                updatedUser: updatedUser,
                categoryName: categoryName
            }))
        }
    }, [faqItem.payload, paramID]);

    const handleSubmit = async () => {
        let data: any = {
            question: state.question,
            answer: state.answer,
            categoryId: state.categoryId
        };

        let x: any = '';

        if (paramID !== null) {
            x = await updateFaq(paramID, data);
        } else {
            x = await setFaq(data);
        }

        x.statusCode === 200 && history.replace('/faq/list', location.state != null ? {listState: location.state.listState} : null);
    };

    const handleChange = (e: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleEditorOnChange = (value) => {
        setState((prevState: any) => ({
            ...prevState,
            answer: value
        }))
    }

    const renderFormInfo = () => {
        return (
            paramID !== null && !faqItem.loading ?
                <>
                    <div className="ml-auto info-area">
                        <em><strong>Ekleyen Kişi : </strong><span>{state.insertedUser}</span></em>
                        <em><strong>Eklenme Tarihi : </strong><span>{state.insertedDate && moment(state.insertedDate).format('LL')}</span></em>
                        {(state.updatedUser && state.updatedUser.length) || (state.updatedDate && state.updatedDate.length) ?
                            <>
                                <em><strong>Son Güncelleyen Kişi : </strong><span>{state.updatedUser}</span></em>
                                <em><strong>Son Güncellenme Tarihi : </strong><span>{state.updatedDate && moment(state.updatedDate).format('LL')}</span></em>
                            </>
                            :
                            null
                        }
                    </div>
                </> : null
        );
    };

    const renderForm = () => {
        return (
            !faqCategories.loading ? 
            <>
                <Paper className="p-4" elevation={0}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="d-flex align-items-center">
                                <h6><strong>Soru/Cevap İçeriği</strong></h6>
                                {renderFormInfo()}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <Form onSubmit={() => handleSubmit()}>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className="form-field">
                                    <Input
                                        id="question"
                                        name="question"
                                        caption="Soru Başlığı"
                                        className="custom-login-field"
                                        value={state!.question || ''}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-field">
                                    <Select
                                        onChange={handleChange}
                                        id="categoryId"
                                        name="categoryId"
                                        caption={'Kategori Seçimi'}
                                        options={{
                                            data: state.faqCategoryList,
                                            displayField: 'name',
                                            displayValue: 'id',
                                            selected: state!.categoryId
                                        }}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        

                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-field">
                                    <ReactQuill theme="snow" 
                                        id= "answer"
                                        defaultValue = ""
                                        modules={ EditorToolbar }
                                        value={ state!.answer || "" } 
                                        onChange={ handleEditorOnChange }/>
                                    {/* <Input
                                        id="answer"
                                        name="answer"
                                        caption="Cevap"
                                        type="text"
                                        multiline
                                        rows={8}
                                        className="custom-login-field"
                                        value={state!.answer || ''}
                                        onChange={handleChange}
                                        validate={[
                                            {
                                                required: 'required',
                                                message: 'Bu alan boş olamaz'
                                            }
                                        ]}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-2 ml-auto">
                                <div className="form-field">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        text={'Kaydet'}
                                        className="custom-blue-button"
                                        iconRight={() => <i className="fad fa-save ml-2"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Paper>
            </> : null
        );
    };

    return (
        <div>
            <TitleBar
                title={paramID !== null ? 'Soru Düzenle' : 'Soru Ekle'}
                loading={faqItem.loading}
                button={[
                    {
                        text: 'Soru Listesine Dön',
                        onClick: () => {                             
                            history.replace('/faq/list', location.state != null ? {listState: location.state.listState} : null);
                        },
                        iconLeft: <i className="fad fa-arrow-left mr-2"/>,
                        className: 'custom-orange-button'
                    }
                ]}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home"/>,
                        link: '/'
                    },
                    {
                        text: 'Sık Sorulan Sorular',
                        link: '/faq'
                    },
                    {
                        text: paramID !== null ? 'Soru Düzenle' : 'Soru Ekle',
                        link: '/faq/add'
                    }
                ]}
            />

            <div className="wrapper">
                {renderForm()}
            </div>
        </div>
    )
});

const ConnectComponent = connect((store: any) => (
    {
        faqItem: store.faqItem,
        faqCategories: store.faqCategories
    }))(Faq) as any;
export default ConnectComponent
