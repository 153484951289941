export const ExportNames = {
    STREET_ANIMALS: {
        APPROVED: {
            FILE_NAME: "SemtSakinleri",
            TITLE: "Semt Sakini Listesi"
        },
        HAS_OWNER: {
            FILE_NAME: "SemtSakinleri_Sahipli",
            TITLE: "Sahipli Semt Sakinleri Listesi"
        },
        NO_OWNER: {
            FILE_NAME: "SemtSakinleri_Sahiplendirilebilir",
            TITLE: "Sahiplendirilebilir Semt Sakini Listesi"
        }
    },
    SHELTER_ANIMALS: {
        FILE_NAME: "GeciciBakimEviSakinleri",
        TITLE: "Geçici Bakım Evi Sakinleri Listesi"
    },
    ADOPTION_APPLICATIONS: {
        FILE_NAME: "BasvuruListesi",
        TITLE: "Başvuru Listesi"
    },
    SHELTERS: {
        FILE_NAME: "GeciciBakimEvleri",
        TITLE: "Geçici Bakım Evi Listesi"
    },
    INCIDENCE: {
        FILE_NAME: "Vakalar",
        TITLE: "Vaka Listesi"
    },
    USERS: {
        VOLUNTEER: {
            APPROVED: {
                FILE_NAME: "Gonulluler",
                TITLE: "Onaylı Gönüllüler Listesi"
            },
            UNAPPROVED: {
                FILE_NAME: "Gonulluler_OnayBekleyenler",
                TITLE: "Onay Bekleyen Gönüllüler Listesi"
            }
        },
        VETERINARY: {
            APPROVED: {
                FILE_NAME: "Veterinerler",
                TITLE: "Onaylı Veterinerler Listesi"
            },
            UNAPPROVED: {
                FILE_NAME: "Veterinerler_OnayBekleyenler",
                TITLE: "Onay Bekleyen Veterinerler Listesi"
            }
        },
        MEMBER: {
            FILE_NAME: "Hayvanseverler",
            TITLE: "Hayvanseverler Listesi"
        }
    }
};