import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {IIncidentFilterProps} from "./../pages/Incidence/types";
import {krax} from "../store";
import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

interface IIncidentFilterState extends IIncidentFilterProps {
}

export const getIncident = async (PageSize: number = 50, PageIndex: number = 0, filterState: IIncidentFilterState, kraxName= 'incident') => {

    const urlSuffix = '/incident/incidentList';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            PageSize: PageSize,
            PageIndex: PageIndex,
            IncidentId: filterState.incidentId,
            IncidentDate: filterState.insertedDate,
            IncidentType: filterState.incidentType,
            Status: filterState.status,
            StreetAnimalName: filterState.streetAnimalName,
            CityId: filterState.cityId,
            CountyId: filterState.countyId,
            NeighboorhoodId: filterState.neighbourhoodId,
            IsDeleted: filterState.isDeleted,
            IsIgnoreFilter: true
        }
    };

    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};



export const getIncidentById = async (id: string) => {
    const urlSuffix = '/incident/details';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));    
    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'incidentItem',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const getImageById = async (id : string) => {    
    const urlSuffix = '/images';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?imageIds=${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'incidentImgs',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });

};

export const getIncidentAction = async (PageSize: number = 50, PageIndex: number = 0, id: string) => {

    const urlSuffix = '/incidentAction/list';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}?IncidentId=${id}&PageSize=${PageSize}&PageIndex${PageIndex}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader()
    };

    krax({
        name: 'incidentActions',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const getIncidentTypes = async() => {
    const urlSuffix = '/incident/types';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'GET',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'incidentTypes',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}