import React, {memo} from 'react';
import uuidv4 from "uuidv4";
import {withRouter} from "react-router";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getClaims } from "../../_helpers";
import { MenuConstants } from "./../../_constants/menu";

interface IProps {
    treeItems: any,
    sidebarShow: boolean,
    history?: any
}

const treeData: any = [
    {
        id: uuidv4(),
        name: 'Semt Sakinleri',
        icon: 'fad fa-paw',
        claim: MenuConstants.Menu_StreetAnimal,
        link: '/streetanimals'        
    },
    {
        id: uuidv4(),
        name: 'Geçici Bakım Evleri',
        icon: 'fad fa-hands-heart',
        claim: MenuConstants.Menu_ShelterAnimal,
        children: [
            {
                id: uuidv4(),
                name: 'Geçici Bakım Evi Sakinleri',
                link: '/shelteranimals/list',
                icon: 'fad fa-hands-heart',
            },
            {
                id: uuidv4(),
                name: 'Geçici Bakım Evleri',
                link: '/shelters/list',
                icon: 'fas fa-home'
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Üyeler',
        icon: 'fad fa-users',
        claim: MenuConstants.Menu_User,
        children: [
            {
                id: uuidv4(),
                name: 'Gönüllüler',
                link: '/users/volunteer/approved',
                icon: 'fad fa-hand-holding-seedling',
            },
            {
                id: uuidv4(),
                name: 'Veterinerler',
                link: '/users/veterinary/approved',
                icon: 'fad fa-user-md',
            },
            {
                id: uuidv4(),
                name: 'Hayvanseverler',
                link: '/users/member',
                icon: 'fad fa-hand-holding-heart',
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Vakalar',
        icon: 'fas fa-first-aid',
        link: '/incidence',
        claim: MenuConstants.Menu_Incident
    },
    {
        id: uuidv4(),
        name: 'Sahiplenme Başvuruları',
        icon: 'fas fa-list-alt',
        link: '/adoptionapplications',
        claim: MenuConstants.Menu_AdoptionApplication
    },
    {
        id: uuidv4(),
        name: 'Bildirimler',
        icon: 'fad fa-bell',
        link: '/notifications',
        claim: MenuConstants.Menu_Notification
    },
    {
        id: uuidv4(),
        name: 'SSS',
        icon: 'fad fa-question-circle',
        link: '/faq',
        claim: MenuConstants.Menu_FAQ_Info
    },
    {
        id: uuidv4(),
        name: 'Bilgilen',
        icon: 'fad fa-info-circle',
        link: '/toinform',
        claim: MenuConstants.Menu_ToInform
    }
];

const TreeMenuComponent = memo((props: IProps) => {

    const {treeItems, sidebarShow, history} = props;

    const handleClick = (link: string) => {
        history.push(link)
    };

    const renderLi = (items: any, index: number, title: string | null) => {

        return <ul className={`${index === 0 ? 'sidebar-multi-menu' : ''}`} data-text={title}>
            {
                items && items.length && items.map((item: any, i: any) => {
                    return (
                        <li key={`qq-${i}`} className={`${index === 0 ? 'main-parent' : ''} ${sidebarShow ? 'big' : ''}`} onClick={() => handleClick(item.link)}>
                            {item.icon && <i className={item.icon}/>}
                            <a href="/" onClick={(e: any) => e.preventDefault()}>{item.name}</a>
                            {item.children && renderLi(item.children, 1, item.name)}
                        </li>
                    )
                })
            }
        </ul>

    };

    return (
        renderLi(treeItems, 0, null)
    );
});

const Sidebar = memo((props: any) => {

    const [show, setShow] = React.useState(false);
    const handleClickAway = () => {
        setShow(false);
    };

    const renderRoleMenu = () => {
        var claims = getClaims().map(a => a.value);
        var filteredTreeData:any = [];

        for(var i=0; i < treeData.length; i++ ) {
             var item = claims.filter((c:any) => c === treeData[i].claim);
             if(item.length > 0) {
                 filteredTreeData.push(treeData[i]);
             }
        }
        
        return (
            <TreeMenuComponent treeItems={filteredTreeData} sidebarShow={show} history={props.history}/>
        )
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <aside className={show ? 'open' : ''}>
                <div className="icon-bars" onClick={() => setShow(!show)}>
                    <h6 className={`${!show ? 'd-none' : 'd-block'}`}>MENÜ</h6>
                    <i className={`fad ${!show ? 'fa-bars' : 'fa-arrow-left'}`}/>
                </div>
                {renderRoleMenu()}
            </aside>
        </ClickAwayListener>
    )
});


export default withRouter(Sidebar);

