import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {IStreetAnimalFilterProps} from "./../pages/StreetAnimals/types";
import {krax} from "../store";

import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

interface IStreetAnimalFilterState extends IStreetAnimalFilterProps{

}

export const getStreetAnimals = async (PageSize: number = 50, PageIndex: number = 0, approvalStatus: boolean = true, ownershipSlug: string = '', filterState: IStreetAnimalFilterState, kraxName= 'streetAnimals') => {    
    const urlSuffix = '/streetanimals/StreetAnimalList';
    const url:string = String(new URL(`${urlBase()}${urlSuffix}`));    
    var adoptable = 'Adoptable';
    var isOwnerFlag = false;
    if (ownershipSlug.length == 0){
        ownershipSlug = '';
        adoptable = '';
    } else {
        isOwnerFlag = true;
    }

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            IsApproved: approvalStatus,
            PageSize: PageSize,
            PageIndex: PageIndex,
            HasOwner: ownershipSlug,
            IsAdoptable: adoptable,
            microChipNumber: filterState.microchipNumber,
            StreetAnimalName: filterState.name,
            Breed: filterState.breed,
            Color: filterState.color,
            Gender: filterState.gender,
            Age: filterState.age,
            CityId: filterState.cityId,
            CountyId: filterState.countyId,
            NeighbourhoodId: filterState.neighbourhoodId,
            IsAlive: isOwnerFlag ? true : filterState.isAlive,
            IsIgnoreFilter: true,
            IsPassive: filterState.isPassive,
            IsDeleted: isOwnerFlag ? false : filterState.isDeleted
        }
    };
    
    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const updateApprovalStatus = async (data: any) => {

    const urlSuffix = '/streetanimals/ApproveAnimal';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            streetAnimalId: data.id,
            isApproved: !data.isApproved
        }
    };

    return krax({
        name: 'streetAnimals',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Semt Sakini Onay Durumu Güncellendi',
                position: 'topRight',
                icon: 'fad fa-paw',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const activateStreetAnimal = async (id: string) => {

    const urlSuffix = '/streetAnimals/activate';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'streetAnimals',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Semt Sakini Aktif Edildi',
                position: 'topRight',
                icon: 'fas fa-trash',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const removeStreetAnimal = async (id: string) => {

    const urlSuffix = '/streetAnimals/deleteStreetAnimal';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'streetAnimals',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Semt Sakini Pasife Alındı',
                position: 'topRight',
                icon: 'fas fa-trash',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const updateOwnershipStatus = async (data: any) => {

    const urlSuffix = '/streetanimals/OwnAnimal';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            streetAnimalId: data.id,
            hasOwner: !data.hasOwner
        }
    };

    return krax({
        name: 'streetAnimals',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'info',
                message: 'Semt Sakini Sahiplendirilme Durumu Güncellendi',
                position: 'topRight',
                icon: 'fad fa-paw',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};