import {create} from 'oneframe-react';

interface IStore {
    loginWithToken: any
    menu: any,
    users: any,
    usersAllRecord: any,
    streetAnimals: any,
    streetAnimalsAllRecord: any,
    shelterAnimals: any,
    shelterAnimalItem: any,
    shelterAnimalsAllRecord: any,
    shelterList: any,
    shelters: any,
    shelterItem: any,
    sheltersAllRecord: any,
    deleteShelter: any,
    faq: any,
    faqCategories: any,
    faqItem: any,
    passwordChange: any,
    changePassword: any,
    dashboardStatistics: any,
    incident: any,
    incidentAllRecord: any,
    incidentTypes: any,
    incidentItem: any,
    incidentImgs: any,
    incidentActions: any,
    lookups: any,
    cities: any,
    counties: any,
    neighbourhoods: any,
    updateShelterAnimal: any,
    addShelterAnimal: any,
    deleteShelterAnimal:any,
    deleteShelterAnimalImage: any,
    deleteFAQInfoImage: any,
    deleteFaqImage: any,
    surveySubscriberInfo: any,
    saveSurveyAnswer: any,
    sendNotification: any,
    lastSentInfo: any,
    adoptionApplications: any,
    adoptionApplicationAnswers: any
}

const initialState: IStore = {
    loginWithToken: {},
    menu: {},
    users: {},
    usersAllRecord: {},
    streetAnimals: {},
    streetAnimalsAllRecord: {},
    shelterAnimals: {},
    shelterAnimalItem: {},
    shelterAnimalsAllRecord: {},
    shelters: {},
    shelterItem: {},
    sheltersAllRecord: {},
    deleteShelter: {},
    faq: {},
    faqCategories: [],
    faqItem: {},
    passwordChange: {},
    changePassword: {},
    dashboardStatistics: {},
    incident: {},
    incidentAllRecord: {},
    incidentTypes: {},
    incidentItem: {},
    incidentImgs: {},
    incidentActions: {},
    lookups: {},
    cities: [],
    counties: [],
    neighbourhoods: [],
    shelterList: [],
    updateShelterAnimal: {},
    addShelterAnimal: {},
    deleteShelterAnimal: {},
    deleteShelterAnimalImage: {},
    deleteFAQInfoImage: {},
    deleteFaqImage: {},
    surveySubscriberInfo: {},
    saveSurveyAnswer: {},
    sendNotification: {},
    lastSentInfo: {},
    adoptionApplications: {},
    adoptionApplicationAnswers: {}
};

export const {Provider, connect, getState, krax, reset} = create(initialState);
