import {IKraxResult} from "../../_actions/kraxTypes";
import {ILocationResponseProps, ILookUpListProps} from "./../common/types";
import { ExportNames } from './../../_constants/exportNames';


export interface ISheltersProps {
    items: IShelterItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IShelterItemProps {
    id: string,
    name: string,
    cityId: number,
    cityName: string,
    countyId: number,
    countyName: string,
    phoneNumber: string,
    address: string
}

export interface IShelterListPageProps {
    shelters: IKraxResult<ISheltersProps>,
    sheltersAllRecord: IKraxResult<ISheltersProps>,
    lookups: IKraxResult<ILookUpListProps>    
}

export interface IShelterListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    shelterList: IShelterItemProps[],
    tabHelperText?: string,
    exportTitle: string,
    exportFileName: string,
    approvalStatus: boolean,
    modalShow: boolean,
    selectedItem: any,
    operationStatus: boolean,
    operationType: string,
    modalWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined,
    tabIndex: number,
    cities: any,
    exportType?: string,
    deleteControl: boolean,
    deleteItemId: string
}

export const IShelterListPageDefaultValue: IShelterListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    shelterList: [],
    exportFileName: ExportNames.SHELTERS.FILE_NAME,
    exportTitle: ExportNames.SHELTERS.TITLE,
    approvalStatus: true,
    modalShow: false,
    operationStatus: false,
    selectedItem: null,
    operationType: '',
    modalWidth: false,
    tabIndex: 0,
    cities: [],
    deleteControl: false,
    deleteItemId: ''    
};

export interface IShelterFilterProps {
    name: string,
    city: string,
    county: string,
    phonenumber: string,
    triggerFilter: boolean
}

export const IShelterFilterDefaultValue : IShelterFilterProps = {        
    name: '',      
    city: '',    
    county: '',
    phonenumber: '',    
    triggerFilter: false
}


export interface IShelterFormPageProps {    
    shelterItem: IKraxResult<IShelterFormPageState>,
    cities: IKraxResult<ILocationResponseProps>,
    counties: IKraxResult<ILocationResponseProps>
}


export interface IShelterFormPageState {
    id: string,
    name: string,
    nameList: any,
    cityId: number | null,
    countyId: number | null,
    phoneNumber: string,
    updatedDate: string,
    updatedUser: string
    insertedDate: string,
    insertedUser: string    
}

export const IShelterFormPageDefaultValue: IShelterFormPageState = {
    id: '',
    name: '',
    nameList: [],
    cityId: null,
    countyId: null,
    phoneNumber: '',
    updatedDate: '',
    updatedUser: '',
    insertedDate: '',
    insertedUser: ''    
};