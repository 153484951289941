export const LookupConstants = {
    GENDER: 'Gender',
    AGE: 'Age',
    BREED: 'Breed',
    COLOR: 'Color',
    WEIGHT: 'Weight',
    INCIDENT_TYPE: 'IncidentType',
    PHYSICALATTRIBUTE: 'Physicalattribute',
    KIND: 'Kind',
    CHARACTER: 'Character',
    LOOKUPSEPARATOR: ','
};

