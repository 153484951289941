import React, {useState} from 'react';
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {logOut} from "../../_actions/auth";
import {Popover, Paper, Badge, MenuList, MenuItem, Avatar} from '@material-ui/core';
import {sessionStorageConstants} from "../../_constants";
import {capitalize} from 'lodash';
import { getHomePage } from '../../_helpers';

export default withRouter((props: any) => {

    let activeUserEmail: string | null = sessionStorage.getItem(sessionStorageConstants.USER_EMAIL);
    activeUserEmail = activeUserEmail && activeUserEmail.split('"')[1];
    let activeUserFullName: string | null = sessionStorage.getItem(sessionStorageConstants.USER_FULLNAME);
    activeUserFullName = activeUserFullName && activeUserFullName.split('"')[1];
    let letter: any = activeUserFullName && activeUserFullName.split(' ');
    let characters: string = letter && capitalize(letter[0][0]);
    characters += typeof letter[1] !== 'undefined' ? letter[1][0] : ' ';

    const handleLogout = async (e: any) => {
        e.preventDefault();
        const log = await logOut();

        if (!log.error) {
            const fromUrl = '/';
            props.history.push(fromUrl)
        }
    };

    const [noty, setNoty] = useState(null);
    const [menu, setMenu] = useState(null);

    const handleNotyClick = event => {
        setNoty(event.currentTarget);
    };
    const handleMenuClick = event => {
        setMenu(event.currentTarget);
    };
    const handleClose = () => {
        setNoty(null);
        setMenu(null)
    };

    const notyOpen: boolean = Boolean(noty);
    const notyId: any = notyOpen ? 'noty-popover' : undefined;

    const menuOpen: boolean = Boolean(menu);
    const menuId: any = menuOpen ? 'menu-popover' : undefined;

    return (
        <header>
            <div className="layout-header-inner justify-content-between">
                <div className="logo">
                    <Link to={getHomePage()}>
                        <img src="/logo.png" alt=""/>
                    </Link>
                </div>
                <div className="nav">
                    <ul className="">
                        <li className="">
                            <a href="/">
                                <i className="fad fa-life-ring"/>
                            </a>
                        </li>
                        <li className="">
                            <a href="/">
                                <i className="fad fa-comments"/>
                            </a>
                        </li>
                        <li className="">
                            <div className="notification" aria-describedby={notyId} onClick={handleNotyClick}>
                                <Badge overlap="circle" anchorOrigin={{vertical: 'top', horizontal: 'right',}} variant={'dot'}>
                                    <i className="fad fa-bell"/>
                                </Badge>
                            </div>
                            <Popover
                                id={notyId}
                                className="head-popover"
                                open={notyOpen}
                                anchorEl={noty}
                                onClose={handleClose}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                                transformOrigin={{vertical: 'top', horizontal: 'center',}}
                            >
                                <Paper style={{padding: 30}}>
                                    Notification
                                </Paper>
                            </Popover>

                        </li>
                        <li className="separator">
                            <div className="user-badge" aria-describedby={menuId} onClick={handleMenuClick}>
                                <span className="mr-3">{activeUserFullName}</span>
                                <div className="rounded-image">
                                    <Avatar variant="rounded">{activeUserFullName && characters}</Avatar>
                                </div>
                                <i className="fas fa-caret-down ml-2 mini"/>
                            </div>

                            <Popover
                                id={menuId}
                                className="head-popover"
                                open={menuOpen}
                                anchorEl={menu}
                                onClose={handleClose}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                transformOrigin={{vertical: 'top', horizontal: 'right',}}
                            >
                                <Paper>
                                    <div className="user-detail-span">
                                        <div className="title">
                                            {activeUserFullName}
                                            <span>
                                                {activeUserEmail}
                                            </span>
                                        </div>
                                    </div>
                                    <MenuList>
                                        <MenuItem>
                                            <Link to="/user" className="">
                                                <i className="fad fa-star fa-fw mr-2"/>
                                                Profil Bilgileri
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to="/addresses" className="">
                                                <i className="fad fa-address-book fa-fw mr-2"/>
                                                Adresler
                                            </Link>
                                        </MenuItem>

                                        <MenuItem>
                                            <Link to="/documents" className="">
                                                <i className="fad fa-file-alt fa-fw mr-2"/>
                                                Dökümanlar
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to="/profile/changePassword" className="">
                                                <i className="fad fa-file-alt fa-fw mr-2"/>
                                                Şifre Değiştir
                                            </Link>
                                        </MenuItem>

                                    </MenuList>
                                </Paper>
                            </Popover>


                        </li>
                        <li className="">
                            <a href="/" onClick={handleLogout}>
                                <i className="fad fa-sign-out-alt"/>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </header>
    )
});

