import React from 'react';
import {isEqual} from 'lodash'
import {Link} from "react-router-dom";

interface ITitleBar {
    data:any
}

const TitleBarComponent = (props: ITitleBar) => {

    const {data} = props;

    const renderItems = () => {

        return data && data.map((item:any, i:number) => {

            let x:any = null;

            if(i === data.length-1) {
                x = item.text
            } else {
                x = <Link to={item.link}>{item.text}</Link>
            }

            return (
                <li className={`breadcrumb-item ${i === data.length-1 ? 'active' : ''}`} key={i}>
                    {x}
                </li>
            )
        })
    };

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {renderItems()}
            </ol>
        </nav>

    )
};

export default React.memo(TitleBarComponent, (p: any, n: any) => isEqual(p, n));



