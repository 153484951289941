import {IKraxResult} from "../../_actions/kraxTypes";

export interface IFaqProps {
    items: IFaqItemProps[],
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
}

export interface IFaqItemProps {
    id: string,
    question: string,
    answer: string,
    insertedDate: string,
    insertedUser: string,
    categoryId: string,
    categoryName: string,
    updatedDate: string,
    updatedUser: string
}

export interface IFaqListPageProps {
    faq: IKraxResult<IFaqProps>,
}

export interface IFaqListPageState {
    page: number,
    pageSize: number,
    totalCount: number | null,
    totalPages: number | null
    faqList: IFaqItemProps[],
    deleteControl: boolean,
    deleteItemId: string,
    modalShow: boolean
}

export const IFaqListPageDefaultValue: IFaqListPageState = {
    page: 0,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    faqList: [],
    deleteControl: false,
    deleteItemId: '',
    modalShow: false
};

export interface IFaqFormCategoryProps {
    items: IFaqCategoryItemProps[],
}

export interface IFaqCategoryItemProps {
    id: string,
    name: string,
    description: string,
    insertedUser: string,
    insertedDate: string,
    updatedUser: string,
    updatedDate: string
}

export interface IFaqFormPageProps {
    faqCategories: IKraxResult<IFaqFormCategoryProps>
    faqItem: IKraxResult<IFaqItemProps>
}

export interface IFaqFormPageState {
    question: string,
    answer: string,
    insertedDate: string,
    insertedUser: string,
    categoryId: string,
    updatedDate: string,
    updatedUser: string
    faqCategoryList: any,
}

export const IFaqFormPageDefaultValue: IFaqFormPageState = {
    answer: '',
    question: '',
    insertedDate: '',
    insertedUser: '',
    categoryId: '',
    updatedDate: '',
    updatedUser: '',
    faqCategoryList: [],
};