export interface INotificationFormPageState {
    title: string,
    body: string,
    lastSentDate: string,
    modalShow: boolean
}

export const INotificationFormPageDefaultValue : INotificationFormPageState = {
    title: '',
    body: '',
    lastSentDate: '',
    modalShow: false
}