import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import List from './List';

export default ({match}: any) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`}>
                <Redirect to={`${match.url}/volunteer/approved`}/>
            </Route>
            <Route path={`${match.url}/volunteer/approved`} component={List}/>
            <Route path={`${match.url}/volunteer/unapproved`} component={List}/>
            <Route path={`${match.url}/veterinary/approved`} component={List}/>
            <Route path={`${match.url}/veterinary/unapproved`} component={List}/>
            <Route path={`${match.url}/member`} component={List}/>
        </Switch>
    )
};

