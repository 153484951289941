import {IKraxResult} from "../../_actions/kraxTypes";

export interface IHomePageProps {
    dashboardStatistics: IKraxResult<any>,
}

export interface IHomePageState {
    statistics: any
}

export const IHomePageStateDefaultValue: IHomePageState = {
    statistics: {}
};