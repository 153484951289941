import {IKraxResult} from "../../_actions/kraxTypes";
import { PageState } from './../../_constants/pageState';

export interface ISurveyStartPageProps {
    surveyItem: IKraxResult<ISurveyStartPageState>
}

export interface ISurveyStartPageState {
    questionList: ISurveyQuestionItemProps[],
    answerList: ISurveyAnswerItemProps[],
    shelterAnimalInfo: IShelterAnimalItemProps | null
}

export interface ISurveyStartPageStatusState {
    pageState: PageState,
    error: string | null
}

export const ISurveyStartPageStateDefaultValue: ISurveyStartPageStatusState = {
    pageState: PageState.LOADING,
    error: null,
}

export interface IShelterAnimalItemProps {
    name: string,
    image: string
}

export const ISurveyStartPageDefaultValue: ISurveyStartPageState = {
    questionList: [],
    answerList: [],
    shelterAnimalInfo: null
}

export interface ISurveyQuestionItemProps {
    id: number,
    text: string,
    answerType: number | null,
    name: string | null,
    order: number
}

export interface ISurveyAnswerItemProps {
    questionId: number,
    name: string | null,
    text: string
}