import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import * as uuidv4 from "uuid/v4";
import Layout from '../pages/Layout'
import {isAuthenticated , hasUserPermission} from "../_helpers";

export default ({component: Component,  claimValue: claimValue, ...rest}:any) => {

    return (
        <Route
            {...rest}
            key={uuidv4}
            render={props => {
                if (isAuthenticated()) {
                    if(hasUserPermission(claimValue)) {
                        return (
                            Component ? <Layout><Component {...props} /></Layout> : rest.render(props)
                        )
                    } else {
                        // TODO redirect yetkiniz yoktur sayfası.
                        return <Redirect to={{pathname: '/nopermission', state: {from: '/'}}}/>
                    }                    
                }

                return <Redirect to={{pathname: '/auth/login', state: {from: '/'}}}/>
            }}
        />
    )
}

