export const MenuConstants = {
    Menu_Dasboard: 'Menu_Dashboard',
    Menu_StreetAnimal: 'Menu_StreetAnimal',
    Menu_FAQ_Info: 'Menu_FAQ_Info',
    Menu_Incident: 'Menu_Incident',
    Menu_User: 'Menu_User',
    Menu_ShelterAnimal: 'Menu_ShelterAnimal',
    Menu_Change_Password: 'Menu_Change_Password',
    Menu_ToInform: 'Menu_ToInform',
    Menu_Notification: 'Menu_Notification',
    Menu_AdoptionApplication: 'Menu_AdoptionApplication'
};