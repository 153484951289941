import React, {memo, useEffect, useState} from 'react';
import styled from "styled-components";
import { getSurveySubscriberInfo, saveSurveyAnswer } from "../../_actions/survey";
import {isEqual} from 'lodash'
import {connect} from "../../store";
import {Button, Form, Input, RadioGroup, toastMessage} from 'oneframe-react'
import { CheckCircle, Error } from '@material-ui/icons';
import { ISurveyStartPageProps, ISurveyStartPageState, ISurveyStartPageStatusState, ISurveyStartPageStateDefaultValue, ISurveyStartPageDefaultValue, ISurveyAnswerItemProps } from './types';
import {useLocation} from 'react-router-dom'
import qs from 'query-string';
import { QuestionType } from './../../_constants/questionType';
import { PageState } from './../../_constants/pageState';
import CircularLoading from "../../components/CircularLoading";
import uuidv4 from "uuidv4";


interface IProps extends ISurveyStartPageProps{
}

interface IFormState extends ISurveyStartPageState{
}

interface ICustomPageState extends ISurveyStartPageStatusState{
}

const SurveyStart = memo((props: IProps) => {
    const location = useLocation();

    const paramID = location && location.search && location.search.length ? qs.parse(location.search) : null;
    const [state, setState] = useState<IFormState>(ISurveyStartPageDefaultValue);
    const [pageState, setPageState] = useState<ICustomPageState>(ISurveyStartPageStateDefaultValue);

    const inputMaxLength : number = 1000;
   
    const showLoading = () => {
        setPageState((prevState:ICustomPageState) => ({...prevState, pageState: PageState.LOADING}));
    }

    const hideLoading = () => {
        setPageState((prevState:ICustomPageState) => ({...prevState, pageState: PageState.IDLE}));
    }

    useEffect(() => {
        if (paramID != null) {
            if (paramID.token != null) {
                showLoading();

                const data: any = {
                    Token: encodeURIComponent(paramID.token.toString())
                }

                const onErrorSubscriberInfo = (_:any, response:any) => {
                    var errorText;

                    if (response.error.errors) {
                        errorText = response.error.errors.message;
                    }

                    setPageState((prevState:ICustomPageState) => ({...prevState, error: errorText, pageState: PageState.ERROR}));
                };

                getSurveySubscriberInfo(data, (_:any, response: any) => {
                    hideLoading();

                    if (response.payload) {
                        var answerList: ISurveyAnswerItemProps[] = fillAnswerList(response.payload);
            
                        setState((prevState:IFormState) => ({...prevState, questionList: response.payload.questions, answerList: answerList, shelterAnimalInfo: response.payload.shelterAnimalInfo}));
                    }
                }, onErrorSubscriberInfo);
            } else {
                setPageState((prevState:ICustomPageState) => ({...prevState, error: "Sayfa bulunamadı! Lütfen linki kontrol ediniz.", pageState: PageState.ERROR}));
            }
        }
    }, []);

    const fillAnswerList = (payload: any) => {
        var answerList: ISurveyAnswerItemProps[] = [];

        for (var i = 0; i < payload.questions.length; i++){
            var x = payload.questions[i];

            answerList.push({
                questionId: x.id,
                name: x.name,
                text: x.name == null ? "" : eval(`payload.${x.name.charAt(0).toLocaleLowerCase()}${x.name.slice(1)}`)
            });
        }

        return answerList;
    }

    const trueFalseData = [{ value: "true", name: "Evet" }, { value: "false", name: "Hayır" }];

    const handleSubmit = async () => {
        showLoading();

        var answerList: ISurveyAnswerItemProps[] = state.answerList.slice();

        for(var i=0; i < answerList.length; i++) {
            var inputValue = eval(`event.target.elements["question-${answerList[i].questionId}"].value`);

            if (inputValue != null) {
                answerList[i].text = inputValue;
            }
        }

        var token = '';

        if (paramID != null && paramID.token != null) {
            token = paramID!.token!.toString();
        }

        const data: any = {
            token: encodeURIComponent(token),
            answers: answerList,
        }

        const onError = (_:any, response:any) => {   
            hideLoading();
            
            var errorMessage = "Bilinmeyen bir hata oluştu.";
            
            if (response.error.errors) {
                errorMessage = response.error.errors.message;
            }

            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: errorMessage,
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',                  
            });
        };

        await saveSurveyAnswer(data, (_:any, response:any) => {
            setPageState((prevState:ICustomPageState) => ({...prevState, pageState: PageState.SUCCESS}));              
        }, onError);
    }

    const renderSurveyItem = () => {
        var items: any[] = [];

        if (state.questionList != null) {
            for (var i = 0; i < state.questionList.length; i++) {
                var questionInput;
                var questionIdName = `question-${state.questionList[i].id}`;
                var answer = state.answerList.find(x => x.questionId == state.questionList[i].id);

                switch (state.questionList[i].answerType) {
                    case QuestionType.Text:
                        questionInput = <Input
                                            id={questionIdName}
                                            name={questionIdName}
                                            className="custom-login-field"
                                            value={answer != null ? answer.text : ''}
                                            validate={[
                                                {
                                                    required: 'required',
                                                    message: 'Bu alan boş olamaz'
                                                },
                                                {
                                                    required: 'maxLength',
                                                    message: `En fazla ${inputMaxLength} karakter girebilirsiniz`,
                                                    value: inputMaxLength
                                                },
                                            ]}
                                        />;
                        break;
                
                    case QuestionType.YesNo:
                        questionInput = <RadioGroup
                                            id={questionIdName}
                                            name={questionIdName}
                                            onChange={() => {}}
                                            required
                                            options={{
                                                data: trueFalseData,
                                                displayField: 'name',
                                                displayValue: 'value',
                                                selected:answer!.text
                                            }}
                                            validate={[
                                                {
                                                    required: 'required',
                                                    message: 'Bu alan boş olamaz'
                                                }
                                            ]}
                                        />;
                        break;

                    case QuestionType.LongText:
                        questionInput = <Input
                                            id={questionIdName}
                                            name={questionIdName}
                                            className="custom-login-field"
                                            value={answer != null ? answer.text : ''}
                                            multiline
                                            rows={4}
                                            validate={[
                                                {
                                                    required: 'required',
                                                    message: 'Bu alan boş olamaz'
                                                },
                                                {
                                                    required: 'maxLength',
                                                    message: `En fazla ${inputMaxLength} karakter girebilirsiniz`,
                                                    value: inputMaxLength
                                                },
                                            ]}
                                        />;
                        break;

                    default:
                        break;
                }

                items.push(
                    <div key={uuidv4()} className="form-field">
                        <p className="form-label">
                            {state.questionList[i].order}. {state.questionList[i].text}
                        </p>
                        { questionInput }
                    </div>
                )
            }
        }

        return (
            <div>{ items }</div>
        );
    }

    const renderSurvey = () => {
        return (
            <div className="form">
                <Form onSubmit={() => handleSubmit()}>
                    {
                        renderSurveyItem()
                    }
                    <div className="form-field">
                        <Button
                            type="submit"
                            fullWidth
                            className="custom-orange-button p-3"
                            text={'Gönder'}
                            iconRight={() => <i className="fad fa-share ml-2"/>}
                        />
                    </div>
                </Form>
            </div>
        );
    };

    const renderImage = () => {
        if (state.shelterAnimalInfo == null || state.shelterAnimalInfo.image == null) {
            return null;
        }

        return (
            <div className="mt-4">
                <img className="shelter-animal-image" src={`data:image/jpeg;base64, ${state.shelterAnimalInfo.image}`} alt={state.shelterAnimalInfo.name} />
                <h6 className="shelter-animal-name">{ state.shelterAnimalInfo.name }</h6>
            </div>
        );
    }

    const renderContent = () => {
        if (pageState.pageState == PageState.ERROR) {
            return (
                <div className="absolute-center-center survey-result">
                    <div className="logo-area">
                        <img src="/semtpati_logo.png" alt=""/>
                    </div>
                    <Error
                        color='error'
                        fontSize='large'
                        className='mb-2'
                    />
                    <h3 className='mb-2'>Hata!</h3>
                    <h6>{ pageState.error || "Beklenmedik bir hata oluştu. Lütfen tekrar deneyiniz." }</h6>
                </div>
            );
        } else if (pageState.pageState == PageState.SUCCESS) {
            return (
                <div className="absolute-center-center survey-result">
                    <div className="logo-area">
                        <img src="/semtpati_logo.png" alt=""/>
                    </div>
                    <CheckCircle
                        fontSize='large'
                        className='mb-2 txt-green'
                    />
                    <h3 className='mb-2'>Başarılı!</h3>
                    <h6>Anket başarılı bir şekilde kaydedildi.</h6>
                </div>
            );
        } else if (pageState.pageState == PageState.LOADING) {
            return (<CircularLoading />);
        } else {
            return (
                <div className="survey-form">
                    <div className="form-area">
                        <h3>
                            Harekete Geçtim.
                        </h3>
                        {renderImage()}

                        {renderSurvey()}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="survey-container">           
            { renderContent() }
        </div>
    )
});

const SurveyStartStyled = styled(SurveyStart)``;
const SurveyStartComponent = React.memo(SurveyStartStyled, (p: any, n: any) => isEqual(p, n));
export default connect((state: any) => (state))(SurveyStartComponent);