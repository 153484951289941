import {buildRequestHeader, getAuthApiBaseUrl, handleErrorType, /*handleErrorType*/} from "../_helpers";
import {IUserFilterProps} from "./../pages/Users/types";
import {krax} from "../store";
import {toastMessage} from 'oneframe-react';

const urlBase = () => getAuthApiBaseUrl();

interface IUserFilterState extends IUserFilterProps {
}

export const getUsers = async (UserType: string = 'Volunteer', PageSize: number = 50, PageIndex: number = 0, approvalStatus: boolean = false, filterState: IUserFilterState, kraxName = 'users') => {

    const urlSuffix = '/users/list';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            UserType: UserType,
            PageSize: PageSize,
            PageIndex: PageIndex,
            IsApproved: approvalStatus,
            IsDeleted: filterState.isDeleted,
            FullName: filterState.fullName,
            Username: filterState.username,
            PhoneNumber: filterState.phoneNumber,
            CityId: filterState.cityId,
            CountyId: filterState.countyId,
            NeighboorhoodId: filterState.neighbourhoodId
        }
    };

    krax({
        name: kraxName,
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {

        },
        onError: (_: any, state: any, error: any) => {

        }
    });
};

export const updateApprovalStatus = async (data: any) => {

    const urlSuffix = '/users/ApproveUser';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
        body: {
            username: data.username,
            isApproved: !data.isApproved
        }
    };

    return krax({
        name: 'users',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Üye Onay Durumu Güncellendi',
                position: 'topRight',
                icon: 'fad fa-user-check',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const removeUser = async (username: string) => {
    const urlSuffix = '/users/deleteUser';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${username}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader(),
    };

    krax({
        name: 'users',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Üye Pasife Alındı',
                position: 'topRight',
                icon: 'fas fa-trash',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
};

export const changeUserToVolunteer = async (id: string) => {
    const urlSuffix = '/users/ChangeMemberToVolunteer';
    const url: string = String(new URL(`${urlBase()}${urlSuffix}/${id}`));

    const kraxRequest: any = {
        isJson: true,
        url: url,
        method: 'POST',
        headers: buildRequestHeader()
    };

    krax({
        name: 'users',
        request: kraxRequest,
        onSuccess: (_: any, state: any) => {
            toastMessage({
                messageType: 'success',
                message: 'Üye gönüllüye çevrildi',
                position: 'topRight',
                icon: 'fas fa-trash',
            })
        },
        onError: (_: any, state: any, error: any) => {
            if (error && !state.error) {
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: 'ERR_CONNECTION_REFUSED',
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            } else {
                handleErrorType(error);
                toastMessage({
                    messageType: 'error',
                    title: 'Hata oluştu',
                    message: state.error.message,
                    position: 'topRight',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                    target: '.error-container',
                })
            }
        }
    });
}

