export const ColorConstants = {
    GREEN: '#1AAF5D',
    LIGHT_BLUE: '#1ECAD3',
    DARK_BLUE: '#484D6D',
    YELLOW: '#F2C500',
    RED: '#F04B5A',
    PURPLE: '#5D62B5',
    ROSE: '#986C6A',
    PINK: '#E78F8E',
    BLACK: '#1A1D1A',
    ORANGE: '#FF9B71'
};