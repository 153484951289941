import React, { memo, useEffect, useState } from 'react';
import { TitleBar } from '../../components';
import { IAdoptionApplicationListPageProps, IAdoptionApplicationListPageState, IAdoptionApplicationListPageDefaultValue, IAdoptionApplicationFilterProps, IAdoptionApplicationFilterDefaultValue, IAdoptionApplicationAnswersItemProps } from "./types";
import { ITableSelectedItemProps, ITableSelectedItemDefaultValue } from "./../common/types"
import { connect } from "../../store";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { DataGrid as Grid, Button, Input, DatePicker, Checkbox, Form, Select, toastMessage } from 'oneframe-react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid as MUIGrid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment'
import 'moment/locale/tr'
import Description from "../../components/Description";
import { LookupConstants } from './../../_constants/lookup';
import { getLookups} from './../../_helpers';
import { filter } from 'lodash';
import { getAdoptionApplications, getAdoptionApplicationAnswersExport } from "../../_actions/adoptionApplications";
import { getShelterList } from "../../_actions/shelterAnimals";
import DownloadExcel from './../../components/ExcelExport/exceldownload'
import { ExportTypes } from './../../_constants/exportType';
import { ExportNames } from './../../_constants/exportNames';

interface IProps extends IAdoptionApplicationListPageProps {
}
interface IAdoptionApplicationState extends IAdoptionApplicationListPageState{
}
interface IAdoptionApplicationFilterState extends IAdoptionApplicationFilterProps {
}
interface ITableSelectedItemState extends ITableSelectedItemProps {
}

const AdoptionApplications = memo((props: IProps) => {
    const location = useLocation();
    const classes = useStyles();

    const { pathname } = location;

    const { adoptionApplications } = props;

    const [state, setState] = useState<IAdoptionApplicationState>(location.state == null ? IAdoptionApplicationListPageDefaultValue : location.state.listState);
    const [filterState, setFilterState] = useState<IAdoptionApplicationFilterState>(IAdoptionApplicationFilterDefaultValue);
    const [exportDataState, setExportDataState] = useState<IAdoptionApplicationAnswersItemProps[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<ITableSelectedItemState>(ITableSelectedItemDefaultValue);
    const { triggerFilter } = filterState;

    const { page, pageSize } = state;

    const showLoading = () => {
        setLoading(true);
    }

    const hideLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            pageSize: 10
        }));

        getShelterList(function (_:any, response:any) {
            if (response.payload) {
                var allLookups:any = getLookups();
                var ageList = filter(allLookups, function (o) { return o.key === LookupConstants.AGE });
                var genderList = filter(allLookups, function (o) { return o.key === LookupConstants.GENDER });
                var kindList = filter(allLookups, function (o) { return o.key === LookupConstants.KIND });

                setState((prevState: IAdoptionApplicationState) => ({...prevState, 
                    ageList: ageList[0].values,
                    genderList: genderList[0].values,
                    kindList: kindList[0].values,
                    shelterList: response.payload.items,
                }));
            }
        });

    }, [pathname]);

    useEffect(() => {
        getAdoptionApplications(pageSize, page, filterState, 'adoptionApplications');
    }, [page, pageSize, triggerFilter]);

    const customizeData = (allData: any) => {
        allData.forEach((item) => {
            if (item.text == "True") item.text = "Evet";
            else if (item.text == "False") item.text = "Hayır";

            if (item.shelterAnimalAssingedUsersAssignedDate != null) {
                item.shelterAnimalAssingedUsersAssignedDate = moment(item.shelterAnimalAssingedUsersAssignedDate).format('LLL')
            }
        });

        return allData;
    }

    const getAllDataOnHandleClick = async (exportType: string) => {
        if (selected.selectedItems.length == 0) {
            toastMessage({
                messageType: 'warning',
                message: "En az 1 adet başvuru seçmelisiniz.",
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',                  
            });
            return;
        }

        showLoading();

        // state sıfırlanmazsa ikinci kez indirilemiyor.
        setExportDataState(null);

        const data = {
            AssignedIdList: selected.selectedItems,
        }

        const onError = (_:any, response:any) => {   
            hideLoading();
            var errorMessage = "Bilinmeyen bir hata oluştu";      
            if (response.error.errors) {
                errorMessage = response.error.errors.message;
            }

            toastMessage({
                messageType: 'error',
                title: 'Hata oluştu',
                message: errorMessage,
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',                  
            });
        };

        await getAdoptionApplicationAnswersExport(data, (_:any, response:any) => {
            if (response.payload) {
                if (response.payload.length == 0) {
                    toastMessage({
                        messageType: 'error',
                        title: 'Kayıt bulunamadı',
                        message: "Başvurular için bir kayıt bulunamadı.",
                        position: 'center',
                        overlay: true,
                        icon: 'fad fa-exclamation-square',                  
                    });
                } else {
                    var customizedData = customizeData(response.payload);

                    if (exportType == ExportTypes.EXCEL) {
                        setExportDataState(customizedData);
                    }
                }

                hideLoading();

                setSelected((prevState: ITableSelectedItemState) => ({
                    ...prevState,
                    selectedItems: []
                }));
            }
        }, onError);
    }

    const allCheckHandleChange = (e) => {
        // Ekrandaki gözüken datalardan, seçili id'ler listesinde yer almayanların listesi.
        var notOnTheSelectedList = adoptionApplications.payload.items.filter((x) => !selected.selectedItems.includes(x.id));
        // Ekrandaki gözüken datalardan, seçili id'ler listesinde yer alanların listesi.
        var onTheSelectedList = adoptionApplications.payload.items.filter((x) => selected.selectedItems.includes(x.id));
        var selectedIds = selected.selectedItems;

        // Tablo render edildiğinde, bu method da çalıştığı için kontroller eklendi.
        // Checkbox seçildiyse ve seçili id'ler listesinde, şu an ekranda yer alan datalar eksik olanlar varsa.
        if (e.target.checked && notOnTheSelectedList.length != 0) {
            var idList = notOnTheSelectedList.map((x) => x.id);  

            var newArray = selectedIds.slice();

            newArray = newArray.concat(idList);

            setSelected(prevState => ({
                ...prevState,
                selectedItems: newArray
            }))
        } else if (!e.target.checked && onTheSelectedList.length != 0 && notOnTheSelectedList.length == 0) { // Checkbox seçimi iptal edildiyse ve seçili id'ler listesinde, şu an ekranda yer alan datalar varsa ve seçili id'ler listesinde, şu an ekranda yer alan datalardan yer almayan yoksa.
            var newArray = selectedIds.filter((x) => !adoptionApplications.payload.items.map((x) => x.id).includes(x));

            setSelected(prevState => ({
                ...prevState,
                selectedItems: newArray
            }))
        }
    }

    const checkHandleChange = (e: any) => {
        var selectedIds = selected.selectedItems;

        var id = e.target.id.split('_')[1];
        var index = selectedIds.findIndex((x) => x == id);

        if (e.target.checked && index == -1) {
            selectedIds.push(id);

            setSelected(prevState => ({
                ...prevState,
                selectedItems: selectedIds
            }))
        } else if (!e.target.checked && index != -1) {
            selectedIds.splice(index, 1);

            setSelected(prevState => ({
                ...prevState,
                selectedItems: selectedIds
            }))
        }
    }

    const filterHandleChange = (e: any) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const filterHandleSubmit = () => {
        // arama sonrasında page 0 a çekilmeli bu filtreyi de tetikler. page 0 ise, else kısmı tetikler.
        if (state.page != 0) {
            setState((prevState) => ({
                ...prevState,
                page: 0
            }));
        }
        else {
            //triggerFilter değiştiğinde use effect getIncident a düşer.
            setFilterState((prevState) => ({
                ...prevState,
                triggerFilter: !triggerFilter
            }));
        }
    }

    const resetFilterHandle = () => {
        setFilterState(IAdoptionApplicationFilterDefaultValue);
    }

    const exportColumns = [
        { label: "Başvuru Sistem No", value: "shelterAnimalAssingedUsersId"},
        { label: "Başvuru Tarihi", value: "shelterAnimalAssingedUsersAssignedDate" },
        { label: "Geçici Bakım Evi Sakini", value: "shelterAnimalAssingedUsersShelterAnimalName" },
        { label: "Soru No", value: "questionOrder" },
        { label: "Soru", value: "questionText" },
        { label: "Cevap", value: "text" },
    ]

    return (
        <>
            <TitleBar
                title={'Sahiplenme Başvuruları'}
                loading={loading || adoptionApplications.loading}
                breadcrumbs={[
                    {
                        text: <i className="fas fa-home" />,
                        link: '/'
                    },
                    {
                        text: 'Sahiplenme Başvuruları',
                        link: "/adoptionapplications"
                    }
                ]}
            />

            <div className="wrapper">
            <div className="mb-3">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Filtreleme Kriterleri</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Form onSubmit={() => filterHandleSubmit()}>
                                <MUIGrid container spacing={3}>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="shelterAnimalName"
                                            name="shelterAnimalName"
                                            caption="İsim"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.shelterAnimalName}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="shelterAnimalKind"
                                            name="shelterAnimalKind"
                                            caption={'Tür'}
                                            options={{
                                                data: state.kindList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.shelterAnimalKind
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="shelterAnimalMicrochipNumber"
                                            name="shelterAnimalMicrochipNumber"
                                            caption="Mikroçip No"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.shelterAnimalMicrochipNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="shelterAnimalGender"
                                            name="shelterAnimalGender"
                                            caption={'Cinsiyet'}
                                            options={{
                                                data: state.genderList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.shelterAnimalGender
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="shelterAnimalAge"
                                            name="shelterAnimalAge"
                                            caption={'Yaş'}
                                            options={{
                                                data: state.ageList,
                                                displayField: 'value',
                                                displayValue: 'value',
                                                selected: filterState!.shelterAnimalAge
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Select
                                            onChange={filterHandleChange}
                                            id="shelterAnimalShelterName"
                                            name="shelterAnimalShelterName"
                                            caption={'Geçici Bakım Evi'}
                                            options={{
                                                data: state.shelterList,
                                                displayField: 'name',
                                                displayValue: 'name',
                                                selected: filterState!.shelterAnimalShelterName
                                            }}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="assignedUserUserName"
                                            name="assignedUserUserName"
                                            caption="Kullanıcı Adı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.assignedUserUserName}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="assignedUserFullName"
                                            name="assignedUserFullName"
                                            caption="Kullanıcı Adı Soyadı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.assignedUserFullName}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="assignedUserPhoneNumber"
                                            name="assignedUserPhoneNumber"
                                            caption="Kullanıcı Telefon No"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.assignedUserPhoneNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="surveyAnswerFullName"
                                            name="surveyAnswerFullName"
                                            caption="Sahiplenecek Kişi Adı Soyadı"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.surveyAnswerFullName}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="surveyAnswerPhoneNumber"
                                            name="surveyAnswerPhoneNumber"
                                            caption="Sahiplenecek Kişi Telefon No"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.surveyAnswerPhoneNumber}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <Input
                                            id="surveyAnswerEmailAddress"
                                            name="surveyAnswerEmailAddress"
                                            caption="Sahiplenecek Kişi E-Posta Adresi"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.surveyAnswerEmailAddress}
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={3}>
                                        <DatePicker id="assignedDate"
                                            name="assignedDate"
                                            caption="Başvuru Tarihi"
                                            className="custom-login-field"
                                            onChange={filterHandleChange}
                                            value={filterState.assignedDate}
                                            momentLocale="tr"
                                        />
                                    </MUIGrid>
                                    <MUIGrid item xs={12}>
                                        <MUIGrid container spacing={0} direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="reset"
                                                    text="Temizle"
                                                    className='custom-blue-button'
                                                    onClick={resetFilterHandle}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={1}>
                                                <Button
                                                    type="submit"
                                                    text="Ara"
                                                    className='custom-green-button'
                                                />
                                            </MUIGrid>
                                        </MUIGrid>
                                    </MUIGrid>
                                </MUIGrid>
                            </Form>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="row mb-3 align-items-center">
                    <div className="col-6 selected-info-row">
                        {
                            selected.selectedItems.length > 0 ?
                            <>
                                <h6>{selected.selectedItems.length} öğe seçili</h6>
                                <a href="javascript:void" className="ml-2" onClick={() => {
                                            setSelected((prevState: ITableSelectedItemState) => ({
                                                ...prevState,
                                                selectedItems: []
                                            }));
                                        }}>
                                    <i className="fas fa-trash fa-1x"/> Seçimi Temizle
                                </a>
                            </> : null
                        }
                    </div>
                    <div className="col-6 export-row text-right">
                        <Form onSubmit={() => getAllDataOnHandleClick(ExportTypes.EXCEL)}>
                            <Button type="submit" text="Cevapları Excel'e Aktar" className='custom-green-button ml-3' disabled={loading} iconLeft={() => <i className="fas fa-file-excel mr-2" />}></Button>
                            {exportDataState != null && exportDataState.length > 0 ?
                                <DownloadExcel
                                    fileName={ExportNames.ADOPTION_APPLICATIONS.FILE_NAME}
                                    buttonText="Cevapları Excel'e Aktar"
                                    data={exportDataState}
                                    sheetName= {ExportNames.ADOPTION_APPLICATIONS.TITLE}
                                    columns = {exportColumns}
                                />
                                :
                                null
                            }
                        </Form>
                    </div>
                </div>
                {!adoptionApplications.loading && !loading ?
                    (adoptionApplications.payload && adoptionApplications.payload.items && adoptionApplications.payload.items.length ?
                        <>
                            <Grid
                                data={adoptionApplications.payload.items}
                                page={adoptionApplications.payload.pageIndex}
                                rowsPerPage={state.pageSize}
                                count={adoptionApplications.payload.totalCount}                    
                                totalPages={adoptionApplications.payload.totalPages}                    
                                serverSide={pageSize !== adoptionApplications.payload.totalCount}
                                loadingState={adoptionApplications.loading}
                                onChangeRowsPerPage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        pageSize: a,
                                        page: 0
                                    }))
                                }}
                                onChangePage={(a: any) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        page: a
                                    }))
                                }}
                                header={{
                                    print: false,
                                    download: false,
                                    search: false,
                                    filter: false,
                                    viewColumns: false
                                }}
                                helperInfoText={' sahiplenme başvurusu var'}
                                sort={false}
                                labels={{
                                    noData: 'Hiç Data Yok',
                                    headerTooltip: 'Sırala',
                                    paginationNext: 'Sonraki Sayfa',
                                    paginationPrev: 'Önceki Sayfa',
                                    paginationRowsPerPage: 'Gösterilecek Veri Sayısı :',
                                    seperator: '/'
                                }}
                                hiddenColumns={['id']}
                                column={ 
                                [
                                    {
                                        label: <Checkbox
                                                    id="check_all"
                                                    name="check_all"
                                                    checked={adoptionApplications.payload.items.filter((x) => !selected.selectedItems.includes(x.id)).length == 0}
                                                    onChange={allCheckHandleChange}/>,
                                        displayField: '',
                                        customRender: (a, b) => {
                                            console.log("custom render");
                                            return (
                                                <>
                                                    <span>
                                                        <Checkbox
                                                            id={`check_${b.id}`}
                                                            name={`check_${b.id}`}
                                                            checked={selected.selectedItems.find((x) => x == b.id) != null}
                                                            onChange={checkHandleChange}/>
                                                    </span>
                                                </>
                                            );
                                        }
                                    }, 
                                    {
                                        label: 'İsim',
                                        displayField: 'shelterAnimalName',
                                    },
                                    {
                                        label:'Tür',
                                        displayField: 'shelterAnimalKind'
                                    },
                                    {
                                        label:'Mikroçip No',
                                        displayField: 'shelterAnimalMicrochipNumber'
                                    },
                                    {
                                        label: 'Cinsiyet',
                                        displayField: 'shelterAnimalGender',
                                    },
                                    {
                                        label: 'Yaş',
                                        displayField: 'shelterAnimalAge',
                                    },
                                    {
                                        label: 'Geçici Bakım Evi',
                                        displayField: 'shelterAnimalShelterName',
                                    },
                                    {
                                        label:'Kullanıcı Adı',
                                        displayField: 'assignedUserUserName',
                                    },
                                    {
                                        label:'Kullanıcı Adı Soyadı',
                                        displayField: 'assignedUserFullName',
                                    },
                                    {
                                        label:'Kullanıcı Telefon No',
                                        displayField: 'assignedUserPhoneNumber',
                                    },
                                    {
                                        label:'Sahiplenecek Kişi Adı Soyadı',
                                        displayField: 'surveyAnswerFullName',
                                    },
                                    {
                                        label:'Sahiplenecek Kişi Telefon No',
                                        displayField: 'surveyAnswerPhoneNumber',
                                    },
                                    {
                                        label:'Sahiplenecek Kişi E-Posta Adresi',
                                        displayField: 'surveyAnswerEmailAddress',
                                    },
                                    {
                                        label:'Başvuru Tarihi',
                                        displayField: 'assignedDate',
                                        customRender: (value: any, allValue: any) => {
                                            return (
                                                <span>{value != null ? moment(value).format('LLL') : ""}</span>
                                            )
                                        }
                                    },
                                ]}
                            />
                        </>
                        : <Description
                            text={"Herhangi bir Sahiplenme Başvurusu yok" } />
                    ) : null
                }
            </div>
        </>
    )
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const ConnectComponent = connect((store: any) => ({ adoptionApplications: store.adoptionApplications }))(AdoptionApplications) as any;
export default ConnectComponent